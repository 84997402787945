import {useOnlineManager} from '@src/core/hooks/useOnlineManager';
import {useTranslation} from 'react-i18next';

export const ToastDescription = () => {
	const isOnline = useOnlineManager();
	const {t} = useTranslation();
	return (
		<span className="fast-adding-toast__text">
			{isOnline
				? t('globalAddingEntityDialog.fastAddingProblem.onlineToast')
				: t('globalAddingEntityDialog.fastAddingProblem.offlineToast')}
		</span>
	);
};

import { useCallback, useState } from 'react';
import * as React from 'react';
import {EditWarrantyClaimDialog} from '@src/components/EditWarrantyClaimDialog';
import {IWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaim';
import {useAsyncFn} from 'react-use';
import {editWarrantyClaim} from '@src/store/modules/entities/warrantyClaim/actions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {ISavingWarrantyClaim} from '@src/interfaces/saving/ISavingWarrantyClaim';

export const useEditWarrantyClaimDialog = (
	warrantyClaim: IWarrantyClaim
): [React.ReactNode, (
) => void] => {
	const [isOpen, setOpen] = useState(false);
	const dispatch = useAppDispatch();
	const open = useCallback(() => {
		setOpen(true);
	}, []);

	const close = useCallback(() => {
		setOpen(false);
	}, []);

	const [{loading}, handleWarrantyClaimChange] = useAsyncFn(async (value: ISavingWarrantyClaim) => {
		if (warrantyClaim) {
				await dispatch(editWarrantyClaim(warrantyClaim.objectId, warrantyClaim.id, value));
		}
	}, [dispatch, warrantyClaim]);

	const dialog = (
		<EditWarrantyClaimDialog
			isOpen={isOpen}
			onClose={close}
			warrantyClaim={warrantyClaim}
			loading={loading}
			onChange={handleWarrantyClaimChange}
		/>
	);

	return [dialog, open];
};

import {memo, useCallback, useEffect, useRef, useState} from 'react';
import './AddingWarrantyClaimDialog.less';
import {Dialog} from '@tehzor/ui-components';
import {IWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaim';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IAddingWarrantyClaimRefProps} from './components/AddingWarrantyClaim';
import {IAddingProblemRefProps} from '../AddingProblem';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {ISavingProblem} from '@src/interfaces/saving/ISavingProblem';
import EntitySelectContainer from '@tehzor/ui-components/src/components/containers/EntitySelectContainer/EntitySelectContainer';
import {useClaimMaps} from './hooks/useClaimMaps';
import {saveClaim} from './utils/saveClaim';
import {useLocalWarrantyClaims} from '@src/core/hooks/queries/warrantyClaims/hooks';
import {useQueryClient} from '@tanstack/react-query';
import {warrantyClaimsQueryKeys} from '@src/api/cache/warrantyClaims/keys';
import {useTranslation} from 'react-i18next';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';
import {useTranslatedObjectStagesArray} from '@src/core/hooks/translations/useTranslatedObjectStagesArray';
import {
	useEntitySelectContainerSubTitle,
	useEntitySelectContainerSubSubTitle
} from '@src/core/hooks/getEntitySelectContainerSubTitle';
import {useObject} from '@src/core/hooks/queries/objects/hooks';
import {useSpace} from '@src/core/hooks/queries/space';
import {extractFastAdding} from '@src/store/modules/settings/modalDialog/selectors';
import {ModalDialogButtons} from '../ModalDialogButtons';

export type AddingWarrantyClaimView = 'claim' | 'problem' | undefined;

interface IAddingWarrantyClaimDialogProps {
	objectId: string;
	spaceId?: string;
	claimId?: string;
	links?: IWarrantyClaim['links'];
	defaultProblemData?: ISavingProblem;
	isOpen: boolean;
	onClose: () => void;
	onSuccess?: () => void | Promise<void>;
}

const addingWarrantyClaimDialogClassNames = {
	root: 'adding-warranty-claim-dialog',
	header: 'adding-warranty-claim-dialog__header',
	body: 'adding-warranty-claim-dialog__body'
};

const AddingWarrantyClaimDialog = (props: IAddingWarrantyClaimDialogProps) => {
	const {t} = useTranslation();

	const {objectId, spaceId, claimId, links, defaultProblemData, isOpen, onClose, onSuccess} =
		props;

	const [selectedWarrantyClaimId, setSelectedWarrantyClaimId] = useState<string | undefined>(
		claimId
	);
	const isFastAddingActive = useAppSelector(extractFastAdding);
	const addingEntityRef = useRef<IAddingProblemRefProps | IAddingWarrantyClaimRefProps>(null);
	const {data: object} = useObject(objectId);
	const {data: space} = useSpace(links?.spaceId, objectId);
	const [view, setView] = useState<AddingWarrantyClaimView>();
	const [saving, setSaving] = useState(false);
	const success = useRef<boolean>(false);

	const online = useAppSelector(s => s.offlineMode.networkStatus);

	const {data: localWarrantyClaims} = useLocalWarrantyClaims();
	const localWarrantyClaimsIds = localWarrantyClaims?.map(claim => claim.id);

	useEffect(() => {
		setView(!selectedWarrantyClaimId ? 'claim' : 'problem');
	}, [selectedWarrantyClaimId]);

	const stage = ObjectStageIds.WARRANTY;
	const processId = ProcessIds.WARRANTY_SERVICE;
	const stages = useTranslatedObjectStagesArray();
	const getSubTitle = useEntitySelectContainerSubTitle();
	const getSubSubTitle = useEntitySelectContainerSubSubTitle();
	const [contentMap, entitiesSelectMapProps] = useClaimMaps({
		objectId,
		claimId,
		spaceId,
		stage,
		processId,
		links,
		view,
		addingEntityRef,
		saving,
		setSaving,
		selectedWarrantyClaimId,
		setSelectedWarrantyClaimId,
		defaultProblemData,
		onClose
	});

	const queryClient = useQueryClient();

	const handleSave = useCallback(async () => {
		const updateLatest = async () => {
			if (selectedWarrantyClaimId) {
				await queryClient.invalidateQueries({
					queryKey: [...warrantyClaimsQueryKeys.detail(selectedWarrantyClaimId), objectId]
				});
			}
		};
		await saveClaim({
			addingEntityRef,
			localWarrantyClaimsIds,
			view,
			online,
			updateLatest,
			selectedWarrantyClaimId,
			success,
			onClose,
			setSaving,
			isFastAddingActive
		});
		if (success.current && onSuccess) {
			success.current = false;
			void onSuccess();
		}
	}, [
		localWarrantyClaimsIds,
		objectId,
		onClose,
		onSuccess,
		online,
		queryClient,
		selectedWarrantyClaimId,
		view,
		isFastAddingActive
	]);

	const handleCancel = useCallback(() => {
		if (addingEntityRef.current) {
			addingEntityRef.current.cancel();
		} else {
			onClose();
		}
	}, [onClose]);

	return (
		<Dialog
			className={addingWarrantyClaimDialogClassNames}
			isOpen={isOpen}
			footer={
				<ModalDialogButtons
					saving={saving}
					handleCancel={handleCancel}
					handleSave={handleSave}
					isProblemType={view === 'problem'}
				/>
			}
			fullScreenOnTablet
			onRequestClose={handleCancel}
		>
			{stages && (
				<EntitySelectContainer
					contentMap={contentMap}
					entitiesSelectMapProps={entitiesSelectMapProps}
					selectedStage={stage}
					selectedProcess={processId}
					stages={stages}
					getEntitySelectContainerSubTitle={getSubTitle}
					getEntitySelectContainerSubSubTitle={getSubSubTitle}
					stageTitle={t(
						'globalAddingEntityDialog.entitySelectContainer.processSelectDialogStageTitle'
					)}
					objectName={object?.name}
					spaceTypeId={space?.type}
					spaceName={space?.name}
				/>
			)}
		</Dialog>
	);
};

export default memo(AddingWarrantyClaimDialog);

import {makeInspectionEditRequest} from '@src/api/backend/inspection';
import {makeInspectionAddRequest} from '@src/api/backend/inspection/add';
import {
	addInspectionActions,
	deleteInspectionActions,
	editInspectionsActions
} from '@src/store/modules/entities/inspection/actions';
import {useQueryClient} from '@tanstack/react-query';
import IInspection from '@tehzor/tools/interfaces/inspections/IInspection';
import {ISavingInspection} from '@src/interfaces/saving/ISavingInspection';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {useUpdateEntity} from '../../hooks/useUpdateEntityList';
import {addTempFiles} from '../../utils/addTempFiles';
import {inspectionsQueryKeys} from '../keys';
import {addErrorToast, addSuccessToast} from '@src/utils/toasts';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import IError from '@tehzor/tools/interfaces/IError';
import {invalidateLinkedInspectionsQueries} from '@src/cache/actions/inspection/invalidateLinkedInspectionsQueries';
import {IInspectionsPagesSettingsState} from '@src/store/modules/settings/pages/inspections/interfaces';
import {delegationHistoryQueryKeys} from '@src/api/cache/delegationHistory/keys';
import {WorkingGroupTypeId} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroupType';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';

export interface IAddInspectionParams {
	objectId: string;
	links: IInspection['links'] | undefined;
	stage: ObjectStageIds | undefined;
	processId: ProcessIds;
	fields: ISavingInspection;
	key: string;
	currentQueryFilter?: IInspectionsPagesSettingsState;
}

export interface IEditInspectionParams {
	inspectionId: string;
	objectId: string;
	fields: ISavingInspection;
}

/**
 * Хук для предачи дефолтной функции мутации в QueryClient
 *  - Дефолтная функия нужна для того, чтобы не указывть её в самом хуке мутации явно
 *  - Если после запуска приложения в кэше будет лежать незаврешенная мутация для этого ключа,
 * 		то для повтра мутации будет использована mutationFn отсюда
 */
export const useInspectionsMutationDefaults = () => {
	const queryClient = useQueryClient();
	const {updateEntity} = useUpdateEntity(inspectionsQueryKeys);
	const dispatch = useAppDispatch();

	queryClient.setMutationDefaults(inspectionsQueryKeys.add(), {
		mutationFn: async (params: IAddInspectionParams) => {
			const {objectId, links, stage, processId, fields, key} = params;
			return makeInspectionAddRequest(
				objectId,
				links,
				{
					...fields,
					newAttachments: await addTempFiles(fields.newAttachments)
				},
				processId,
				key,
				stage
			);
		},
		onSuccess: (newInspection: IInspection, {key, objectId, links}: IAddInspectionParams) => {
			queryClient.removeQueries({queryKey: inspectionsQueryKeys.savingData(key)});
			updateEntity<IInspection>(
				newInspection,
				deleteInspectionActions.success,
				addInspectionActions.success,
				key
			);
			void invalidateLinkedInspectionsQueries(objectId, links);
			void queryClient.refetchQueries({
				queryKey: inspectionsQueryKeys.localList()
			});
			void queryClient.invalidateQueries({
				queryKey: [...inspectionsQueryKeys.list(), {objects: [objectId]}]
			});
			addSuccessToast('Успех', 'Осмотр успешно добавлен');
		},
		onError: () => {
			addErrorToast('Ошибка', 'при добавлении осмотра');
		},
		retry: false
	});

	queryClient.setMutationDefaults(inspectionsQueryKeys.edit(), {
		mutationFn: async ({inspectionId, objectId, fields}: IEditInspectionParams) => {
			await queryClient.cancelQueries({
				queryKey: inspectionsQueryKeys.detail(inspectionId)
			});
			return makeInspectionEditRequest(objectId, inspectionId, {
				...fields,
				newAttachments: await addTempFiles(fields.newAttachments)
			});
		},
		onError: err => {
			dispatch(editInspectionsActions.failure(err as IError));
		},
		onSuccess: async (data: IInspection, {inspectionId, objectId}: IEditInspectionParams) => {
			dispatch(editInspectionsActions.success(data));
			await invalidateLinkedInspectionsQueries(objectId);
			await queryClient.invalidateQueries({
				queryKey: inspectionsQueryKeys.detail(inspectionId)
			});
			await queryClient.invalidateQueries({queryKey: inspectionsQueryKeys.list()});
			await queryClient.invalidateQueries({
				queryKey: delegationHistoryQueryKeys.histories({
					objectId,
					inspectionId,
					workingGroupType: WorkingGroupTypeId.PERFORMERS
				})
			});
		}
	});
};

import {useCallback, useState} from 'react';
import {ActionButtons, Button, ModalDialog} from '@tehzor/ui-components';
import './AddingTaskDialog.less';
import {useEditableTask} from '../EditableTask/hooks/useEditableTask';
import ILocation from '@tehzor/tools/interfaces/ILocation';
import {TaskStatusId} from '@tehzor/tools/interfaces/tasks/ITaskStatus';
import {TaskTypeId} from '@tehzor/tools/interfaces/tasks/ITaskType';
import {TaskPriorityId} from '@tehzor/tools/interfaces/tasks/ITaskPriority';
import {useAddTask} from '@src/core/hooks/mutations/task/useAddTask';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';
import {useTranslation} from 'react-i18next';

interface IAddingTaskDialogProps {
	objectId?: string;
	floor?: string;
	planId?: string;
	location?: ILocation;
	status?: TaskStatusId;
	taskType?: TaskTypeId;
	taskPriority?: TaskPriorityId;
	startTime?: string;
	endTime?: string;
	activeGroup?: string;
	respUsers?: string[];
	links?: {
		spaceId?: string;
		checkListId?: string;
		checkItemId?: string;
	};
	onSuccess?: () => void | Promise<void>;
	isOpen: boolean;
	close(): void;
}

export const AddingTaskDialog = ({
	objectId,
	floor,
	isOpen,
	planId,
	location,
	status,
	taskType,
	taskPriority,
	activeGroup,
	respUsers,
	links,
	startTime,
	endTime,
	close,
	onSuccess
}: IAddingTaskDialogProps) => {
	const {mutateAsync: addTask} = useAddTask();
	const {t} = useTranslation();
	const [saving, setSaving] = useState(false);
	const [taskFields, getSavingData, isBlocking, reset] = useEditableTask({
		objectId,
		floor,
		planId,
		location,
		status,
		taskType,
		taskPriority,
		links,
		activeGroup,
		respUsers,
		startTime,
		endTime
	});

	const handleSave = useCallback(async () => {
		const savingData = await getSavingData();
		if (!savingData) {
			return;
		}
		setSaving(true);

		try {
			await addTask({...savingData, links});
			if (onSuccess) {
				void onSuccess();
			}
		} finally {
			setSaving(false);
			reset();
			close();
		}
	}, [addTask, close, getSavingData, links, reset, onSuccess]);

	const [closingDialog, getClosingConfirmation] = useTranslatedConfirmDialog();

	const cancel = useCallback(async () => {
		if (!isBlocking || (await getClosingConfirmation())) {
			close();
		}
	}, [isBlocking, getClosingConfirmation, close]);

	return (
		<ModalDialog
			className={{
				root: 'adding-task',
				content: 'adding-task__container'
			}}
			open={isOpen}
			title={t('addingTaskDialog.title')}
			footer={
				<ActionButtons>
					<Button
						type="cancel"
						label={t('cancelBtn.label')}
						disabled={saving}
						onClick={cancel}
					/>
					<Button
						type="accent-blue"
						label={t('applyBtn.label')}
						disabled={saving}
						onClick={handleSave}
					/>
				</ActionButtons>
			}
			fullScreenOnTablet
			onRequestClose={cancel}
			onAfterClose={reset}
		>
			{taskFields}
			{closingDialog}
		</ModalDialog>
	);
};

/**
 * Компонент с кнопками и чекбоксом модального окна с функцией быстрого добавления нарушений
 */

import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractFastAdding} from '@src/store/modules/settings/modalDialog/selectors';
import {ActionButtons, Button, Checkbox} from '@tehzor/ui-components';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useTranslation} from 'react-i18next';
import './ModalDialogButtons.less';
import {useEffect, useState} from 'react';
import {useIsMutating} from '@tanstack/react-query';
import {checksQueryKeys} from '@src/api/cache/checks/keys';
import {internalAcceptancesQueryKeys} from '@src/api/cache/internalAcceptances/keys';
import {FastAddingToast} from './components/FastAddingToast';
import {useOnlineManager} from '@src/core/hooks/useOnlineManager';
import {ButtonLabel} from './components/ButtonLabel';
import {modalDialogActions} from '@src/store/modules/settings/modalDialog/slice';

interface IActionButtons {
	saving: boolean;
	handleCancel: () => void;
	handleSave: () => Promise<void>;
	isProblemType: boolean;
}

export const ModalDialogButtons = ({
	isProblemType,
	saving,
	handleCancel,
	handleSave
}: IActionButtons) => {
	const dispatch = useAppDispatch();
	const {changeFastAddingMode, resetFastAddingMode} = modalDialogActions;
	const {t} = useTranslation();
	const isOnline = useOnlineManager();
	const isDesktop = useIsDesktop();
	const fastAdding = useAppSelector(extractFastAdding);
	const [isDisabled, setIsDisabled] = useState(false);
	const [isNotificationShown, setIsNotificationShown] = useState(false);

	const isCheckCreating = !!useIsMutating({mutationKey: checksQueryKeys.add()});
	const isAcceptanceCreating = !!useIsMutating({mutationKey: internalAcceptancesQueryKeys.add()});

	const handleCheckboxChange = () => dispatch(changeFastAddingMode(!fastAdding));

	useEffect(() => {
		setIsDisabled((isAcceptanceCreating || isCheckCreating) && isOnline);
	}, [isCheckCreating, isAcceptanceCreating, isOnline]);

	useEffect(() => {
		if ((isAcceptanceCreating || isCheckCreating) && isOnline) {
			return;
		}
		if (saving && isProblemType) {
			setIsDisabled(true);
			setIsNotificationShown(true);
			setTimeout(() => {
				setIsDisabled(false);
				setIsNotificationShown(false);
			}, 3000);
		}
	}, [isCheckCreating, isAcceptanceCreating, saving, isOnline, isProblemType]);

	useEffect(() => {
		dispatch(resetFastAddingMode());
	}, [dispatch]);

	return (
		<>
			<div
				className={
					isNotificationShown && !isDesktop
						? 'mobile-notification__visible'
						: 'mobile-notification'
				}
			>
				{isOnline
					? t('globalAddingEntityDialog.fastAddingProblem.onlineToast')
					: t('globalAddingEntityDialog.fastAddingProblem.offlineToast')}
			</div>
			<ActionButtons>
				{isProblemType ? (
					<Checkbox
						checked={fastAdding}
						onChange={handleCheckboxChange}
						className="custom-action-buttons__checkbox"
					>
						<span>{t('globalAddingEntityDialog.fastAddingProblem.checkbox')}</span>
					</Checkbox>
				) : null}
				{(isDesktop || !isProblemType) && (
					<Button
						type="cancel"
						label={t('cancelBtn.label')}
						disabled={saving}
						onClick={handleCancel}
						className="custom-action-buttons__button"
					/>
				)}
				<Button
					type="accent-blue"
					label={
						<ButtonLabel
							isDesktop={isDesktop}
							isProblemType={isProblemType}
							isFastProblemAddingActive={fastAdding}
						/>
					}
					className="custom-action-buttons__button"
					disabled={saving || isDisabled}
					onClick={handleSave}
				/>
			</ActionButtons>
			<FastAddingToast
				saving={saving}
				isProblemType={isProblemType}
				fastAdding={fastAdding}
			/>
		</>
	);
};

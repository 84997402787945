import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useMemo} from 'react';
import {LoadingPanel, Plate} from '@tehzor/ui-components';
import {useAvailableCheckLists} from '@src/core/hooks/checkLists/useAvailableCheckLists';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {useCheckListsIsLoading} from '@src/core/hooks/queries/checkLists/hooks';
import {MobileInfoBlock} from '@src/pages/SpacePage/components/info/MobileInfoBlock';
import {getCheckListsWithStages} from '../../utils/getCheckListsWithStages';
import {StageGroup} from '../StageGroup/StageGroup';
import {useSpace} from '@src/core/hooks/queries/space';
import {useHasUnfinished} from '@src/pages/SpacePage/hooks/useHasUnfinished';
import {useObjectStagesAsArray} from '@src/core/hooks/queries/objectStages/hooks';
import {useSpaceCheckListsHeader} from '../../hooks/useSpaceCheckListsHeader';
import {useTranslatedDictionaryArray} from '@src/core/hooks/translations/useTranslatedDictionaryArray';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';

export const CheckLists = () => {
	const {objectId, spaceId, stage} = useStrictParams<{
		objectId: string;
		spaceId: string;
		stage?: ObjectStageIds | 'all';
	}>();
	useSpaceCheckListsHeader(spaceId, objectId, stage || 'all');
	const checkLists = useAvailableCheckLists(objectId, spaceId, stage);

	const checkListsIds = useMemo(() => checkLists?.map(cl => cl.id), [checkLists]);

	const [hasUnfixedProblems, hasHandlingOrWaitingProblems, hasUnacceptedCheckItem] =
		useHasUnfinished(checkListsIds, objectId, spaceId);

	const listsLoading = useCheckListsIsLoading();

	const {data: space} = useSpace(spaceId, objectId);
	const {data: stages} = useObjectStagesAsArray();

	const checkListsWithStages = useMemo(() => getCheckListsWithStages(checkLists), [checkLists]);
	const checkListsStages = useMemo(
		() => stages?.filter(s => checkListsWithStages[s.id]),
		[checkListsWithStages, stages]
	);

	const translatedCheckListsStages = useTranslatedDictionaryArray(
		dictionaryKeys.objectStages,
		checkListsStages
	);

	return (
		<LoadingPanel active={listsLoading}>
			<>
				<div className="check-list-page__m-lists__info-block">
					{space && (
						<MobileInfoBlock
							space={space}
							objectId={objectId}
							hasUnacceptedCheckItem={hasUnacceptedCheckItem}
							hasHandlingOrWaitingProblems={hasHandlingOrWaitingProblems}
							hasUnfixedProblems={hasUnfixedProblems}
						/>
					)}
				</div>
				<Plate
					withoutPadding
					className="check-list-page__m-lists"
				>
					{translatedCheckListsStages &&
						translatedCheckListsStages.map((st, i, arr) => {
							const isLastStage = i === arr.length - 1;
							return (
								<StageGroup
									key={st.id}
									stage={st}
									mobileView
									checkLists={checkListsWithStages[st.id]}
									addDivider={!isLastStage}
								/>
							);
						})}
				</Plate>
			</>
		</LoadingPanel>
	);
};

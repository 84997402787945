import {memo, useCallback, useRef, useState} from 'react';
import './AddingCheckDialog.less';
import {ModalDialog} from '@tehzor/ui-components';
import {IAddingProblemRefProps} from '../AddingProblem';
import {IAddingInspectionRefProps} from '../AddingInspection';
import useAppSelector from '@src/core/hooks/useAppSelector';
import ICheck from '@tehzor/tools/interfaces/checks/ICheck';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {ICheckAddingEntityType} from '@src/store/modules/settings/checkAdding/reducers/entityType';
import {useEntityType} from './hooks/useEntityType';
import {useAvailableTypes} from './hooks/useAvailableTypes';
import {ISavingInspection} from '@src/interfaces/saving/ISavingInspection';
import EntitySelectContainer from '@tehzor/ui-components/src/components/containers/EntitySelectContainer/EntitySelectContainer';
import {useCheckMaps} from './hooks/useCheckMaps';
import {saveCheck} from './utils/saveCheck';
import {useAddCheck} from '@src/core/hooks/mutations/checks/useAddCheck';
import {useLocalChecks} from '@src/core/hooks/queries/checks/hooks';
import {useObject} from '@src/core/hooks/queries/objects/hooks';
import {ISavingProblem} from '@src/interfaces/saving/ISavingProblem';
import {useQueryClient} from '@tanstack/react-query';
import {checksQueryKeys} from '@src/api/cache/checks/keys';
import {useTranslation} from 'react-i18next';
import {useTranslatedObjectStagesArray} from '@src/core/hooks/translations/useTranslatedObjectStagesArray';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';
import {useSpace} from '@src/core/hooks/queries/space';
import {
	useEntitySelectContainerSubTitle,
	useEntitySelectContainerSubSubTitle
} from '@src/core/hooks/getEntitySelectContainerSubTitle';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {extractFastAdding} from '@src/store/modules/settings/modalDialog/selectors';
import {ModalDialogButtons} from '../ModalDialogButtons';
import {modalDialogActions} from '@src/store/modules/settings/modalDialog/slice';

interface IAddingCheckDialogProps {
	objectId: string;
	checkId?: string;
	links?: ICheck['links'];
	types?: ICheckAddingEntityType[];
	defaultProblemData?: ISavingProblem;
	defaultInspectionData?: ISavingInspection;
	isOpen: boolean;
	problemToCopyId?: string;
	createdBy?: string;
	onClose: () => void;
	onSuccess?: () => void | Promise<void>;
	title: string;
}

const addingCheckDialogClassNames = {
	root: 'adding-check-dialog',
	header: 'adding-check-dialog__header',
	body: 'adding-check-dialog__body'
};

const AddingCheckDialog = (props: IAddingCheckDialogProps) => {
	const {
		objectId,
		checkId,
		links,
		types,
		isOpen,
		problemToCopyId,
		createdBy,
		onSuccess,
		onClose,
		defaultProblemData,
		defaultInspectionData,
		title
	} = props;
	const {t} = useTranslation();
	const dispatch = useAppDispatch();
	const {resetFastAddingMode} = modalDialogActions;
	const online = useAppSelector(s => s.offlineMode.networkStatus);
	const {data: object} = useObject(objectId);
	const {data: space} = useSpace(links?.spaceId, objectId);

	const [selectedCheckId, setSelectedCheckId] = useState<string | undefined>(checkId);
	const queryClient = useQueryClient();

	const {data: localChecks} = useLocalChecks();
	const addingEntityRef = useRef<IAddingProblemRefProps | IAddingInspectionRefProps>(null);

	const availableTypes = useAvailableTypes(objectId, types, {createdBy});
	const type = useEntityType(objectId, availableTypes);

	const [saving, setSaving] = useState(false);
	const success = useRef<boolean>(false);

	const stage = ObjectStageIds.BUILDING;
	const processId = ProcessIds.OPERATIONAL_CONTROL;
	const stages = useTranslatedObjectStagesArray();
	const getSubTitle = useEntitySelectContainerSubTitle();
	const getSubSubTitle = useEntitySelectContainerSubSubTitle();

	const [contentMap, entitiesSelectMapProps] = useCheckMaps({
		objectId,
		checkId,
		stage,
		processId,
		links,
		availableTypes,
		type,
		addingEntityRef,
		problemToCopyId,
		saving,
		setSaving,
		selectedCheckId,
		setSelectedCheckId,
		defaultProblemData,
		defaultInspectionData,
		onClose
	});
	const addCheck = useAddCheck(object);
	const isFastAddingActive = useAppSelector(extractFastAdding);
	const handleSave = useCallback(async () => {
		const localChecksIds = localChecks?.map(check => check.id) || [];
		const addNewCheck = () =>
			addCheck({
				objectId,
				links,
				stage
			});
		const updateLatest = async () => {
			if (online && selectedCheckId && !localChecksIds.includes(selectedCheckId)) {
				await queryClient.invalidateQueries({
					queryKey: [...checksQueryKeys.detail(selectedCheckId), objectId]
				});
			}
		};

		await saveCheck({
			addingEntityRef,
			selectedCheckId,
			localChecksIds,
			updateLatest,
			online,
			addNewCheck,
			setSaving,
			success,
			onClose,
			isFastAddingActive
		});

		if (success.current && onSuccess) {
			success.current = false;
			void onSuccess();
		}
	}, [
		addCheck,
		links,
		localChecks,
		objectId,
		onClose,
		onSuccess,
		online,
		queryClient,
		selectedCheckId,
		stage,
		isFastAddingActive
	]);

	const handleCancel = useCallback(() => {
		if (addingEntityRef.current) {
			addingEntityRef.current.cancel();
		} else {
			onClose();
		}
		dispatch(resetFastAddingMode());
	}, [addingEntityRef, onClose, dispatch, resetFastAddingMode]);

	return (
		<ModalDialog
			dataTestId="AddingCheckDialog"
			className={addingCheckDialogClassNames}
			open={isOpen}
			title={title}
			footer={
				<ModalDialogButtons
					handleSave={handleSave}
					saving={saving}
					handleCancel={handleCancel}
					isProblemType={type === 'problem'}
				/>
			}
			fullScreenOnTablet
			onRequestClose={handleCancel}
		>
			{stages && (
				<EntitySelectContainer
					contentMap={contentMap}
					entitiesSelectMapProps={entitiesSelectMapProps}
					stagesSelectDialogTitle={t(
						'globalAddingEntityDialog.entitySelectContainer.processSelectDialogTitle'
					)}
					stageTitle={t(
						'globalAddingEntityDialog.entitySelectContainer.processSelectDialogStageTitle'
					)}
					stagesSelectDialogSaveLabel={t(
						'addingCheckDialog.stagesSelectDialog.saveLabel'
					)}
					stagesSelectDialogCancelLabel={t(
						'addingCheckDialog.stagesSelectDialog.cancelLabel'
					)}
					stages={stages}
					selectedStage={stage}
					selectedProcess={processId}
					getEntitySelectContainerSubTitle={getSubTitle}
					getEntitySelectContainerSubSubTitle={getSubSubTitle}
					objectName={object?.name}
					spaceTypeId={space?.type}
					spaceName={space?.name}
				/>
			)}
		</ModalDialog>
	);
};

export default memo(AddingCheckDialog);

import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useCallback, useEffect, useRef} from 'react';
import {Id, toast, ToastContainer} from 'react-toastify';
import './FastAddingToast.less';
import {ToastDescription} from './ToastDescription';

const FAST_ADDING_CONTAINER_ID = 'fast-adding-notification-container';

const savingIcon = <i className="tz-choice-circle-24 fast-adding-toast__icon"></i>;
interface IFastAddingToastProps {
	saving: boolean;
	isProblemType: boolean;
	fastAdding: boolean;
}

export const FastAddingToast = ({saving, isProblemType, fastAdding}: IFastAddingToastProps) => {
	const toastId = useRef<Id>('');
	const isDesktop = useIsDesktop();

	const showToast = useCallback(() => {
		if (!toast.isActive(toastId.current, FAST_ADDING_CONTAINER_ID)) {
			toastId.current = toast(<ToastDescription />, {
				autoClose: 2000,
				closeOnClick: false,
				draggable: false,
				className: 'fast-adding-toast',
				containerId: FAST_ADDING_CONTAINER_ID,
				icon: savingIcon,
				hideProgressBar: true,
				closeButton: false
			});
		}
	}, []);

	useEffect(() => {
		if (saving && isProblemType && fastAdding) {
			showToast();
		}
	}, [saving, showToast, isProblemType, fastAdding]);

	if (!isDesktop) return null;

	return (
		<ToastContainer
			limit={1}
			position="bottom-center"
			className="fast-adding-toast-container"
			containerId={FAST_ADDING_CONTAINER_ID}
		/>
	);
};

import {forwardRef, memo, Ref, useCallback} from 'react';
import {EditableFieldLabel, TextField} from '@tehzor/ui-components';
import {IPrescriptionProps} from './IPrescriptionProps';
import {useTranslation} from 'react-i18next';
import {useController, useFormContext} from 'react-hook-form';

const defaultTextAreaProps = {
	minRows: 2,
	maxRows: 6
};

const Prescription = (
	{
		className,
		name,
		style,
		label,
		addon,
		disabled,
		errorMessage,
		onSetValue,
		required,
		textAreaProps = defaultTextAreaProps
	}: IPrescriptionProps,
	ref?: Ref<HTMLTextAreaElement>
) => {
	const {t} = useTranslation();
	const {control} = useFormContext();

	const {
		field: {value},
		fieldState: {invalid}
	} = useController({
		name,
		control,
		rules: {
			required
		}
	});
	const handleChange = useCallback(
		(v: string) => {
			if (onSetValue) {
				onSetValue(name, v);
			}
		},
		[onSetValue, name]
	);

	return (
		<div
			className={className}
			style={style}
		>
			<EditableFieldLabel addon={addon}>
				{label ?? t('editableProblem.prescription.label')}
			</EditableFieldLabel>

			<TextField
				value={value}
				elementRef={ref}
				elementType="textarea"
				elementProps={textAreaProps}
				error={invalid ? errorMessage : undefined}
				disabled={disabled}
				onChange={handleChange}
			/>
		</div>
	);
};

export default memo(
	forwardRef<HTMLTextAreaElement, IPrescriptionProps>(Prescription)
) as typeof Prescription;

import {useTranslation} from 'react-i18next';
import {EntityInfo, EntityInfoDate} from '@tehzor/ui-components';
import {IListWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IListWorkAcceptance';
import {useDateFnsLocale} from '@src/core/hooks/translations/useDateFnsLocales';

interface IWorkAcceptanceProps {
	data: IListWorkAcceptance;
}

const checkIcon = <i className="tz-work-acceptance-28" />;

const WorkAcceptanceInfo = ({data}: IWorkAcceptanceProps) => {
	const {t} = useTranslation();
	const dateFnsLocale = useDateFnsLocale();
	return (
		<EntityInfo
			title={`${t('spacePage.desktopTable.WorkAcceptanceInfo.title')} №${data.number}`}
			subTitle={
				<EntityInfoDate
					labelFrom={t(
						'components.tableCell.info.entityInfoDateWithCountFiles.labelFrom'
					)}
					locale={dateFnsLocale}
					value={data.createdAt}
				/>
			}
			icon={checkIcon}
			iconColor="#57A4FF"
			iconSize={44}
		/>
	);
};

export default WorkAcceptanceInfo;

import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import {useQuery} from '@tanstack/react-query';
import {IGetSpaceProblemRepliesResponse} from '../interfaces';
import {extractSpaceProblemCommentsByProblem} from './selectors';
import {useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractUserRoles} from '@src/store/modules/auth/profile/selectors';
import {hasPermission} from '@tehzor/tools/utils/findPermission';

export const useSpaceProblemReplies = <T = IGetSpaceProblemRepliesResponse>(
	objectId: string,
	spaceId: string,
	permission?: boolean,
	select?: (data: IGetSpaceProblemRepliesResponse) => T
) => {
	const roles = useAppSelector(extractUserRoles);
	const canView = useMemo(() => hasPermission(roles, 'repliesView'), [roles]);

	return useQuery({
		queryKey: spacesQueryKeys.spaceProblemReplies(objectId, spaceId),
		meta: {
			error: 'при загрузке ответов'
		},
		select,
		enabled: permission || canView
	});
};

export const useSpaceProblemRepliesAsArray = (
	objectId: string,
	spaceId: string,
	permission?: boolean
) => useSpaceProblemReplies(objectId, spaceId, permission, extractSpaceProblemCommentsByProblem);

import {CSSProperties, memo, useCallback} from 'react';
import {EditableFieldLabel, TextField} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import ILocation from '@tehzor/tools/interfaces/ILocation';
import {InputType, ViewerType} from '@tehzor/ui-components/src/components/LocationSelect';
import {extractLocSelectDefaultSettings} from '@src/store/modules/settings/locationSelect/selectors';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {
	changeLocDefaultInputType,
	changeLocDefaultViewerType
} from '@src/store/modules/settings/locationSelect/actions';
import {usePlans, usePlansAsArray} from '@src/core/hooks/queries/plans/hooks';
import {useTranslation} from 'react-i18next';
import {TranslatedLocationSelect} from '@src/components/TranslatedLocationSelect';
import {useController, useFormContext} from 'react-hook-form';
import {SetValueOptions} from './ProblemForm';

interface ILocationProps {
	name: string;
	planIdFieldName: string;
	floorFieldName: string;
	objectId: string;
	onSetValue: (name: string, value: unknown, options?: SetValueOptions) => void;
	className?: string;
	style?: CSSProperties;
	label?: string;
	entityPlanId?: string;
	entityLocation?: ILocation;
	disabled?: boolean;
	errorMessage?: string;
	required?: boolean;
	planRequired?: boolean;
}

const LocationMemo = ({
	className,
	style,
	label,
	entityPlanId,
	name: locationFieldName,
	planIdFieldName,
	floorFieldName,
	objectId,
	entityLocation,
	disabled,
	errorMessage,
	onSetValue,
	required,
	planRequired
}: ILocationProps) => {
	const {data: plans} = usePlansAsArray(objectId);
	const {data: plansMap} = usePlans(objectId);
	const {t} = useTranslation();
	const fieldLabel = label || t('components.editableFields.location.label');
	const settings = useAppSelector(s => extractLocSelectDefaultSettings(s, objectId));
	const dispatch = useAppDispatch();
	const {control} = useFormContext();

	// обязательное требование заполнения снимается если нет планов
	const isException = plans?.length === 0 || !plans || !plansMap;
	const {
		field: {value: location},
		fieldState: {invalid}
	} = useController({
		name: locationFieldName,
		control,
		rules: {required: isException ? !isException : required}
	});

	const {
		field: {value: planIdValue},
		fieldState: {invalid: planIdInvalid}
	} = useController({
		name: planIdFieldName,
		control,
		rules: {required: isException ? !isException : planRequired}
	});

	const planId = planIdValue || entityPlanId;

	const handleChange = useCallback(
		(v: ILocation | null) => {
			onSetValue(locationFieldName, v);
		},
		[onSetValue, locationFieldName]
	);

	const handleViewerTypeChange = useCallback(
		(v: ViewerType) => {
			dispatch(changeLocDefaultViewerType(objectId, v));
		},
		[objectId, dispatch]
	);

	const handleInputTypeChange = useCallback(
		(v: InputType) => {
			dispatch(changeLocDefaultInputType(objectId, v));
		},
		[objectId, dispatch]
	);

	const handlePlanChange = useCallback(
		(v?: string) => {
			onSetValue(planIdFieldName, v);

			// Автоматическая установка этажа из плана
			if (v && plansMap) {
				const plan = plansMap.byId[v];
				onSetValue(floorFieldName, plan?.floor ? String(plan.floor) : '');
			} else {
				onSetValue(floorFieldName, '');
			}
		},
		[onSetValue, plansMap, floorFieldName, planIdFieldName]
	);

	const locationPlans =
		(entityLocation?.points?.length || entityLocation?.sectors?.length) && planId && plansMap
			? [plansMap.byId[planId]]
			: plans;

	return (
		<div
			className={className}
			style={style}
		>
			<EditableFieldLabel>{fieldLabel}</EditableFieldLabel>

			<TranslatedLocationSelect
				mode="edit"
				defaultViewerType={settings.defaultViewerType}
				defaultInputType={settings.defaultInputType}
				planId={planId}
				location={location}
				entityLocation={entityLocation}
				plans={locationPlans}
				multiplePoints
				onLocationChange={handleChange}
				onViewerTypeChange={handleViewerTypeChange}
				onInputTypeChange={handleInputTypeChange}
				onPlanChange={handlePlanChange}
			>
				{(displayValue, open: () => void) => (
					<TextField
						className="text-field_interactive"
						disabled={disabled}
						elementType="div"
						value={displayValue}
						icon={<i className="tz-location-20" />}
						error={invalid || planIdInvalid ? errorMessage : undefined}
						onClick={open}
					/>
				)}
			</TranslatedLocationSelect>
		</div>
	);
};
export const Location = memo(LocationMemo) as typeof LocationMemo;

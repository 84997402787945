import {ISavingProblem} from '@src/interfaces/saving/ISavingProblem';
import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';

export type IAddProblemResponse = IProblem;

/**
 * Добавляет нарушение
 *
 * @param objectId id объекта
 * @param links ссылки на другие сущности
 * @param fields данные нарушения
 * @param processId id процесса
 * @param localId локальный id нарушения
 * @param stage стадия объекта
 */
export const makeProblemAddRequest = async (
	objectId: string,
	links: IProblem['links'] | undefined,
	fields: ISavingProblem,
	processId: ProcessIds,
	localId?: string,
	stage: ObjectStageIds = ObjectStageIds.BUILDING,

) => {
	const response = await httpRequests.withToken.post<IAddProblemResponse>('/problems', {
		objectId,
		links,
		stage,
		processId,
		localId,
		...fields
	});
	return response.data;
};

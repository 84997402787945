import {memo} from 'react';
import {PageBreadcrumbs} from './PageBreadcrumbs';
import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';
import {DesktopTable} from './table/DesktopTable';
import {DesktopInfoBlock} from './info/DesktopInfoBlock';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {useSpaceTitle} from '@src/core/hooks/spaces/useSpaceTitle';
import {useSpaceSectionsMenu} from '@src/core/hooks/useSpaceSectionsMenu';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {SpacesPageVariants} from '@src/interfaces/SpacesPageVariants';
import {DesktopWorkAcceptanceTable} from './workAcceptanceTable/DesktopTable';

interface IDesktopProps {
	objectId: string;
	spaceId: string;
	space: ISpace;
	stage?: ObjectStageIds;
	hasUnfixedProblems: boolean;
	hasHandlingOrWaitingProblems: boolean;
	hasUnacceptedCheckItem: boolean;
	spacesPageVariants: SpacesPageVariants;
}

export const Desktop = memo(
	({
		objectId,
		spaceId,
		space,
		stage,
		hasUnfixedProblems,
		hasHandlingOrWaitingProblems,
		hasUnacceptedCheckItem,
		spacesPageVariants
	}: IDesktopProps) => {
		const spaceTitle = useSpaceTitle(space);
		const sections = useSpaceSectionsMenu(objectId, spaceId, stage);

		useAppHeader(
			{
				title: spaceTitle,
				showBackBtn: true,
				sectionsMenu: sections
			},
			[spaceTitle, sections]
		);

		return (
			<div className="page-cont space-page">
				<PageBreadcrumbs
					objectId={objectId}
					spaceId={spaceId}
					showActions={spacesPageVariants === SpacesPageVariants.PROBLEMS}
				/>
				<div className="space-page__d-columns">
					<div className="space-page__d-column">
						<DesktopInfoBlock
							space={space}
							objectId={objectId}
							hasUnfixedProblems={hasUnfixedProblems}
							hasHandlingOrWaitingProblems={hasHandlingOrWaitingProblems}
							hasUnacceptedCheckItem={hasUnacceptedCheckItem}
						/>
					</div>

					{spacesPageVariants === SpacesPageVariants.PROBLEMS && (
						<DesktopTable
							objectId={objectId}
							spaceId={spaceId}
							space={space}
						/>
					)}

					{spacesPageVariants === SpacesPageVariants.WORK_ACCEPTANCES && (
						<DesktopWorkAcceptanceTable
							objectId={objectId}
							spaceId={spaceId}
						/>
					)}
				</div>
			</div>
		);
	}
);

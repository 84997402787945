import {memo, useCallback, useRef, useState} from 'react';
import './AddingWorkAcceptanceDialog.less';
import {ModalDialog} from '@tehzor/ui-components';
import {IAddingProblemRefProps} from '../AddingProblem';
import {IAddingInspectionRefProps} from '../AddingInspection';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {useEntityType} from './hooks/useEntityType';
import {useAvailableTypes} from './hooks/useAvailableTypes';
import EntitySelectContainer from '@tehzor/ui-components/src/components/containers/EntitySelectContainer/EntitySelectContainer';
import {useObject} from '@src/core/hooks/queries/objects/hooks';
import {useTranslatedObjectStagesArray} from '@src/core/hooks/translations/useTranslatedObjectStagesArray';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';
import {
	useEntitySelectContainerSubTitle,
	useEntitySelectContainerSubSubTitle
} from '@src/core/hooks/getEntitySelectContainerSubTitle';
import {useSpace} from '@src/core/hooks/queries/space';
import {useWorkAcceptanceMaps} from './hooks/useWorkAcceptanceMaps';
import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';
import IInspection from '@tehzor/tools/interfaces/inspections/IInspection';
import {useStructureById} from '@src/fsd/src/entities/Structures';
import {IWorkAcceptanceAddingEntityType} from '@src/store/modules/settings/workAcceptanceAdding/interfaces';
import {ModalDialogButtons} from '../ModalDialogButtons';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {modalDialogActions} from '@src/store/modules/settings/modalDialog/slice';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractFastAdding} from '@src/store/modules/settings/modalDialog/selectors';

interface IAddingWorkAcceptanceDialogProps {
	objectId: string;
	stage: ObjectStageIds;
	workAcceptanceId?: string;
	links?: IProblem['links'] | IInspection['links'];
	types?: IWorkAcceptanceAddingEntityType[];
	isOpen: boolean;
	onClose: () => void;
	onSuccess?: () => void | Promise<void>;
	title: string;
	entityButtonTitle?: string;
}

const addingCheckDialogClassNames = {
	root: 'adding-work-acceptance-dialog',
	header: 'adding-work-acceptance__header',
	body: 'adding-work-acceptance__body'
};

export const AddingWorkAcceptanceDialog = memo((props: IAddingWorkAcceptanceDialogProps) => {
	const {
		objectId,
		stage,
		workAcceptanceId,
		links,
		types,
		isOpen,
		onSuccess,
		onClose,
		title,
		entityButtonTitle
	} = props;

	const dispatch = useAppDispatch();
	const {resetFastAddingMode} = modalDialogActions;
	const isFastAddingActive = useAppSelector(extractFastAdding);

	const {data: object} = useObject(objectId);
	const {data: space} = useSpace(links?.spaceId, objectId);
	const {data: structure} = useStructureById(objectId, links?.structureId);

	const addingEntityRef = useRef<IAddingProblemRefProps | IAddingInspectionRefProps>(null);

	const availableTypes = useAvailableTypes(objectId, types);
	const type = useEntityType(objectId, availableTypes);

	const [saving, setSaving] = useState(false);
	const success = useRef<boolean>(false);
	const processId = ProcessIds.ACCEPTANCE_CONTROL;
	const stages = useTranslatedObjectStagesArray();
	const getSubTitle = useEntitySelectContainerSubTitle();
	const getSubSubTitle = useEntitySelectContainerSubSubTitle();
	const [contentMap, entitiesSelectMapProps] = useWorkAcceptanceMaps({
		objectId,
		workAcceptanceId,
		stage,
		processId,
		links,
		availableTypes,
		type,
		addingEntityRef,
		saving,
		setSaving,
		onClose
	});

	const handleSave = useCallback(async () => {
		if (addingEntityRef.current) {
			const extraLinks: IProblem['links'] | IInspection['links'] = {
				workAcceptanceId,
				spaceId: space?.id,
				structureId: structure?.id
			};
			const savingData = await addingEntityRef.current.getSavingData(true);
			if (!savingData) {
				return;
			}
			setSaving(true);
			if (addingEntityRef.current.saveCache && savingData) {
				const cachedData = await addingEntityRef.current.saveCache(savingData, extraLinks);
				if (cachedData) {
					await addingEntityRef.current.save(savingData, extraLinks, cachedData.cacheKey);
				}
			}
		} else {
			if (isFastAddingActive) return;
			onClose();
		}
		success.current = true;
		if (success.current) {
			setSaving(false);
			if (isFastAddingActive) return;
			onClose();
		}
		if (success.current && onSuccess) {
			success.current = false;
			void onSuccess();
		}
	}, [onClose, onSuccess, space?.id, structure?.id, workAcceptanceId, isFastAddingActive]);

	const handleCancel = useCallback(() => {
		if (addingEntityRef.current) {
			addingEntityRef.current.cancel();
		} else {
			onClose();
		}
		dispatch(resetFastAddingMode());
	}, [addingEntityRef, onClose, dispatch, resetFastAddingMode]);

	return (
		<ModalDialog
			dataTestId="AddingWorkAcceptanceDialog"
			className={addingCheckDialogClassNames}
			open={isOpen}
			title={title}
			footer={
				<ModalDialogButtons
					handleSave={handleSave}
					saving={saving}
					handleCancel={handleCancel}
					isProblemType={type === 'problem'}
				/>
			}
			fullScreenOnTablet
			onRequestClose={handleCancel}
		>
			{stages && (
				<EntitySelectContainer
					contentMap={contentMap}
					entitiesSelectMapProps={entitiesSelectMapProps}
					stages={stages}
					selectedStage={stage}
					selectedProcess={processId}
					getEntitySelectContainerSubTitle={getSubTitle}
					getEntitySelectContainerSubSubTitle={getSubSubTitle}
					objectName={object?.name}
					spaceTypeId={space?.type}
					spaceName={space?.name}
					structureTypeId={structure?.type}
					structureName={structure?.name}
					entityButtonTitle={entityButtonTitle}
					disable
				/>
			)}
		</ModalDialog>
	);
});

import {useCallback, useState} from 'react';
import './EditableProblemDialog.less';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {useProblemTitle} from './utils/useProblemTitle';
import {ActionButtons, Button, ModalDialog} from '@tehzor/ui-components';
import {useEditProblem} from '@src/core/hooks/mutations/problem/useEditProblem';
import {IPreparedProblem} from '@src/pages/ProblemPage/interfaces/IPreparedProblem';
import {useObject} from '@src/core/hooks/queries/objects/hooks';
import {useTranslation} from 'react-i18next';
import {useEditableProblemForm} from '../Forms';

interface IEditableProblemDialogProps {
	objectId: string;
	problem: IPreparedProblem;
	isOpen: boolean;
	onClose: () => void;
}

/**
 * Окно редактирования нарушения
 */
const EditableProblemDialog = ({
	objectId,
	problem,
	isOpen,
	onClose
}: IEditableProblemDialogProps) => {
	const {t} = useTranslation();
	const [saving, setSaving] = useState(false);
	const {data: object} = useObject(objectId);

	const {
		form,
		isBlocking,
		getSavingData,
		resetForm: reset
	} = useEditableProblemForm({
		objectId,
		stage: problem.stage,
		processId: problem.processId,
		defaultData: undefined,
		problem,
		saving
	});
	const [editProblem] = useEditProblem(object);
	const save = useCallback(async () => {
		setSaving(true);
		if (problem) {
			const savingData = await getSavingData(true);
			if (savingData) {
				editProblem({problemId: problem.id, objectId, fields: savingData});
				onClose();
				reset();
			}
		}
		setSaving(false);
	}, [objectId, problem, getSavingData, editProblem, onClose, reset]);

	const [closingDialog, getClosingConfirmation] = useConfirmDialog(
		t('editableProblemDialog.useConfirmDialog.title'),
		t('editableProblemDialog.useConfirmDialog.message'),
		{
			acceptBtnProps: {type: 'accent-red'},
			acceptBtnLabel: t('useConfirmDialog.acceptBtnLabel'),
			rejectBtnLabel: t('useConfirmDialog.rejectBtnLabel')
		}
	);

	const handleClose = useCallback(async () => {
		if (onClose && (!isBlocking || (await getClosingConfirmation()))) {
			onClose();
			reset();
		}
	}, [isBlocking, onClose, reset, getClosingConfirmation]);

	const title = useProblemTitle(problem);

	return (
		<ModalDialog
			className="editable-problem-dialog"
			open={isOpen}
			title={title}
			footer={
				<ActionButtons>
					<Button
						type="accent-blue"
						label={t('editableProblemDialog.dialog.footer.actionButtons.save')}
						disabled={saving}
						onClick={save}
					/>
					<Button
						type="cancel"
						label={t('editableProblemDialog.dialog.footer.actionButtons.cancel')}
						disabled={saving}
						onClick={handleClose}
					/>
				</ActionButtons>
			}
			fullScreenOnTablet
			onRequestClose={handleClose}
		>
			{form}
			{closingDialog}
		</ModalDialog>
	);
};

export default EditableProblemDialog;

import {IAddingInspectionRefProps} from '@src/components/AddingInspection';
import {IAddingProblemRefProps} from '@src/components/AddingProblem';
import ICheck from '@tehzor/tools/interfaces/checks/ICheck';
import IInspection from '@tehzor/tools/interfaces/inspections/IInspection';
import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';
import {Dispatch, MutableRefObject, RefObject, SetStateAction} from 'react';

interface ISaveCheckProps {
	addingEntityRef: RefObject<IAddingProblemRefProps | IAddingInspectionRefProps>;
	selectedCheckId?: string;
	localChecksIds: string[];
	online: boolean;
	addNewCheck: () => ICheck | undefined;
	updateLatest: (checkId?: string) => void;
	setSaving: Dispatch<SetStateAction<boolean>>;

	isFastAddingActive: boolean;
	success: MutableRefObject<boolean>;
	onClose: () => void;
}

export const saveCheck = async ({
	addingEntityRef,
	selectedCheckId,
	localChecksIds,
	online,
	updateLatest,
	addNewCheck,
	setSaving,
	success,
	onClose,
	isFastAddingActive
}: ISaveCheckProps) => {
	if (addingEntityRef.current) {
		let checkId = selectedCheckId;
		const extraLinks: IProblem['links'] | IInspection['links'] = {checkId};
		const savingData = await addingEntityRef.current.getSavingData(true);
		if (!savingData) {
			return;
		}
		if (addingEntityRef.current.saveCache && savingData) {
			if (checkId === undefined) {
				setSaving(true);
				const check = addNewCheck();
				checkId = check?.id;
				if (!check || !checkId) {
					return;
				}
				extraLinks.checkId = checkId;
				await addingEntityRef.current.saveCache(savingData, extraLinks);
			} else if (checkId && localChecksIds.includes(checkId)) {
				extraLinks.checkId = checkId;
				await addingEntityRef.current.saveCache(savingData, extraLinks);
			} else {
				const cachedData = await addingEntityRef.current.saveCache(savingData, extraLinks);
				if (cachedData) {
					await addingEntityRef.current.save(savingData, extraLinks, cachedData.cacheKey);
				}
			}
		}
		if (checkId && online) {
			void updateLatest();
		}
	} else {
		if (isFastAddingActive) return;
		onClose();
	}
	success.current = true;
	if (success.current) {
		setSaving(false);
		if (isFastAddingActive) return;
		onClose();
	}
};

import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {
	IEditWorkAcceptanceResponse,
	makeWorkAcceptanceEditRequest
} from '@src/api/backend/workAcceptance';
import {ISavingWorkAcceptance} from '@src/interfaces/saving/ISavingWorkAcceptance';
import {queryClient} from '@src/api/QueryClient';
import {workAcceptancesQueryKeys} from '@src/api/cache/workAcceptances/keys';
import {makeWorkAcceptanceResultEditRequest} from '@src/api/backend/workAcceptance/editResult';
import {delegationHistoryQueryKeys} from '@src/api/cache/delegationHistory/keys';
import {WorkingGroupTypeId} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroupType';

const request = () => ({type: types.EDIT_REQUEST});

const success = (response: IEditWorkAcceptanceResponse) => {
	void queryClient.invalidateQueries({queryKey: workAcceptancesQueryKeys.lists()});
	void queryClient.invalidateQueries({queryKey: workAcceptancesQueryKeys.paginate()});
	void queryClient.invalidateQueries({queryKey: workAcceptancesQueryKeys.details()});
	void queryClient.invalidateQueries({
		queryKey: delegationHistoryQueryKeys.histories({
			objectId: response.objectId,
			workAcceptanceId: response.id,
			workingGroupType: WorkingGroupTypeId.ACCEPTORS
		})
	});
	return {
		type: types.EDIT_SUCCESS,
		payload: response
	};
};

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при изменении приёмки работ');
	return {
		type: types.EDIT_FAILURE,
		payload: error
	};
};

/**
 * Изменяет приёмку работ
 */
export const editWorkAcceptance = (
	objectId: string,
	workAcceptanceId: string,
	data: ISavingWorkAcceptance
) =>
	createApiAction<IEditWorkAcceptanceResponse>(request, success, failure, () =>
		makeWorkAcceptanceEditRequest(objectId, workAcceptanceId, data)
	);

/**
 * Изменяет результат приёмки работ.
 * Изменение результата оставляем тут, т.к. сущность одна и та же, экшены одни и те же
 * При переносе на React Query, будет разделено на два хука
 */
export const editWorkAcceptanceResult = (
	objectId: string,
	workAcceptanceId: string,
	data: ISavingWorkAcceptance
) =>
	createApiAction<IEditWorkAcceptanceResponse>(request, success, failure, () =>
		makeWorkAcceptanceResultEditRequest(objectId, workAcceptanceId, data)
	);

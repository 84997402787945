import {CSSProperties, memo, useCallback, useMemo, useState} from 'react';
import {useContractsList, useContractsListArray} from '@src/core/hooks/queries/contracts/hooks';
import {IContractsFiltersState} from '@src/store/modules/settings/pages/contracts/reducers';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {
	EditableFieldLabel,
	Select2,
	SelectOption,
	SelectPopup,
	TextFieldWithForwardedRef
} from '@tehzor/ui-components';
import SelectSearch, {
	flatFilter
} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {useTranslation} from 'react-i18next';
import {useController, useFormContext} from 'react-hook-form';
import {editableProblemFields} from '../useEditableProblemForm/editableProblemFields';
import {useUpdateEffect} from 'react-use';
import {SetValueOptions} from './ProblemForm';

interface IContractsProps {
	className?: string;
	name: string;
	style?: CSSProperties;
	label?: string;
	objectId?: string;
	stage?: ObjectStageIds;
	disabled?: boolean;
	required?: boolean;
	onSetValue?: (name: string, value: unknown, options?: SetValueOptions) => void;
	errorMessage?: string;
}

const arrowIcon = <i className="tz-simple-arrow-20" />;

export const Contracts = memo(
	({
		className,
		name,
		style,
		label,
		objectId,
		stage,
		disabled,
		required,
		onSetValue,
		errorMessage
	}: IContractsProps) => {
		const {t} = useTranslation();
		const {getValues, control} = useFormContext();
		const [planId, categoryId] = getValues([
			editableProblemFields.PLAN_ID,
			editableProblemFields.CATEGORY_ID
		]);
		const filters: IContractsFiltersState = useMemo(
			() => ({
				categoryIds: categoryId ? [categoryId] : undefined,
				objectIds: objectId ? [objectId] : undefined,
				stageIds: stage ? [stage] : undefined,
				planIds: planId ? [planId] : undefined
			}),
			[objectId, stage, planId, categoryId]
		);
		const {data: contractsData} = useContractsList(filters);
		const {data: contracts} = useContractsListArray(filters);

		// обязательное требование заполнения снимается если нет договоров на выбор
		const isException = contracts?.length === 0 || !contracts;
		const {
			field: {value},
			fieldState: {invalid}
		} = useController({
			name,
			control,
			rules: {required: isException ? !isException : required}
		});

		const contract = value ? contractsData?.byId[value] : undefined;
		const [search, setSearch] = useState('');
		const clearSearch = () => setSearch('');
		const isSearchShown = (contracts?.length || 0) >= 8;

		const filteredContracts = useMemo(
			() => (contracts ? flatFilter(contracts, 'name', search) : undefined),
			[contracts, search]
		);

		const handleClear = useCallback(() => {
			if (onSetValue) {
				onSetValue(name, null, {shouldDirty: false, shouldValidate: true});
			}
		}, [name, onSetValue]);

		const handleChange = useCallback(
			(val: string) => {
				if (onSetValue) {
					onSetValue(name, val);
				}
			},
			[name, onSetValue]
		);

		useUpdateEffect(() => {
			handleClear();
		}, [planId, categoryId]);

		if (!contracts) {
			return null;
		}

		const selectSearch = isSearchShown ? (
			<SelectSearch
				value={search}
				onChange={setSearch}
				onClear={clearSearch}
			/>
		) : null;

		return (
			<div
				className={className}
				style={style}
			>
				<EditableFieldLabel>
					{label ?? t('editableProblem.contracts.label')}
				</EditableFieldLabel>
				<SelectPopup
					noHeader={!isSearchShown}
					search={selectSearch}
					trigger={
						<TextFieldWithForwardedRef
							elementType="div"
							value={contract?.name}
							icon={arrowIcon}
							error={invalid ? errorMessage : undefined}
							onClearClick={handleClear}
							disabled={disabled}
							cleanable={!!value}
						/>
					}
				>
					<Select2
						value={value || undefined}
						onChange={handleChange}
					>
						{filteredContracts?.map(contractItem => (
							<SelectOption
								dataTestId="ContractSelectOption"
								key={contractItem.id}
								itemKey={contractItem.id}
								content={contractItem.name}
								inputType="radio"
							/>
						))}
					</Select2>
				</SelectPopup>
			</div>
		);
	}
);

import {Dispatch, ReactNode, SetStateAction, useEffect} from 'react';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {
	IGlobalAddingLinks,
	IGlobalAddingPermissions,
	IGlobalAddingRef,
	IGlobalAddingType
} from '../GlobalAddingEntityDialog';
import AddingCheckGlobal from '@src/components/AddingCheckDialog/AddingCheckGlobal';
import {ISavingProblem} from '@src/interfaces/saving/ISavingProblem';
import AddingInternalAcceptanceGlobal from '@src/components/AddingInternalAcceptanceDialog/AddingInternalAcceptanceGlobal';
import AddingOwnerAcptGlobal, {
	AddingOwnerAcceptanceView
} from '@src/components/AddingOwnerAcptDialog/AddingOwnerAcptGlobal';
import AddingWarrantyClaimGlobal from '@src/components/AddingWarrantyClaimDialog/AddingWarrantyClaimGlobal';
import {IEntitySelectProps} from '@tehzor/ui-components/src/components/containers/EntitySelectContainer/components/EntitySelect/EntitySelect';
import {ISavingInspection} from '@src/interfaces/saving/ISavingInspection';
import {AddingWarrantyClaimView} from '@src/components/AddingWarrantyClaimDialog/AddingWarrantyClaimDialog';
import {useLatestChecks} from '@src/components/AddingCheckDialog/hooks/useLatestChecks';
import {useLatestInternalAcceptances} from '@src/components/AddingInternalAcceptanceDialog/hooks/useLatestInternalAcceptances';
import {
	useLatestOwnerAcceptanceId,
	useLatestOwnerAcceptancesAsArray
} from '@src/core/hooks/queries/ownerAcceptances';
import {useExtractLatestChecksAsArray} from '@src/core/hooks/queries/checks/latest/hooks';
import {useExtractLatestInternalAcceptancesAsArray} from '@src/core/hooks/queries/internalAcceptances/latest/hooks';
import {
	useExtractLatestWarrantyClaimId,
	useExtractLatestWarrantyClaimsAsArray
} from '@src/core/hooks/queries/warrantyClaims/latest/hooks';
import {useTranslation} from 'react-i18next';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';

interface IUseEntitiesMapsProps {
	objectId: string;
	spaceId?: string;
	links?: IGlobalAddingLinks;
	permissions: IGlobalAddingPermissions;
	addingEntityRef: IGlobalAddingRef;
	defaultProblemData?: ISavingProblem;
	defaultInspectionData?: ISavingInspection;
	types?: IGlobalAddingType[];

	saving: boolean;
	setSaving: Dispatch<SetStateAction<boolean>>;

	selectedCheckId?: string;
	selectedInternalAcceptanceId?: string;
	selectedOwnerAcceptanceId?: string;
	selectedWarrantyClaimId?: string;

	setSelectedCheckId: Dispatch<SetStateAction<string | undefined>>;
	setSelectedInternalAcceptanceId: Dispatch<SetStateAction<string | undefined>>;
	setSelectedOwnerAcceptanceId: Dispatch<SetStateAction<string | undefined>>;
	setSelectedWarrantyClaimId: Dispatch<SetStateAction<string | undefined>>;

	ownerAcceptanceView: AddingOwnerAcceptanceView;
	setOwnerAcceptanceView: Dispatch<SetStateAction<AddingOwnerAcceptanceView>>;

	warrantyClaimView: AddingWarrantyClaimView;
	setWarrantyClaimView: Dispatch<SetStateAction<AddingWarrantyClaimView>>;

	onClose: () => void;
}

export const useEntitiesMaps = ({
	objectId,
	spaceId,
	links,
	permissions,
	addingEntityRef,
	defaultProblemData,
	defaultInspectionData,
	types,
	saving,
	setSaving,
	selectedCheckId,
	selectedInternalAcceptanceId,
	selectedOwnerAcceptanceId,
	selectedWarrantyClaimId,
	setSelectedCheckId,
	setSelectedInternalAcceptanceId,
	setSelectedOwnerAcceptanceId,
	setSelectedWarrantyClaimId,
	ownerAcceptanceView,
	setOwnerAcceptanceView,
	warrantyClaimView,
	setWarrantyClaimView,
	onClose
}: IUseEntitiesMapsProps): [
	Record<ObjectStageIds, Record<ProcessIds, ReactNode>>,
	Record<ObjectStageIds, Record<ProcessIds, IEntitySelectProps>>
] => {
	const {t} = useTranslation();

	const contentMap = {} as Record<ObjectStageIds, Record<ProcessIds, ReactNode>>;
	const entitiesSelectMapProps = {} as Record<
		ObjectStageIds,
		Record<ProcessIds, IEntitySelectProps>
	>;

	const {data: latestChecks} = useExtractLatestChecksAsArray(objectId, links?.spaceId);

	useLatestChecks(objectId, setSelectedCheckId, selectedCheckId, links);

	if (permissions.checks) {
		contentMap[ObjectStageIds.BUILDING] = {} as Record<ProcessIds, ReactNode>;
		contentMap[ObjectStageIds.BUILDING][ProcessIds.OPERATIONAL_CONTROL] = (
			<AddingCheckGlobal
				stage={ObjectStageIds.BUILDING}
				processId={ProcessIds.OPERATIONAL_CONTROL}
				objectId={objectId}
				onClose={onClose}
				links={links}
				saving={saving}
				setSaving={setSaving}
				ref={addingEntityRef}
				selectedCheckId={selectedCheckId}
				defaultProblemData={defaultProblemData}
				defaultInspectionData={defaultInspectionData}
				types={types}
			/>
		);

		entitiesSelectMapProps[ObjectStageIds.BUILDING] = {} as Record<
			ProcessIds,
			IEntitySelectProps
		>;
		entitiesSelectMapProps[ObjectStageIds.BUILDING][ProcessIds.OPERATIONAL_CONTROL] = {
			name: t('globalAddingEntityDialog.useEntitiesMaps.building.name'),
			newName: t('globalAddingEntityDialog.useEntitiesMaps.building.newName'),
			selected: selectedCheckId,
			onEntityChange: setSelectedCheckId,
			entities: latestChecks,
			localEntities: []
		};
	}

	const {data: latestInternalAcceptances} = useExtractLatestInternalAcceptancesAsArray(
		objectId,
		links?.spaceId
	);

	useLatestInternalAcceptances(
		objectId,
		setSelectedInternalAcceptanceId,
		selectedInternalAcceptanceId,
		links
	);

	if (permissions.internalAcceptances) {
		contentMap[ObjectStageIds.ACCEPTANCE] = {} as Record<ProcessIds, ReactNode>;
		contentMap[ObjectStageIds.ACCEPTANCE][ProcessIds.INTERNAL_ACCEPTANCE] = (
			<AddingInternalAcceptanceGlobal
				stage={ObjectStageIds.ACCEPTANCE}
				processId={ProcessIds.INTERNAL_ACCEPTANCE}
				objectId={objectId}
				onClose={onClose}
				links={links}
				ref={addingEntityRef}
				selectedInternalAcceptanceId={selectedInternalAcceptanceId}
				defaultProblemData={defaultProblemData}
				defaultInspectionData={defaultInspectionData}
				types={types}
				saving={saving}
				setSaving={setSaving}
			/>
		);

		entitiesSelectMapProps[ObjectStageIds.ACCEPTANCE] = {} as Record<
			ProcessIds,
			IEntitySelectProps
		>;
		entitiesSelectMapProps[ObjectStageIds.ACCEPTANCE][ProcessIds.INTERNAL_ACCEPTANCE] = {
			name: t('globalAddingEntityDialog.useEntitiesMaps.acceptance.name'),
			newName: t('globalAddingEntityDialog.useEntitiesMaps.acceptance.newName'),
			selected: selectedInternalAcceptanceId,
			onEntityChange: setSelectedInternalAcceptanceId,
			entities: latestInternalAcceptances,
			localEntities: []
		};
	}

	const {data: latestOwnerAcceptanceId} = useLatestOwnerAcceptanceId(objectId, links?.spaceId);
	const {data: latestOwnerAcceptances} = useLatestOwnerAcceptancesAsArray(
		objectId,
		links?.spaceId
	);

	useEffect(() => {
		if (latestOwnerAcceptanceId) {
			setSelectedOwnerAcceptanceId(latestOwnerAcceptanceId);
		}
	}, [latestOwnerAcceptanceId, setSelectedOwnerAcceptanceId]);

	if (permissions.acceptances) {
		contentMap[ObjectStageIds.TRANSFER] = {} as Record<ProcessIds, ReactNode>;
		contentMap[ObjectStageIds.TRANSFER][ProcessIds.UNITS_HANDOVER] = (
			<AddingOwnerAcptGlobal
				objectId={objectId}
				spaceId={spaceId}
				onClose={onClose}
				links={links}
				ref={addingEntityRef}
				defaultProblemData={defaultProblemData}
				selectedOwnerAcceptanceId={selectedOwnerAcceptanceId}
				view={ownerAcceptanceView}
				setView={setOwnerAcceptanceView}
				saving={saving}
				setSaving={setSaving}
			/>
		);
		entitiesSelectMapProps[ObjectStageIds.TRANSFER] = {} as Record<
			ProcessIds,
			IEntitySelectProps
		>;
		entitiesSelectMapProps[ObjectStageIds.TRANSFER][ProcessIds.UNITS_HANDOVER] = {
			name: t('globalAddingEntityDialog.useEntitiesMaps.transfer.name'),
			newName: t('globalAddingEntityDialog.useEntitiesMaps.transfer.newName'),
			selected: selectedOwnerAcceptanceId,
			onEntityChange: setSelectedOwnerAcceptanceId,
			entities: latestOwnerAcceptances,
			localEntities: []
		};
	}

	const {data: latestWarrantyClaimId} = useExtractLatestWarrantyClaimId(objectId, links?.spaceId);
	const {data: latestWarrantyClaims} = useExtractLatestWarrantyClaimsAsArray(
		objectId,
		links?.spaceId
	);

	useEffect(() => {
		if (latestWarrantyClaimId) {
			setSelectedWarrantyClaimId(latestWarrantyClaimId);
		}
	}, [latestWarrantyClaimId]);

	if (permissions.claims) {
		contentMap[ObjectStageIds.WARRANTY] = {} as Record<ProcessIds, ReactNode>;
		contentMap[ObjectStageIds.WARRANTY][ProcessIds.WARRANTY_SERVICE] = (
			<AddingWarrantyClaimGlobal
				objectId={objectId}
				spaceId={spaceId}
				onClose={onClose}
				links={links}
				ref={addingEntityRef}
				selectedWarrantyClaimId={selectedWarrantyClaimId}
				defaultProblemData={defaultProblemData}
				view={warrantyClaimView}
				setView={setWarrantyClaimView}
				saving={saving}
				setSaving={setSaving}
			/>
		);

		entitiesSelectMapProps[ObjectStageIds.WARRANTY] = {} as Record<
			ProcessIds,
			IEntitySelectProps
		>;
		entitiesSelectMapProps[ObjectStageIds.WARRANTY][ProcessIds.WARRANTY_SERVICE] = {
			name: t('globalAddingEntityDialog.useEntitiesMaps.warranty.name'),
			newName: t('globalAddingEntityDialog.useEntitiesMaps.warranty.newName'),
			selected: selectedWarrantyClaimId,
			onEntityChange: setSelectedWarrantyClaimId,
			entities: latestWarrantyClaims,
			localEntities: []
		};
	}

	return [contentMap, entitiesSelectMapProps];
};

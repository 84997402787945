import {ITask} from '@tehzor/tools/interfaces/tasks/ITask';
import {EntityGridItem, LinkButton} from '@tehzor/ui-components';
import {memo} from 'react';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useTranslation} from 'react-i18next';

interface ISpaceTasksDesktopProps {
	tasks: ITask[];
}

const linkIcon = <i className="tz-link-20" />;
const externalLinkIcon = <i className="tz-external-link-16" />;

export const SpaceTasksLinksMobile = memo((props: ISpaceTasksDesktopProps) => {
	const {tasks} = props;
	const {pushPath} = useChangePath();
	const {t} = useTranslation();
	return (
		<EntityGridItem
			label={t('spacePage.tasksLinks.title')}
			icon={linkIcon}
			fullRow
		>
			<div className="space-page__tasks">
				{tasks.map(task => (
					<LinkButton
						key={task.id}
						type="filled"
						className="space-page__tasks-link"
						leftIcon={externalLinkIcon}
						onClick={() => pushPath(`/tasks/${task.id}`)}
						label={task.name}
					/>
				))}
			</div>
		</EntityGridItem>
	);
});

import {Column} from 'react-table';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import ObjectStatsCell from '@src/components/tableCells/ObjectStatsCell';
import ObjectNameDesktopCell from '@src/components/tableCells/ObjectNameDesktopCell';
import HeaderCellWithRowSelectWrap from '@src/components/tableCells/HeaderCellWithRowSelectWrap';
import CompanyNameCell from '@src/components/tableCells/CompanyNameCell';
import MenuCell from '../components/table/MenuCell';
import {ObjectStagesCell} from '../components/table/ObjectStagesCell';
import {useTranslation} from 'react-i18next';
import {useIsDesktop} from "@tehzor/ui-components/src/utils/mediaQueries";
import {ObjectMobileCell} from '@src/components/tableCells/ObjectMobileCell';
import {useMemo} from 'react';

export const useTableColumns = (): Array<Column<IObject>> => {
	const {t} = useTranslation();
	const isDesktop = useIsDesktop();

	return useMemo(() => {
		if (!isDesktop) {
			return [
				{
					id: 'name',
					accessor: 'name',
					Cell: ObjectMobileCell,
					className: 'objects-page__mobile-cell'
				}
			];
		}

		return [
			{
				id: 'name',
				accessor: 'name',
				Header: HeaderCellWithRowSelectWrap(t('objectsPage.table.columns.name')),
				Cell: ObjectNameDesktopCell,
				width: 210,
				minWidth: 80,
				disableSortBy: true
			},
			{
				id: 'company',
				accessor: 'companyId',
				Header: t('objectsPage.table.columns.company'),
				Cell: CompanyNameCell,
				width: 110,
				minWidth: 80,
				disableSortBy: true
			},
			{
				id: 'city',
				accessor: 'city',
				Header: t('objectsPage.table.columns.city'),
				width: 80,
				minWidth: 80,
				disableSortBy: true
			},
			{
				id: 'address',
				accessor: 'address',
				Header: t('objectsPage.table.columns.address'),
				width: 100,
				minWidth: 100,
				disableSortBy: true
			},
			{
				id: 'stage',
				accessor: 'stage',
				Header: t('objectsPage.table.columns.stages'),
				Cell: ObjectStagesCell,
				width: 110,
				minWidth: 80,
				disableSortBy: true
			},
			{
				id: 'problems',
				Header: t('objectsPage.table.columns.problems'),
				Cell: ObjectStatsCell,
				width: 130,
				minWidth: 80,
				disableSortBy: true
			},
			{
				id: 'menu',
				Header: '',
				Cell: MenuCell,
				width: 54,
				className: 'objects-page__table-menu',
				isNonClickable: true
			}
		];
	}, [isDesktop, t]);
};

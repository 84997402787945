import {memo, useCallback, useMemo, useRef} from 'react';
import config from '@src/core/config';
import IReason from '@tehzor/tools/interfaces/IReason';
import {ReasonSelect, useLocalSearchProvider, useSearchSelectPopup} from '@tehzor/ui-components';
import {IEnhancedRegStandard} from '@tehzor/ui-components/src/components/ReasonSelect/ReasonSelect';
import {useRegulatoryStandardsAsArray} from '@src/core/hooks/queries/regulatoryStandards/hooks';
import {useTranslation} from 'react-i18next';
import {IReasonProps, Reason} from './Reason';
import {useController, useFormContext} from 'react-hook-form';
import {SetValueOptions} from './ProblemForm';
import {reasonRenderItem} from '../utils/reasonRenderItem';

export interface IReasonEnhancedRegStandard extends IEnhancedRegStandard {
	externalLink?: string;
}

const externalLink = config.regStandardExternalLink;

type ISearchableProblemReasonProps = Omit<IReasonProps, 'onSelect' | 'addon'> & {
	name: string;
	onSetValue?: (name: string, value: unknown, options?: SetValueOptions) => void;
};

export const SearchableProblemReason = memo(
	({
		required,
		disabled,
		name,
		errorMessage,
		onSetValue,
		...props
	}: ISearchableProblemReasonProps) => {
		const {t} = useTranslation();
		const {control} = useFormContext();

		const {
			field: {value},
			fieldState: {invalid}
		} = useController({
			name,
			control,
			rules: {required}
		});

		const inputRef = useRef<HTMLTextAreaElement>(null);
		const {data: regStandards} = useRegulatoryStandardsAsArray();

		const filteredData = useMemo<IReasonEnhancedRegStandard[]>(
			() =>
				regStandards
					? regStandards.map(item => ({
							...item,
							fullName: `«${item.name}»`,
							externalLink
					  }))
					: [],
			[regStandards]
		);
		const provider = useLocalSearchProvider<IReasonEnhancedRegStandard>({
			initialList: filteredData,
			keys: ['fullName']
		});

		const onSelect = useCallback(
			(selected: IReasonEnhancedRegStandard) => {
				if (selected.fullName && onSetValue) {
					onSetValue(name, {regulatoryStandard: selected.id, value: selected.fullName});
				}
			},
			[onSetValue, name]
		);

		const [popup, handleSearchChange] = useSearchSelectPopup<IReasonEnhancedRegStandard>({
			inputRef,
			onSelect,
			provider,
			renderItem: reasonRenderItem,
			noCheckbox: true
		});

		const handleSelectChange = useCallback(
			(v: IReason | null) => {
				if (onSetValue) {
					onSetValue(name, v);
				}
			},
			[onSetValue, name]
		);

		const handleChange = useCallback(
			(reason: string) => {
				if (onSetValue) {
					if (reason === '') {
						onSetValue(name, '');
					} else {
						onSetValue(name, {value: reason});
					}
				}
				handleSearchChange(reason);
			},
			[onSetValue, handleSearchChange, name]
		);
		return (
			<Reason
				ref={inputRef}
				value={value}
				disabled={disabled}
				errorMessage={invalid ? errorMessage : undefined}
				onChange={handleChange}
				select={
					<ReasonSelect
						data={regStandards}
						externalLink={externalLink}
						value={value}
						dialogTitle={t(
							'components.editableFields.SearchableProblemReason.dialogTitle'
						)}
						searchPlaceholder={t(
							'components.editableFields.SearchableProblemReason.searchPlaceholder'
						)}
						dialogAcceptBtnLabel={t(
							'components.editableFields.SearchableProblemReason.dialog.accept.label'
						)}
						dialogRejectBtnLabel={t(
							'components.editableFields.SearchableProblemReason.dialog.reject.label'
						)}
						btnLabel={t(
							'components.editableFields.SearchableProblemReason.button.label'
						)}
						noRowsMessage={t(
							'components.editableFields.SearchableProblemReason.noRowsMessage'
						)}
						error={errorMessage || undefined}
						disabled={disabled}
						onChange={handleSelectChange}
					/>
				}
				search={popup}
				{...props}
			/>
		);
	}
);

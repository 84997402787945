import {queryClient} from '@src/api/QueryClient';
import {IOfflineJournalEntity} from '../interfaces/IOfflineJournalEntity';
import {ISavingInspectionQuery} from '@src/core/hooks/mutations/inspection/useAddInspection';
import {needResetLinks} from './needResetlinks';
import {inspectionsQueryKeys} from '@src/api/cache/inspections/keys';
import {IAddInspectionParams} from '@src/api/cache/inspections/defaults/useInspectionsMutationDefaults';
import IError from '@tehzor/tools/interfaces/IError';

export const transferInspection = (entity: IOfflineJournalEntity['entity']) => {
	const {entityId} = entity;
	const mutationCache = queryClient.getMutationCache();
	let cachedData = queryClient.getQueryData<ISavingInspectionQuery>(
		inspectionsQueryKeys.savingData(entity.entityId)
	);
	if (cachedData) {
		const needLinksReset = needResetLinks(cachedData.links);
		if (needLinksReset) {
			cachedData = queryClient.setQueryData<ISavingInspectionQuery>(
				inspectionsQueryKeys.savingData(entityId),
				data => {
					if (data) {
						return {
							...data,
							links: {
								...data.links,
								checkId: undefined,
								internalAcceptanceId: undefined
							}
						};
					}
					return data;
				}
			);
		}
		if (cachedData) {
			const {object, fields, stage, processId, links, key} = cachedData;
			const mutation = mutationCache.build<unknown, IError, IAddInspectionParams, unknown>(
				queryClient,
				{
					mutationKey: inspectionsQueryKeys.add()
				}
			);
			void mutation.execute({objectId: object.id, stage, processId, links, fields, key});
		}
	}
};

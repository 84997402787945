import * as React from 'react';
import './FixDateDisplayValue.less';
import {format} from 'date-fns';
import {ProblemStatusId} from '@tehzor/tools/interfaces/problems/IProblemStatus';
import {WorkAcceptanceStatusIds} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceStatus';
import FixDateIcon from './components/FixDateIcon';
import classNames from 'classnames';
import {CriticalMarker} from '@src/components/CriticalMarker';
import {longDateTimeFormat} from '@tehzor/tools/utils/dateFormats';
import {useDateFnsLocale} from '@src/core/hooks/translations/useDateFnsLocales';

interface IFixDateDisplayValueProps {
	className?: string;
	style?: React.CSSProperties;
	plannedFixDate: number;
	actualFixDate?: number;
	status: ProblemStatusId | WorkAcceptanceStatusIds;
	critical?: boolean;
	dateFormat?: string;
	prefix?: string;
}

export const FixDateDisplayValue = ({
	className,
	style,
	plannedFixDate,
	status,
	critical,
	actualFixDate,
	dateFormat,
	prefix
}: IFixDateDisplayValueProps) => {
	const dateFnsLocale = useDateFnsLocale();
	const formatted = format(plannedFixDate, dateFormat || longDateTimeFormat, {
		locale: dateFnsLocale
	});

	return (
		<span
			className={classNames('fix-date-display-value', className)}
			style={style}
		>
			<span className="fix-date-display-value__text">
				{prefix ? `${prefix} ` : ''}
				{formatted}
			</span>

			{critical && <CriticalMarker />}

			{(status !== ProblemStatusId.FIXED ||
				(actualFixDate && actualFixDate > plannedFixDate)) && (
				<>
					{'  '}
					<FixDateIcon
						plannedFixDate={plannedFixDate}
						actualFixDate={actualFixDate}
					/>
				</>
			)}
		</span>
	);
};

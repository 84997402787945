import './DelegationHistoryDialog.less';
import {DelegationHistoryList, ModalDialog} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';
import {useDateFnsLocale} from '@src/core/hooks/translations/useDateFnsLocales';
import {IEnrichedDelegationHistory} from '@tehzor/tools/interfaces/delegationHistories/IEnrichedDelegationHistory';
import {memo} from 'react';

interface IDelegationHistoryDialogProps {
	histories: IEnrichedDelegationHistory[];
	isOpen: boolean;

	automaticActionLabelIn?: string;
	automaticActionLabelTitle?: string;
	userActionLabelIn?: string;
	userActionLabelTitle?: string;

	onClose: () => void;
}

export const DelegationHistoryDialog = memo(
	({
		histories,
		isOpen,
		automaticActionLabelIn,
		automaticActionLabelTitle,
		userActionLabelIn,
		userActionLabelTitle,
		onClose
	}: IDelegationHistoryDialogProps) => {
		const {t} = useTranslation();
		const locale = useDateFnsLocale();

		return (
			<ModalDialog
				className={{
					root: 'delegation-histories-dialog',
					content: 'delegation-histories-dialog__content'
				}}
				open={isOpen}
				title={t('components.delegationHistoryDialog.title')}
				fullScreenOnMobile
				onRequestClose={onClose}
			>
				<DelegationHistoryList
					data={histories}
					dateHoursFormat={t('components.delegationHistoryDialog.dateHoursFormat')}
					dateOptions={{locale}}
					automaticActionLabelIn={
						automaticActionLabelIn ??
						t('components.delegationHistoryDialog.automaticActionLabelIn')
					}
					automaticActionLabelTitle={
						automaticActionLabelTitle ??
						t('components.delegationHistoryDialog.automaticActionLabelTitle')
					}
					userActionLabelIn={
						userActionLabelIn ??
						t('components.delegationHistoryDialog.userActionLabelIn')
					}
					userActionLabelTitle={
						userActionLabelTitle ??
						t('components.delegationHistoryDialog.userActionLabelTitle')
					}
				/>
			</ModalDialog>
		);
	}
);

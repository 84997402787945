const domain = 'work-acceptance';

export const GET_REQUEST = `${domain}/get/request`;
export const GET_SUCCESS = `${domain}/get/success`;
export const GET_FAILURE = `${domain}/get/failure`;

export const ADD_REQUEST = `${domain}/add/request`;
export const ADD_SUCCESS = `${domain}/add/success`;
export const ADD_FAILURE = `${domain}/add/failure`;

export const EDIT_REQUEST = `${domain}/edit/request`;
export const EDIT_SUCCESS = `${domain}/edit/success`;
export const EDIT_FAILURE = `${domain}/edit/failure`;

export const EDIT_STATUS_REQUEST = `${domain}/status/edit/request`;
export const EDIT_STATUS_SUCCESS = `${domain}/status/edit/success`;
export const EDIT_STATUS_FAILURE = `${domain}/status/edit/failure`;

export const DELETE_REQUEST = `${domain}/delete/request`;
export const DELETE_SUCCESS = `${domain}/delete/success`;
export const DELETE_FAILURE = `${domain}/delete/failure`;

export const GET_PROBLEMS_REQUEST = `${domain}/problems/get/request`;
export const GET_PROBLEMS_SUCCESS = `${domain}/problems/get/success`;
export const GET_PROBLEMS_FAILURE = `${domain}/problems/get/failure`;

export const GET_INSPECTIONS_REQUEST = `${domain}/inspections/get/request`;
export const GET_INSPECTIONS_SUCCESS = `${domain}/inspections/get/success`;
export const GET_INSPECTIONS_FAILURE = `${domain}/inspections/get/failure`;

export const GET_PROBLEM_REPLIES_REQUEST = `${domain}/problem-replies/get/request`;
export const GET_PROBLEM_REPLIES_SUCCESS = `${domain}/problem-replies/get/success`;
export const GET_PROBLEM_REPLIES_FAILURE = `${domain}/problem-replies/get/failure`;

export const EDIT_ACCEPTORS_REQUEST = `${domain}/responsible/edit/request`;
export const EDIT_ACCEPTORS_SUCCESS = `${domain}/responsible/edit/success`;
export const EDIT_ACCEPTORS_FAILURE = `${domain}/responsible/edit/failure`;

export const EXPORT_REQUEST = `${domain}/export/request`;
export const EXPORT_SUCCESS = `${domain}/export/success`;
export const EXPORT_FAILURE = `${domain}/export/failure`;

export const SEND_REQUEST = `${domain}/send/request`;
export const SEND_SUCCESS = `${domain}/send/success`;
export const SEND_FAILURE = `${domain}/send/failure`;

export const GET_COMMENTS_REQUEST = `${domain}/comments/get/request`;
export const GET_COMMENTS_SUCCESS = `${domain}/comments/get/success`;
export const GET_COMMENTS_FAILURE = `${domain}/comments/get/failure`;

export const RESET_COMMENTS = `${domain}/comments/reset`;
import {queryClient} from '@src/api/QueryClient';
import {IOfflineJournalEntity} from '../interfaces/IOfflineJournalEntity';
import {ISavingProblemQuery} from '@src/core/hooks/mutations/problem/useAddProblem';
import {needResetLinks} from './needResetlinks';
import {problemsQueryKeys} from '@src/api/cache/problems/keys';
import {findMutation} from './findMutation';
import IError from '@tehzor/tools/interfaces/IError';
import {IAddProblemParams} from '@src/api/cache/problems/defaults/useProblemsMutationDefaults';

export const transferProblem = (entity: IOfflineJournalEntity['entity']) => {
	const {entityId} = entity;
	const mutationCache = queryClient.getMutationCache();
	let cachedData = queryClient.getQueryData<ISavingProblemQuery>(
		problemsQueryKeys.savingData(entityId)
	);
	if (cachedData) {
		const needLinksReset = needResetLinks(cachedData.links);
		if (needLinksReset) {
			cachedData = queryClient.setQueryData<ISavingProblemQuery>(
				problemsQueryKeys.savingData(entityId),
				data => {
					if (data) {
						return {
							...data,
							links: {
								...data.links,
								checkId: undefined,
								internalAcceptanceId: undefined,
								warrantyClaimId: undefined,
								ownerAcceptanceId: undefined
							}
						};
					}
					return data;
				}
			);
		}
		if (cachedData) {
			const {object, fields, stage, processId, links, key} = cachedData;
			const currentMutation = findMutation(key);

			if (currentMutation) {
				void currentMutation.execute({
					objectId: object.id,
					stage,
					processId,
					links,
					fields,
					key
				});
				return;
			}
			const mutation = mutationCache.build<unknown, IError, IAddProblemParams, unknown>(
				queryClient,
				{
					mutationKey: problemsQueryKeys.add()
				}
			);
			void mutation.execute({objectId: object.id, stage, processId, links, fields, key});
		}
	}
};

import {useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractCheckRecordByListIds} from '@src/store/modules/pages/checkLists/selectors/records';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import {CheckRecordStatusId} from '@tehzor/tools/interfaces/checkRecords/ICheckRecordStatus';

interface CheckListStatusesAsMap {
	[key: string]: {
		id: CheckRecordStatusId;
		count: number;
	};
}

export const useCheckListStatuses = (checkLists: ICheckList[], workAcceptanceId: string) => {
	const checkListsStatuses = useAppSelector(s =>
		extractCheckRecordByListIds(
			s,
			checkLists.map(c => c.id),
			workAcceptanceId
		)
	);

	return useMemo(
		() =>
			checkListsStatuses.reduce<CheckListStatusesAsMap>((acc, next) => {
				const currentCount = acc[next.status]?.count || 0;
				acc[next.status] = {
					id: next.status,
					count: currentCount + 1
				};
				return acc;
			}, {}),
		[checkListsStatuses]
	);
};

import {memo, ReactElement, useCallback, useMemo} from 'react';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {MenuItem} from '@tehzor/ui-components';
import {IMenuItemProps} from '@tehzor/ui-components/src/components/menu/MenuItem';
import classNames from 'classnames';
import MenuLayer from '@tehzor/ui-components/src/components/menu/MenuLayer';
import {useTranslation} from 'react-i18next';
import {IDialogMenuTriggerFnProps} from '@tehzor/ui-components/src/components/menu/DialogMenu';
import {IPopupMenuTriggerFnProps} from '@tehzor/ui-components/src/components/menu/PopupMenu';
import {IWorkAcceptanceAddingEntityType} from '@src/store/modules/settings/workAcceptanceAdding/interfaces';
import {workAcceptanceAddingActions} from '@src/store/modules/settings/workAcceptanceAdding/slice';
import {modalDialogActions} from '@src/store/modules/settings/modalDialog/slice';

interface IEntityTypeSelectProps {
	type: IWorkAcceptanceAddingEntityType;
	types: IWorkAcceptanceAddingEntityType[];
	disabled: boolean;
}

export const EntityTypeSelect = memo(({type, types, disabled}: IEntityTypeSelectProps) => {
	const {t} = useTranslation();
	const dispatch = useAppDispatch();
	const {resetFastAddingMode} = modalDialogActions;
	const {changeWorkAcceptanceAddingEntityType} = workAcceptanceAddingActions;
	const changeType = (value: IWorkAcceptanceAddingEntityType) => {
		dispatch(resetFastAddingMode());
		if (!disabled) {
			dispatch(changeWorkAcceptanceAddingEntityType(value));
		}
	};

	const entityTypeMap = useMemo<Record<IWorkAcceptanceAddingEntityType, string>>(
		() => ({
			problem: t('addingWorkAcceptanceDialog.dialog.entityTypeSelect.entityTypeMap.problem'),
			inspection: t(
				'addingWorkAcceptanceDialog.dialog.entityTypeSelect.entityTypeMap.inspection'
			)
		}),
		[t]
	);

	const handleTrigger = useCallback(
		({isOpen, toggle, ref}: IDialogMenuTriggerFnProps | IPopupMenuTriggerFnProps) => (
			<div
				data-testid="AddingWorkAcceptanceDialogEntityTypeSelect"
				className={classNames('adding-work-acceptance-dialog__entity-type-select', {
					disabled
				})}
				onClick={!disabled ? toggle : undefined}
				ref={ref}
			>
				{entityTypeMap[type]}{' '}
				{!disabled && <i className={classNames('tz-simple-arrow-20', {isOpen})} />}
			</div>
		),
		[disabled, entityTypeMap, type]
	);

	const menuContent: Array<ReactElement<IMenuItemProps>> = [];
	for (const item of types) {
		menuContent.push(
			<MenuItem
				key={item}
				onClick={() => changeType(item)}
				selected={type === item}
			>
				{entityTypeMap[item]}
			</MenuItem>
		);
	}

	return menuContent.length > 1 ? (
		<MenuLayer trigger={handleTrigger}>{menuContent}</MenuLayer>
	) : (
		<div
			data-testid="AddingWorkAcceptanceDialogEntityTypeSelect"
			className={classNames('adding-work-acceptance-dialog__entity-type-select', 'disabled')}
		>
			{entityTypeMap[type]}
		</div>
	);
});

import {memo} from 'react';
import {Button, EntityGrid, ExpandablePlate} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {hasPermission} from '@tehzor/tools/utils/findPermission';
import {extractUserRoles} from '@src/store/modules/auth/profile/selectors';
import {useObject} from '@src/core/hooks/queries/objects/hooks';
import {useMetersAsArrayForSpace} from '@src/core/hooks/queries/meters/hooks';
import {useAddingMeterDialog} from '@src/components/AddingMeterDialog/hooks/useAddingMeterDialog';
import {
	useExtractMeterTypesAllIds,
	useExtractMeterTypesById
} from '@src/core/hooks/queries/meterTypes/hooks';
import {MeterTypeId} from '@tehzor/tools/interfaces/meters/IMeterType';
import {Meters} from './Meters';
import {useTranslation} from 'react-i18next';

interface MetersBlockProps {
	objectId: string;
	spaceId: string;
}

const plusIcon = <i className="tz-plus-20" />;

export const MetersBlock = memo(({objectId, spaceId}: MetersBlockProps) => {
	const {t} = useTranslation();
	const {data: meters} = useMetersAsArrayForSpace(spaceId);
	const {data: object} = useObject(objectId);
	const {data: meterTypes} = useExtractMeterTypesById();
	const {data: meterTypeIds} = useExtractMeterTypesAllIds();

	const roles = useAppSelector(extractUserRoles);

	// Диалог добавления прибора учёта
	const [addingDialog, openAddingDialog] = useAddingMeterDialog(
		object?.companyId,
		objectId,
		spaceId
	);

	const canAdd = hasPermission(roles, 'meters-add');

	return (
		<ExpandablePlate
			title={t('spacePage.info.metersBlock.title')}
			className={{
				root: 'space-page__meter-block',
				expandButton: 'space-page__meter-block-btn',
				content: 'space-page__meter-block-content'
			}}
			initiallyClosed
		>
			<EntityGrid>
				{meters &&
					meterTypes &&
					meterTypeIds &&
					meterTypeIds.map((typeId: MeterTypeId) => (
						<Meters
							key={typeId}
							spaceId={spaceId}
							typeId={typeId}
						/>
					))}
				{canAdd && addingDialog}
			</EntityGrid>
			<Button
				label={t('spacePage.info.metersBlock.button.add.label')}
				onClick={openAddingDialog}
				leftIcon={plusIcon}
				className="space-page__meter-adding-btn"
			/>
		</ExpandablePlate>
	);
});

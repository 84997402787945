import {
	IObjectStageWithProcessesWithIcon,
	ObjectStageIds
} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {ActionButtons, Button} from '../../../../buttons';
import {ModalDialog} from '../../../../dialogs';
import {EntitySelect, IEntitySelectProps} from '../EntitySelect/EntitySelect';
import './StagesSelectDialog.less';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';
import classNames from 'classnames';

interface IStagesSelectProps {
	stages: IObjectStageWithProcessesWithIcon[];
	defaultStage?: ObjectStageIds;
	defaultProcessId?: ProcessIds;
	isOpen: boolean;
	entitiesSelectMapProps: Record<ObjectStageIds, Record<ProcessIds, IEntitySelectProps>>;
	title?: string;
	stageTitle?: string;
	saveLabel?: string;
	cancelLabel?: string;

	onConfirm?: (stageId: string, processId: string) => void;
	onClose: () => void;
	onCancel?: () => void;
}

const checkedIcon = <i className="tz-mark-24 stages-select-dialog__items-selected-icon"/>;

export const StagesSelectDialog = ({
	stages,
	defaultStage,
	defaultProcessId,
	isOpen,
	title,
	stageTitle,
	saveLabel,
	cancelLabel,
	onConfirm,
	onClose,
	onCancel,
	entitiesSelectMapProps
}: IStagesSelectProps) => {
	const [checked, setChecked] = useState<ObjectStageIds>(() => defaultStage || stages[0].id);
	const [checkedProcess, setCheckedProcess] = useState<ProcessIds>(
		() => defaultProcessId || stages[0].processes[0].id
	);

	const isProcessSelected = (stageId: ObjectStageIds, processId: ProcessIds) =>
		checked === stageId && checkedProcess === processId;

	const handleChange = useCallback((stageId: ObjectStageIds, processId: ProcessIds) => {
		setChecked(stageId);
		setCheckedProcess(processId);
	}, []);

	const checkedEntitiesSelectProps = useMemo(
		() => entitiesSelectMapProps[checked][checkedProcess],
		[checked, checkedProcess, entitiesSelectMapProps]
	);

	const [selectedEntity, setSelectedEntity] = useState<string | undefined>(undefined);

	useEffect(() => {
		const defaultSelectedEntity = checkedEntitiesSelectProps.selected;
		setSelectedEntity(defaultSelectedEntity);
	}, [checkedEntitiesSelectProps]);

	const handleConfirm = useCallback(() => {
		if (onConfirm && checked && checkedProcess) {
			onConfirm(checked, checkedProcess);
		}
		if (checkedEntitiesSelectProps) {
			checkedEntitiesSelectProps.onEntityChange(selectedEntity);
		}
		onClose();
	}, [onConfirm, checked, checkedProcess, checkedEntitiesSelectProps, onClose, selectedEntity]);

	const handleCancel = useCallback(() => {
		if (onCancel) {
			onCancel();
		}
		onClose();
	}, [onCancel, onClose]);

	return (
		<ModalDialog
			className="stages-select-dialog"
			open={isOpen}
			onRequestClose={onClose}
			title={title ?? 'Стадия'}
			footer={(
				<ActionButtons>
					<Button
						type="cancel"
						label={cancelLabel ?? 'Отменить'}
						onClick={handleCancel}
					/>
					<Button
						type="accent-blue"
						label={saveLabel ?? 'Сохранить'}
						onClick={handleConfirm}
					/>
				</ActionButtons>
			)}
		>
			<div className="stages-select-dialog__items">
				{stages.map(({ id, name, processes }) => (
					entitiesSelectMapProps[id] && (
						<div
							key={id}
							className="stages-select-dialog__items-stage-container"
						>
							<div className="stages-select-dialog__items-stage-title">
								{stageTitle ?? 'Стадия'}: {name}
							</div>
							{processes
								.filter(process => process.id !== ProcessIds.ACCEPTANCE_CONTROL)
								.map(process => (
									<div
										data-testid="MenuItem"
										className={classNames(
											'stages-select-dialog__items-item',
											{
												'stages-select-dialog__selected-process':
													isProcessSelected(id, process.id),
											}
										)}
										key={id.concat(process.id)}
										onClick={() => handleChange(id, process.id)}
									>
										<div className="stages-select-dialog__items-icon-container">
											<div
												className="stages-select-dialog__items-icon-background"
												style={{ backgroundColor: process.backgroundColor }}
											>
												{process.icon}
											</div>
										</div>
										<div className="stages-select-dialog__items-item-name">
											{process.name}
										</div>
										{isProcessSelected(id, process.id) && checkedIcon}
									</div>
								))
							}
						</div>
					)
				))}
			</div>
			{checked && checkedEntitiesSelectProps && (
				<EntitySelect
					{...checkedEntitiesSelectProps}
					onEntityChange={setSelectedEntity}
					selected={selectedEntity}
				/>
			)}
		</ModalDialog>
	);
};

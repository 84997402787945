import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {useAcceptorsGroupsAsArray} from '@src/core/hooks/queries/workingGroups/hooks';
import {useUsersAsMap} from '@src/core/hooks/queries/users/hooks';

export const useWorkAcceptanceAcceptors = (
	objectId: string,
	stage: ObjectStageIds,
	acceptorIds: string[] | undefined,
	acceptorsActiveGroup: string | undefined
) => {
	const {data: usersMap} = useUsersAsMap();
	const {data: acceptorsGroupAsArray} = useAcceptorsGroupsAsArray(objectId, stage);

	const acceptors =
		usersMap && acceptorIds
			? acceptorIds.map(acceptorId => usersMap[acceptorId]?.displayName)
			: undefined;
	const acceptorsGroup = acceptorsGroupAsArray?.find(
		acceptorGroup => acceptorGroup.id === acceptorsActiveGroup
	);

	return {acceptors, acceptorsGroup};
};

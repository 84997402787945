import {useMemo} from 'react';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';

/**
 * Хук для мапы соответствия стадий и процесов
 *
 **/
export const useStagesAndProcessesMap = () =>
	useMemo(
		() => ({
			[ObjectStageIds.BUILDING]: [
				ProcessIds.OPERATIONAL_CONTROL,
				ProcessIds.ACCEPTANCE_CONTROL
			],
			[ObjectStageIds.ACCEPTANCE]: [ProcessIds.INTERNAL_ACCEPTANCE],
			[ObjectStageIds.TRANSFER]: [ProcessIds.UNITS_HANDOVER],
			[ObjectStageIds.WARRANTY]: [ProcessIds.WARRANTY_SERVICE]
		}),
		[]
	);
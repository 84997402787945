import {CellProps} from 'react-table';
import {useTranslation} from 'react-i18next';
import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {dateTimeCommaSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import {FixDateDisplayValue} from '@src/components/FixDateDisplayValue';
import {FixDateTag} from '@src/components/FixDateTag';
import './WorkAcceptanceDeadlineCell.less';

interface IWorkAcceptanceDeadlineProps {
	workAcceptance: IWorkAcceptance;
}

export const WorkAcceptanceDeadline = ({workAcceptance}: IWorkAcceptanceDeadlineProps) => {
	const {t} = useTranslation();
	const {status, acceptanceDate} = workAcceptance;
	const isDesktop = useIsDesktop();

	return acceptanceDate ? (
		<>
			{isDesktop ? (
				<FixDateDisplayValue
					className="work-acceptance-deadline-cell-date"
					plannedFixDate={acceptanceDate}
					status={status}
					dateFormat={dateTimeCommaSeparatedFormat}
				/>
			) : (
				<FixDateDisplayValue
					className="work-acceptance-deadline-cell-date"
					plannedFixDate={acceptanceDate}
					status={status}
					dateFormat={dateTimeCommaSeparatedFormat}
					prefix={t('problemsPage.mobileProblemCell.plannedFixDateShort')}
				/>
			)}
			<FixDateTag
				className="fix-date-view-field__tag"
				plannedFixDate={acceptanceDate}
			/>
		</>
	) : null;
};

export const WorkAcceptanceDeadlineCell = ({row}: CellProps<{data: IWorkAcceptance}>) => (
	<WorkAcceptanceDeadline workAcceptance={row.original.data} />
);

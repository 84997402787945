import {QueryFunctionContext, useQueryClient} from '@tanstack/react-query';
import {
	delegationHistoryQueryKeys,
	IGetDelegationHistoryQueryKey
} from '@src/api/cache/delegationHistory/keys';
import {requestDelegationHistories} from '@src/api/backend/delegationHistories';

export const useDelegationHistoryQueryDefaults = () => {
	const queryClient = useQueryClient();
	queryClient.setQueryDefaults(delegationHistoryQueryKeys.all(), {
		queryFn: async ({queryKey}: QueryFunctionContext<IGetDelegationHistoryQueryKey>) => {
			const [, delegationHistoriesParams] = queryKey;
			return requestDelegationHistories(delegationHistoriesParams);
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: 1000 * 60 * 60 * 24 // 1 День
	});
};

import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IWorkAcceptanceAddingEntityType, IWorkAcceptanceAddingSettingsState} from './interfaces';

const initialState: IWorkAcceptanceAddingSettingsState = {entityType: 'problem'};

export const workAcceptanceAddingSlice = createSlice({
	name: 'workAcceptanceAdding',
	initialState,
	reducers: {
		changeWorkAcceptanceAddingEntityType(
			state,
			action: PayloadAction<IWorkAcceptanceAddingEntityType>
		) {
			state.entityType = action.payload;
		}
	}
});

export const {actions: workAcceptanceAddingActions, reducer: workAcceptanceAddingReducer} =
	workAcceptanceAddingSlice;

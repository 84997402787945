import {ICustomField, IFieldSetting} from '@tehzor/tools/interfaces/fields';
import {DatePicker, EditableFieldLabel, TextFieldWithForwardedRef} from '@tehzor/ui-components';
import {memo, useCallback} from 'react';
import {IDatePickerTriggerProps} from '@tehzor/ui-components/src/components/dates/DatePicker/DatePicker';
import {format} from 'date-fns';
import {dateFormat} from '@tehzor/tools/utils/dateFormats';
import {useDateFnsLocale} from '@src/core/hooks/translations/useDateFnsLocales';
import {useTranslation} from 'react-i18next';
import {useController, useFormContext} from 'react-hook-form';
import {SetValueOptions} from '../../ProblemForm';

interface ICustomDateFieldProps {
	field: ICustomField;
	setting: IFieldSetting;
	disabled?: boolean;
	onSetValue?: (name: string, value: unknown, options?: SetValueOptions) => void;
	name: string;
	errorMessage?: string;
	required?: boolean;
}

const getIcon = (icon?: string) =>
	icon ? <i className={icon} /> : <i className="tz-simple-arrow-20" />;

const CustomDateFieldComponent = ({
	field,
	setting,
	disabled,
	onSetValue,
	name,
	errorMessage,
	required
}: ICustomDateFieldProps) => {
	const {control} = useFormContext();
	const locale = useDateFnsLocale();
	const {t} = useTranslation();
	const {
		field: {value},
		fieldState: {invalid}
	} = useController({
		name,
		control,
		rules: {required}
	});

	const handleChange = useCallback(
		(val?: Date | null) => {
			if (onSetValue) {
				onSetValue(name, val ? val.getTime() : null);
			}
		},
		[name, onSetValue]
	);

	const handleClearButton = useCallback(() => {
		handleChange(null);
	}, [handleChange]);

	const trigger = useCallback(
		(props: IDatePickerTriggerProps) => (
			<TextFieldWithForwardedRef
				elementType="div"
				value={props?.value !== undefined ? format(props.value, dateFormat) : ''}
				icon={getIcon(field.icon)}
				onClearClick={handleClearButton}
				onClick={props?.toggle}
				disabled={disabled}
				ref={props?.ref}
				cleanable={!!props?.value}
				error={invalid ? errorMessage : undefined}
			/>
		),
		[invalid, field.icon, handleClearButton, disabled, errorMessage]
	);

	const date = value ? new Date(value) : undefined;
	const dialogProps = {title: t('tasksPage.dateTimeSelect.dialogProps')};

	return (
		<div>
			<EditableFieldLabel>
				{setting.required ? `${field.name} *` : field.name}
			</EditableFieldLabel>

			<DatePicker
				trigger={trigger}
				value={date}
				datesOptions={{locale}}
				dialogProps={dialogProps}
				selectCancelBtnLabel={t('cancelBtn.label')}
				selectApplyBtnLabel={t('applyBtn.label')}
				InlineDatePickerLabel={t('inlineDatePicker.label')}
				useApplyButton
				onChange={handleChange}
			/>
		</div>
	);
};

export const CustomDateField = memo(CustomDateFieldComponent) as typeof CustomDateFieldComponent;

import {memo} from 'react';
import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';
import {Common} from './Common';
import {useTranslation} from 'react-i18next';
import {Plate} from '@tehzor/ui-components';

interface IDesktopInfoProps {
	objectId: string;
	space: ISpace;
	hasUnfixedProblems: boolean;
	hasHandlingOrWaitingProblems: boolean;
	hasUnacceptedCheckItem: boolean;
}

export const DesktopInfo = memo(
	({
		objectId,
		space,
		hasUnfixedProblems,
		hasHandlingOrWaitingProblems,
		hasUnacceptedCheckItem
	}: IDesktopInfoProps) => {
		const {t} = useTranslation();
		return (
			<Plate className={{content: 'space-page__info-plate'}}>
				<div className="space-page__info-title">{t('spacePage.info.title')}</div>
				<Common
					objectId={objectId}
					space={space}
					hasUnfixedProblems={hasUnfixedProblems}
					hasHandlingOrWaitingProblems={hasHandlingOrWaitingProblems}
					hasUnacceptedCheckItem={hasUnacceptedCheckItem}
				/>
			</Plate>
		);
	}
);

import {CheckRecordStatusId} from '@tehzor/tools/interfaces/checkRecords/ICheckRecordStatus';
import {useCheckRecordsStatuses} from '@src/core/hooks/queries/checkRecordStatuses/hooks';
import {CheckListIndicator} from '@tehzor/ui-components/src/components/various/CheckListIndicator/CheckListIndicator';
import './WorkAcceptanceCheckListStatuses.less';

interface WorkAcceptanceCheckListStatusesProps {
	checkListStatuses: {[key: string]: {id: CheckRecordStatusId; count: number}};
}

export const WorkAcceptanceCheckListStatuses = ({
	checkListStatuses
}: WorkAcceptanceCheckListStatusesProps) => {
	const {data: statuses} = useCheckRecordsStatuses();

	if (!statuses) return null;

	return (
		<div className="work-acceptance-check-list-statuses">
			{Object.values(checkListStatuses).map(checkListStatus => (
				<CheckListIndicator
					key={checkListStatus.id}
					statuses={statuses}
					status={checkListStatus.id}
					count={checkListStatus.count}
				/>
			))}
		</div>
	);
};

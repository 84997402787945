import {ISavingProblem} from '@src/interfaces/saving/ISavingProblem';
import {OfflineDataTransferStatus} from '@tehzor/tools/contracts/dataTransferWebWorker/interfaces/IOfflineDataTransferStatuses';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IListProblem} from '@tehzor/tools/interfaces/problems/IListProblem';
import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';
import {ProblemStatusId} from '@tehzor/tools/interfaces/problems/IProblemStatus';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import IPlan from '@tehzor/tools/interfaces/plans/IPlan';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';

export const toProblem = (
	object: IObject,
	links: IProblem['links'] | undefined,
	stage: ObjectStageIds | undefined,
	processId: ProcessIds,
	fields: ISavingProblem,
	key: string,
	nextNumber?: number,
	user?: IBriefUser,
	plans?: INormalizedData<IPlan>
): IProblem => {
	const newProblem: IProblem = {
		...(fields as Omit<ISavingProblem, 'newAttachments'>),
		objectId: object.id,
		links,
		stage: stage || ObjectStageIds.BUILDING,
		processId,
		id: key,
		status: ProblemStatusId.WAITING,
		reopenedTimes: fields.reopenedTimes || 0,
		transferStatus: OfflineDataTransferStatus.SAVED_LOCAL,
		plannedFixDate: fields.plannedFixDate || undefined,
		floor: fields.floor || undefined,
		location: fields.location || undefined,
		reason: fields.reason || undefined,
		categoryId: fields.categoryId || undefined,
		contractId: fields.contractId || undefined,
		localCreatedAt: Number(key),
		createdBy: user?.id
	};
	if (nextNumber) {
		newProblem.localNumber = `АВТ-${nextNumber}`;
	}
	if (fields.planId && plans?.allIds.length) {
		const problemPlan = plans.byId[fields.planId];
		if (problemPlan) {
			newProblem.plan = {
				id: problemPlan.id,
				name: problemPlan.name
			};
		}
	}
	return newProblem;
};

export const toListProblem = (
	object: Pick<IObject, 'id' | 'name'>,
	links: IProblem['links'] | undefined,
	stage: ObjectStageIds | undefined,
	processId: ProcessIds,
	fields: ISavingProblem,
	key: string,
	nextNumber?: number,
	user?: IBriefUser,
	plans?: INormalizedData<IPlan>
): IProblem => {
	const newProblem: IListProblem = {
		...(fields as Omit<ISavingProblem, 'newAttachments'>),
		objectId: object.id,
		links,
		stage: stage || ObjectStageIds.BUILDING,
		processId,
		id: key,
		status: ProblemStatusId.WAITING,
		reopenedTimes: fields.reopenedTimes || 0,
		transferStatus: OfflineDataTransferStatus.SAVED_LOCAL,
		plannedFixDate: fields.plannedFixDate || undefined,
		floor: fields.floor || undefined,
		location: fields.location || undefined,
		reason: fields.reason || undefined,
		object: {id: object.id, name: object.name},
		categoryId: fields.categoryId || undefined,
		contractId: fields.contractId || undefined,
		localCreatedAt: Number(key),
		createdBy: user?.id
	};
	if (nextNumber) {
		newProblem.localNumber = `АВТ-${nextNumber}`;
	}
	if (fields.planId && plans?.allIds.length) {
		const problemPlan = plans.byId[fields.planId];
		if (problemPlan) {
			newProblem.plan = {
				id: problemPlan.id,
				name: problemPlan.name
			};
		}
	}
	return newProblem;
};

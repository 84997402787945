import {useUser} from '@src/core/hooks/queries/users/hooks';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractUserProfile} from '@src/store/modules/auth/profile/selectors';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {WorkingGroupTypeId} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroupType';
import {IRuleParams} from '@tehzor/tools/utils/responsibilityRules/IRuleParams';
import {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import isEqual from 'lodash/isEqual';
import {useController, useFormContext} from 'react-hook-form';
import {editableProblemFields} from '../useEditableProblemForm/editableProblemFields';
import {Responsibles} from './Responsibles';
import {SetValueOptions} from './ProblemForm';

interface IEditableProblemInspectorsProps {
	objectId: string;
	stage?: ObjectStageIds;
	scope?: string;
	createdBy?: string;
	name: string;
	onSetValue?: (name: string, value: unknown, options?: SetValueOptions) => void;
	disabled?: boolean;
	errorMessage?: string;
	required?: boolean;
}

const inspectorIcon = <i className="tz-inspector-20" />;

export const EditableProblemInspectors = ({
	objectId,
	stage,
	name,
	onSetValue,
	scope,
	createdBy,
	disabled,
	errorMessage,
	required
}: IEditableProblemInspectorsProps) => {
	const {t} = useTranslation();
	const {getValues, control} = useFormContext();

	const [categoryId, planId, inspectorsActiveGroup, initialRuleParams] = getValues([
		editableProblemFields.CATEGORY_ID,
		editableProblemFields.PLAN_ID,
		editableProblemFields.INSPECTORS_ACTIVE_GROUP,
		editableProblemFields.INITIAL_RULE_PARAMS
	]);

	// обязательное требование заполнения снимается если нет проверяющих на выбор или выбрана группа
	const [isException, setIsException] = useState<boolean | undefined>(undefined);
	const {
		field: {value: inspectors},
		fieldState: {invalid}
	} = useController({
		name,
		control,
		rules: {
			required: isException || inspectorsActiveGroup ? false : required
		}
	});

	const ruleParams: IRuleParams = useMemo(
		() => ({
			categoryId: categoryId || undefined,
			planId
		}),
		[categoryId, planId]
	);
	const user = useAppSelector(extractUserProfile);
	const {data: author} = useUser(createdBy);

	const handleChange = useCallback(
		(users: string[], group?: string) => {
			if (onSetValue) {
				onSetValue(name, users);
				onSetValue(editableProblemFields.INSPECTORS_ACTIVE_GROUP, group);
			}
		},
		[onSetValue, name]
	);

	const handleUpdateException = useCallback((value: boolean) => {
		setIsException(value);
	}, []);

	const checkDoSuggestions = useCallback(() => {
		if (isEqual(initialRuleParams, ruleParams)) {
			return false;
		}
		return true;
	}, [initialRuleParams, ruleParams]);

	return (
		<Responsibles
			objectId={objectId}
			stage={stage}
			scope={scope}
			workingGroupType={WorkingGroupTypeId.INSPECTORS}
			respUsers={inspectors}
			activeGroup={inspectorsActiveGroup}
			ruleParams={ruleParams}
			author={author ?? user}
			label={t('components.editableProblem.inspectors.label')}
			errorLabel={invalid && !inspectorsActiveGroup?.length ? errorMessage : undefined}
			dialogTitle={t('components.delegationDialog.inspectors.title')}
			icon={inspectorIcon}
			disabled={disabled}
			onResponsiblesChange={handleChange}
			onUpdateException={handleUpdateException}
			checkDoSuggestions={checkDoSuggestions}
			required={required}
		/>
	);
};

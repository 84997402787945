import {useCompanies} from '@src/core/hooks/queries/companies/hooks';
import {useWorkingGroupsAsMap} from '@src/core/hooks/queries/workingGroups/hooks';
import {IWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroup';

export const useWorkAcceptanceCompany = (
	acceptorsActiveGroup: string | undefined,
	acceptorsGroupAsObj: IWorkingGroup | undefined
) => {
	const {data: companies} = useCompanies();
	const {data: groupsMap} = useWorkingGroupsAsMap();
	const companiesMap = companies?.byId;

	const company =
		companiesMap &&
		acceptorsActiveGroup &&
		groupsMap &&
		acceptorsGroupAsObj &&
		groupsMap[acceptorsGroupAsObj?.id]
			? companiesMap[groupsMap[acceptorsActiveGroup].companyId].name
			: undefined;

	return company;
};

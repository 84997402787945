import {CSSProperties, memo, useCallback} from 'react';
import {EditableFieldLabel, TextField} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';
import {useController, useFormContext} from 'react-hook-form';
import {SetValueOptions} from './ProblemForm';

interface IFloorsProps {
	className?: string;
	style?: CSSProperties;
	name: string;
	onSetValue?: (name: string, value: unknown, options?: SetValueOptions) => void;
	label?: string;
	disabled?: boolean;
	errorMessage?: string;
	required?: boolean;
}

const Floors = ({
	className,
	style,
	name,
	onSetValue,
	label,
	disabled,
	errorMessage,
	required
}: IFloorsProps) => {
	const {t} = useTranslation();
	const {control} = useFormContext();
	const fieldLabel = label || t('components.editableFields.floors.label');

	const {
		field: {value},
		fieldState: {invalid}
	} = useController({
		name,
		control,
		rules: {required}
	});

	const handleChange = useCallback(
		(v: string) => {
			if (onSetValue) {
				onSetValue(name, v);
			}
		},
		[onSetValue, name]
	);

	return (
		<div
			className={className}
			style={style}
		>
			<EditableFieldLabel>{fieldLabel}</EditableFieldLabel>

			<TextField
				value={value}
				elementType="input"
				error={invalid ? errorMessage : undefined}
				disabled={disabled}
				onChange={handleChange}
			/>
		</div>
	);
};

export default memo(Floors) as typeof Floors;

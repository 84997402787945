import {createElement} from 'react';
import {
	IObjectStageWithProcesses,
	IObjectStageWithProcessesWithIcon
} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {determineColors} from "../../../various/EntityInfo/utils/determineColors";

export const convertStages = (
	stages: IObjectStageWithProcesses[]
): IObjectStageWithProcessesWithIcon[] =>
	stages.map(stage => ({
		id: stage.id,
		name: stage.name,
		color: stage.color,
		iconName: stage.iconName,
		processes: stage.processes.map(process => ({
			id: process.id,
			name: process.name,
			color: stage.color,
			backgroundColor: determineColors(stage.color)[1],
			icon: createElement('i', {
				className: `tz-${process.iconName}-16`,
				style: {color: stage.color}
			})
		})),
		order: stage.order
	}));

import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import {getInitialState} from '@src/store/modules/pages/checkLists/reducers/records';
import {ICheckRecord} from '@tehzor/tools/interfaces/checkRecords/ICheckRecord';
import {getLatestModified} from '@src/utils/selectorUtils';

const getData = (state: IState) => state.pages.checkLists.records;
const getId = (state: IState, id: string | undefined) => id;
const getIds = (state: IState, ids: string[]) => ids;
const getWorkAcceptanceId = (state: IState, ids: string[], workAcceptanceId: string) =>
	workAcceptanceId;
const getWorkAcceptanceIds = (
	state: IState,
	id: string | undefined,
	workAcceptanceIds?: string[]
) => workAcceptanceIds;

/**
 * Возвращает записи по категориям чек-листа
 */
export const extractCheckRecordsData = createSelector([getData], data => data || getInitialState());
/**
 * Возвращает состояние процесса изменения чек-листа
 */
export const extractCheckRecordsIsChanging = createSelector(
	[extractCheckRecordsData],
	data => data.isChanging
);

/**
 * Возвращает записи по категориям чек-листа в виде массива
 */
export const extractCheckRecordsAsArray = createSelector([extractCheckRecordsData], data =>
	data.allIds.map(id => data.byId[id])
);

/**
 * Возвращает записи по категориям чек-листа в виде массива по идентификатору чеклиста
 */
export const extractCheckRecordsAsArrayByListId = createSelector(
	[extractCheckRecordsAsArray, getId],
	(data, listId) => data.filter(item => item.links.checkListId === listId)
);

/**
 * Возвращает запись категории чек-листа по идентификатору категории
 */
export const extractCheckRecordByItemId = createSelector(
	[extractCheckRecordsAsArray, getId],
	(data, itemId) =>
		getLatestModified<ICheckRecord>(data.filter(record => record.links.checkItemId === itemId))
);

/**
 * Возвращает все записи категории чек-листа по идентификатору категории
 */
export const extractCheckRecordsByItemIAndWorkAcceptanceIds = createSelector(
	[extractCheckRecordsAsArray, getId, getWorkAcceptanceIds],
	(data, itemId, workAcceptanceIds) =>
		data.filter(
			record =>
				record.links.checkItemId === itemId &&
				record.links.workAcceptanceId &&
				workAcceptanceIds &&
				workAcceptanceIds.includes(record.links.workAcceptanceId)
		)
);

/**
 * Возвращает запись категории чек-листа по идентификатору чек-листа
 */
export const extractCheckRecordByListId = createSelector(
	[extractCheckRecordsAsArray, getId],
	(data, listId) =>
		getLatestModified<ICheckRecord>(
			data.filter(record => record.links.checkListId === listId && !record.links.checkItemId)
		)
);

/**
 * Возвращает записи чек-листа по идентификаторам чек-листов и идентификатору приёмки
 */
export const extractCheckRecordByListIds = createSelector(
	[extractCheckRecordsAsArray, getIds, getWorkAcceptanceId],
	(data, listIds, workAcceptanceId) =>
		data.filter(
			record =>
				listIds.includes(record.links.checkListId) &&
				record.links.workAcceptanceId &&
				workAcceptanceId &&
				record.links.workAcceptanceId === workAcceptanceId &&
				!record.links.checkItemId
		)
);

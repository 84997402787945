import {queryClient} from '@src/api/QueryClient';
import {
	IEditProblemInspectorsResponse,
	makeProblemInspectorsEditRequest
} from '@src/api/backend/problem/editInspectors';
import {problemsQueryKeys} from '@src/api/cache/problems/keys';
import {createApiAction} from '@src/store/middlewares/api';
import {addErrorToast} from '@src/utils/toasts';
import IError from '@tehzor/tools/interfaces/IError';
import * as types from '../constants';
import {delegationHistoryQueryKeys} from '@src/api/cache/delegationHistory/keys';
import {WorkingGroupTypeId} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroupType';

export type IEditProblemInspectorsPayload = IEditProblemInspectorsResponse;

const request = () => ({type: types.EDIT_INSPECTORS_REQUEST});

const success = (response: IEditProblemInspectorsResponse) => ({
	type: types.EDIT_INSPECTORS_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при изменении проверяющих');
	return {
		type: types.EDIT_INSPECTORS_FAILURE,
		payload: error
	};
};

/**
 * Изменяет проверяющих нарушения
 *
 * @param objectId id объекта
 * @param problemId id нарушения
 * @param respUsers пользователи
 * @param activeGroup текущая группа
 */
export const editProblemInspectors = (
	objectId: string,
	problemId: string,
	respUsers?: string[],
	activeGroup?: string
) =>
	createApiAction<IEditProblemInspectorsResponse>(
		request,
		res => {
			void queryClient.invalidateQueries({queryKey: problemsQueryKeys.list()});
			void queryClient.invalidateQueries({
				queryKey: [...problemsQueryKeys.detail(problemId), objectId]
			});
			void queryClient.invalidateQueries({
				queryKey: delegationHistoryQueryKeys.histories({
					objectId,
					problemId,
					workingGroupType: WorkingGroupTypeId.INSPECTORS
				})
			});
			return success(res);
		},
		failure,
		() => makeProblemInspectorsEditRequest(objectId, problemId, respUsers, activeGroup)
	);

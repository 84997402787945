import {useObject} from '@src/core/hooks/queries/objects/hooks';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IProblemTemplate} from '@tehzor/tools/interfaces/problemTemplatesSets/IProblemTemplate';
import {useLocalSearchProvider, useSearchSelectPopup} from '@tehzor/ui-components';
import {memo, useCallback, useMemo, useRef} from 'react';

import {TemplateSelectDialog} from './TemplateSelectDialog';
import {useProblemTemplatesAsArray} from '@src/core/hooks/queries/problemTemplatesSets/hooks';
import Description from './Description/Description';
import {useTemplateSelectHandler} from '../hooks/useTemplateSelectHandler';
import {IDescriptionProps} from './Description/IDescriptionProps';
import {templateRenderItem} from '../utils/templateRenderItem';

interface ISearchableProblemDescriptionProps extends Omit<IDescriptionProps, 'onSelect' | 'addon'> {
	stage: ObjectStageIds;
	objectId: string;
}

const SearchableProblemDescription = ({
	objectId,
	stage,
	onSetValue,
	name,
	...props
}: ISearchableProblemDescriptionProps) => {
	const inputRef = useRef<HTMLTextAreaElement>(null);
	const onSelect = useTemplateSelectHandler({objectId, stage});
	const {data: object} = useObject(objectId);
	const {data: templates} = useProblemTemplatesAsArray(object);
	const filteredTemplates = useMemo(
		() => templates?.filter(el => el.stageId === stage) || [],
		[templates, stage]
	);
	const provider = useLocalSearchProvider({
		initialList: filteredTemplates,
		keys: ['description']
	});

	const [popup, handleSearchChange] = useSearchSelectPopup<IProblemTemplate>({
		inputRef,
		onSelect,
		provider,
		renderItem: templateRenderItem,
		noCheckbox: true
	});
	const handleChange = useCallback(
		(fieldName: string, value: string) => {
			if (onSetValue) {
				onSetValue(fieldName, value);
			}

			handleSearchChange(value);
		},
		[handleSearchChange, onSetValue]
	);
	return (
		<>
			<Description
				ref={inputRef}
				onSetValue={handleChange}
				name={name}
				addon={
					<TemplateSelectDialog
						objectId={objectId}
						stage={stage}
					/>
				}
				{...props}
			/>
			{popup}
		</>
	);
};

export default memo(SearchableProblemDescription);

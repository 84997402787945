import {addWarningToast} from '@src/utils/toasts';
import {onlineManager, useQueryClient} from '@tanstack/react-query';
import useAppDispatch from '../../useAppDispatch';
import useAppSelector from '../../useAppSelector';
import {toListInspection, toInspection} from './utils/convertToLocalSave';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import IInspection from '@tehzor/tools/interfaces/inspections/IInspection';
import {ISavingInspection} from '@src/interfaces/saving/ISavingInspection';
import {IAddInspectionParams} from '@src/api/cache/inspections/defaults/useInspectionsMutationDefaults';
import {useAddInspectionMutation} from '@src/api/cache/inspections/mutations';
import {inspectionsQueryKeys} from '@src/api/cache/inspections/keys';
import {useLocalInspections} from '../../queries/inspections/hooks';
import {addInspectionActions} from '@src/store/modules/entities/inspection/actions';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import IListInspection from '@tehzor/tools/interfaces/inspections/IListInspection';
import {useNextLocalNumber} from '../../useNextLocalNumber';
import {OfflineDataTransferStatus} from '@tehzor/tools/contracts/dataTransferWebWorker/interfaces/IOfflineDataTransferStatuses';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';

export interface ISavingInspectionQuery {
	object: IObject;
	links: IInspection['links'];
	stage: ObjectStageIds | undefined;
	processId: ProcessIds;
	fields: ISavingInspection;
	transferStatus: OfflineDataTransferStatus;
	number: number;
	key: string;
}

export const useAddNewInspection = (object?: IObject) => {
	const dispatch = useAppDispatch();
	const queryClient = useQueryClient();
	const online = onlineManager.isOnline();
	const {data: localInspections} = useLocalInspections();
	const currentQueryFilter = useAppSelector(s => s.settings.pages.inspections);
	const nextNumber = useNextLocalNumber<IListInspection>(localInspections, 'createdAt');
	const mutation = useAddInspectionMutation();

	const createNewInspectionCache = ({
		objectId,
		links,
		stage,
		processId,
		fields
	}: Omit<IAddInspectionParams, 'key' | 'currentQueryFilter'>): Promise<IInspection> => {
		const key = Date.now().toString(10);
		if (links?.checkRecordId && links?.checkRecordId.startsWith('local')) {
			links.checkRecordId = undefined;
		}

		// Формируем кэш нарушения с данными для сохранения, для удобства
		// в дальнейшем мы используем его при сохранении локальных родительских сущностей
		return new Promise(resolve => {
			if (!object) return;
			const converted = toInspection(
				object,
				links,
				stage,
				processId,
				fields,
				key,
				nextNumber
			);
			const convertedToList = toListInspection(
				object,
				links,
				stage,
				processId,
				fields,
				key,
				nextNumber
			);
			queryClient.setQueryData<ISavingInspectionQuery>(inspectionsQueryKeys.savingData(key), {
				object,
				links,
				stage,
				processId,
				fields,
				key,
				transferStatus: OfflineDataTransferStatus.SAVED_LOCAL,
				number: nextNumber
			});
			queryClient.setQueryData(
				[...inspectionsQueryKeys.detail(key), objectId],
				convertedToList
			);

			if (!online) {
				addWarningToast('Локальное сохранение', 'осмотр добавлен локально');
				void queryClient.refetchQueries({queryKey: inspectionsQueryKeys.localList()});
				dispatch(addInspectionActions.success(converted));
			}
			resolve(converted);
		});

		// Складывем новое нарушение в кэш как отдельную сущность, чтоб можно было в неё перейти из списка
		// для локального редактирования и т.п.
		// await new Promise(resolve => {
	};

	const saveInspection = ({
		objectId,
		links,
		stage,
		processId,
		fields,
		key
	}: Omit<IAddInspectionParams, 'currentQueryFilter'>) => {
		mutation.mutate({
			objectId,
			links,
			stage,
			processId,
			fields,
			key,
			currentQueryFilter
		});
	};
	return {createNewInspectionCache, saveInspection};
};

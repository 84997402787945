import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import {WorkAcceptanceFrontTypeId} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceFrontType';
import {WorkAcceptanceTypeId} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceType';
import {WorkingGroupTypeId} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroupType';
import {IRuleParams} from '@tehzor/tools/utils/responsibilityRules/IRuleParams';
import {ISuggestions} from '@tehzor/tools/utils/responsibilityRules/ISuggestions';
import {formInitialExpandedGroups} from '@tehzor/ui-components/src/components/delegation/DelegationTree/utils/formInitialExpandedGroups';
import {ReactNode, useCallback, useEffect, useMemo, useState} from 'react';
import {DelegationDialog} from '../DelegationDialog';
import {ViewButtonsTypeId} from '../types/ViewButtonsTypeId';
import {getGroupsIds} from '../utils/getGroupIds';
import {useDelegationRules} from './useDelegationRules';
import {useEnrichedGroups} from './useEnrichedGroups';
import {useFilteredWorkingGroups} from './useFilteredWorkingGroups';
import {useViewButtons} from './useViewButtons';
import {useUpdateEffect} from 'react-use';
import {useSuggestionsByRules} from './useSuggestionsByRules';

interface IUseDelegationDialogProps {
	objectId: string;
	workingGroupType?: WorkingGroupTypeId;
	stage?: ObjectStageIds;
	scope?: string;
	workAcceptanceType?: WorkAcceptanceTypeId;
	workAcceptanceFrontType?: WorkAcceptanceFrontTypeId;
	ruleParams?: IRuleParams;
	activeGroup?: string;
	selectedUsers?: string[];
	selectedGroup?: string;
	author?: IBriefUser;
	onlyActiveGroup?: boolean;
	autoApplySuggestions?: boolean;
	required?: boolean;
	title?: string;
	onChange?: (users: string[], group: string | undefined) => void;
	onUpdateException?: (isException?: boolean) => void;
	checkDoSuggestions?: () => boolean;
}

export const useDelegationDialog = ({
	objectId,
	workingGroupType,
	stage,
	scope,
	workAcceptanceType,
	workAcceptanceFrontType,
	ruleParams,
	activeGroup,
	selectedUsers,
	selectedGroup,
	author,
	onlyActiveGroup,
	autoApplySuggestions,
	required,
	title,
	onChange,
	onUpdateException,
	checkDoSuggestions
}: IUseDelegationDialogProps): [ReactNode, () => void] => {
	const [isOpen, setOpen] = useState(false);
	const [suggestions, setSuggestions] = useState<ISuggestions>({});
	const [expandedGroups, setExpandedGroups] = useState<string[]>([]);

	const [viewButtons, viewButtonsType] = useViewButtons(
		suggestions,
		workingGroupType === undefined
	);

	const workingGroups = useFilteredWorkingGroups({
		objectId,
		activeGroup,
		workingGroupType,
		viewButtonsType,
		stage,
		scope,
		workAcceptanceType,
		workAcceptanceFrontType
	});

	const groupIds = useMemo(() => getGroupsIds(workingGroups), [workingGroups]);
	const rules = useDelegationRules({stage, scope, groupIds, objectId});

	const enrichedGroups = useEnrichedGroups({
		objectId,
		workingGroups,
		rules,
		suggestions,
		activeGroup,
		useSuggestions: viewButtonsType === ViewButtonsTypeId.SUGGESTED
	});

	// убираем обязательное требование поля при отсутствии вариантов для выбора
	useEffect(() => {
		if (!required || !onUpdateException) {
			return;
		}

		const isException = !workingGroups || workingGroups.length === 0;
		onUpdateException(isException);
	}, [workingGroups, onUpdateException, required]);

	/**
	 * Хук был добавлен во избежание цикличной зависимости, которая приводила к бесконечным ререндерам
	 * TODO: убрать цикличную зависимость в getSuggestionsByRule, прокидывая туда не фильтрованные группы
	 */
	const suggestionsRules = useSuggestionsByRules(workingGroups, rules, ruleParams);
	const isNeedDoSuggestions = checkDoSuggestions && checkDoSuggestions();

	useUpdateEffect(() => {
		if (!isNeedDoSuggestions) {
			return;
		}

		const [s, group, users] = suggestionsRules;
		setSuggestions(s);
		setExpandedGroups(formInitialExpandedGroups(enrichedGroups, users, group));

		if (autoApplySuggestions && onChange && users.length) {
			onChange(users, group);
		}
	}, [suggestionsRules, enrichedGroups, isNeedDoSuggestions]);

	const handleOpen = () => setOpen(true);

	const handleClose = useCallback(() => setOpen(false), []);

	const dialog = (
		<DelegationDialog
			activeGroup={activeGroup}
			users={selectedUsers}
			group={selectedGroup}
			author={author}
			workingGroups={enrichedGroups}
			isOpen={isOpen}
			onlyInitialLevelSelectable={onlyActiveGroup}
			onChange={onChange}
			expandedGroups={expandedGroups}
			onGroupsExpand={setExpandedGroups}
			onClose={handleClose}
			title={title}
			viewButtons={viewButtons}
		/>
	);

	return [dialog, handleOpen];
};

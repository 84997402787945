import {CSSProperties, MouseEvent, ReactNode, useCallback, useRef} from 'react';
import './FilesDropArea.less';
import classNames from 'classnames';

import IRawFile from '@tehzor/tools/interfaces/IRawFile';
import {FilesPicker} from '@tehzor/ui-components';
import ButtonBase from '@tehzor/ui-components/src/components/buttons/ButtonBase';

const defaultIcon = <i className="tz-attach-24" />;

interface IFilesDropAreaProps {
	className?: string;
	style?: CSSProperties;
	icon?: ReactNode;
	label?: ReactNode;
	accept?: string;
	multiple?: boolean;
	disabled?: boolean;

	onPick?: (files: IRawFile[]) => void;
}

const FilesDropArea = (props: IFilesDropAreaProps) => {
	const {
		className,
		style,
		icon = defaultIcon,
		label = 'Прикрепить файлы',
		accept,
		multiple,
		disabled,
		onPick
	} = props;

	const pickerRef = useRef<FilesPicker>(null);

	const handleClick = useCallback(
		(evt: MouseEvent) => {
			evt.preventDefault();

			if (pickerRef.current && !disabled) {
				pickerRef.current.open();
			}
		},
		[disabled]
	);

	return (
		<>
			<ButtonBase
				className={classNames(
					'files-drop-area',
					{'files-drop-area_disabled': disabled},
					className
				)}
				style={style}
				classNamePrefix="files-drop-area"
				leftIcon={icon}
				label={label}
				outerTagType="button"
				onClick={handleClick}
			/>
			<FilesPicker
				accept={accept}
				multiple={multiple}
				onChange={onPick}
				ref={pickerRef}
			/>
		</>
	);
};

FilesDropArea.displayName = 'FilesDropArea';

export default FilesDropArea;

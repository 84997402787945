import {queryClient} from '@src/api/QueryClient';
import {
	IEditInspectionPerformersResponse,
	makeInspectionPerformersEditRequest
} from '@src/api/backend/inspection/editPerformers';
import {inspectionsQueryKeys} from '@src/api/cache/inspections/keys';
import {createApiAction} from '@src/store/middlewares/api';
import {addErrorToast} from '@src/utils/toasts';
import IError from '@tehzor/tools/interfaces/IError';
import * as types from '../constants';
import {delegationHistoryQueryKeys} from '@src/api/cache/delegationHistory/keys';
import {WorkingGroupTypeId} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroupType';

export type IEditInspectionPerformersPayload = IEditInspectionPerformersResponse;

const request = () => ({type: types.EDIT_PERFORMERS_REQUEST});

const success = (response: IEditInspectionPerformersResponse) => ({
	type: types.EDIT_PERFORMERS_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при изменении исполнителей');
	return {
		type: types.EDIT_PERFORMERS_FAILURE,
		payload: error
	};
};

/**
 * Изменяет исполнителей осмотра
 *
 * @param objectId id объекта
 * @param problemId id осмотра
 * @param respUsers пользователи
 * @param activeGroup текущая группа
 */
export const editInspectionPerformers = (
	objectId: string,
	inspectionId: string,
	respUsers?: string[],
	activeGroup?: string
) =>
	createApiAction<IEditInspectionPerformersResponse>(
		request,
		res => {
			void queryClient.invalidateQueries({queryKey: inspectionsQueryKeys.list()});
			void queryClient.invalidateQueries({
				queryKey: [...inspectionsQueryKeys.detail(inspectionId), objectId]
			});
			void queryClient.invalidateQueries({
				queryKey: delegationHistoryQueryKeys.histories({
					objectId,
					inspectionId,
					workingGroupType: WorkingGroupTypeId.PERFORMERS
				})
			});
			return success(res);
		},
		failure,
		() => makeInspectionPerformersEditRequest(objectId, inspectionId, respUsers, activeGroup)
	);

import {useCustomFieldById} from '@src/core/hooks/queries/fields/hooks';
import {IFieldSetting, ValueTypeId} from '@tehzor/tools/interfaces/fields';
import {memo} from 'react';
import {CustomSelectField} from './components/CustomSelectField';
import {CustomDateField} from './components/CustomDateField';
import {CustomTextField} from './components/CustomTextField';
import {CustomLinkField} from './components/CustomLinkField';
import './CustomFields.less';
import {SetValueOptions} from '../ProblemForm';

interface ICustomFieldProps {
	custom: IFieldSetting;
	isMobile?: boolean;
	disabled?: boolean;
	onSetValue?: (name: string, value: unknown, options?: SetValueOptions) => void;
	name: string;
	errorMessage?: string;
	required?: boolean;
}

const CustomFieldComponent = ({
	custom,
	isMobile,
	name,
	errorMessage,
	onSetValue,
	disabled,
	required
}: ICustomFieldProps) => {
	const {data: field} = useCustomFieldById(custom.fieldId);
	switch (field?.valueType.id) {
		case ValueTypeId.SELECT: {
			return (
				<CustomSelectField
					setting={custom}
					name={name}
					onSetValue={onSetValue}
					field={field}
					isMobile={isMobile}
					required={required}
					errorMessage={errorMessage}
					disabled={disabled}
				/>
			);
		}
		case ValueTypeId.TEXT: {
			return (
				<CustomTextField
					name={name}
					onSetValue={onSetValue}
					setting={custom}
					field={field}
					required={required}
					errorMessage={errorMessage}
					disabled={disabled}
				/>
			);
		}
		case ValueTypeId.DATE: {
			return (
				<CustomDateField
					setting={custom}
					name={name}
					onSetValue={onSetValue}
					field={field}
					disabled={disabled}
					required={required}
					errorMessage={errorMessage}
				/>
			);
		}
		case ValueTypeId.LINK: {
			return (
				<CustomLinkField
					setting={custom}
					field={field}
					name={name}
					errorMessage={errorMessage}
					onSetValue={onSetValue}
					required={required}
					disabled={disabled}
				/>
			);
		}
		default: {
			return null;
		}
	}
};

export const CustomField = memo(CustomFieldComponent) as typeof CustomFieldComponent;

import './WarrantyClaimPage.less';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import {Desktop} from './components/Desktop';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {Mobile} from './components/Mobile';
import {useWarrantyClaim} from '@src/core/hooks/queries/warrantyClaim/hooks';
import {useWarrantyClaimSettings} from '../../core/hooks/settings/useWarrantyClaimSettings';

export const WarrantyClaimPage = () => {
	useScrollRestoration();
	const {objectId, warrantyClaimId} = useStrictParams<{
		objectId: string;
		warrantyClaimId: string;
	}>();
	const {data: warrantyClaim} = useWarrantyClaim(warrantyClaimId, objectId, undefined, {
		enabled: !!warrantyClaimId && !!objectId
	});

	const isDesktop = useIsDesktop();
	const settings = useWarrantyClaimSettings(objectId);
	
	if (isDesktop) {
		return (
			<Desktop
				settings={settings}
				objectId={objectId}
				warrantyClaimId={warrantyClaimId}
				warrantyClaim={warrantyClaim}
			/>
		);
	}
	return (
		<Mobile
			settings={settings}
			objectId={objectId}
			warrantyClaimId={warrantyClaimId}
			warrantyClaim={warrantyClaim}
		/>
	);
};

import {useTranslation} from 'react-i18next';

interface ILabel {
	isFastProblemAddingActive: boolean;
	isDesktop: boolean;
	isProblemType?: boolean;
}

export const ButtonLabel = ({isDesktop, isFastProblemAddingActive, isProblemType}: ILabel) => {
	const {t} = useTranslation();
	if (isDesktop && isFastProblemAddingActive && isProblemType) {
		return <p>{t('globalAddingEntityDialog.fastAddingProblem.save')}</p>;
	}
	if (!isDesktop && isFastProblemAddingActive && isProblemType) {
		return (
			<div
				className={
					isProblemType ? 'custom-action-buttons__submit-button-wrapper' : undefined
				}
			>
				<span>{t('globalAddingEntityDialog.dialog.footer.actionButtons.save')} </span>
				<span className="custom-action-buttons__submit-button-label">
					{t('globalAddingEntityDialog.fastAddingProblem.save.mobile')}
				</span>
			</div>
		);
	} else {
		return <p>{t('globalAddingEntityDialog.dialog.footer.actionButtons.save')}</p>;
	}
};

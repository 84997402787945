import {createSlice, PayloadAction} from '@reduxjs/toolkit';

const initialState = false;

export const modalDialogSlice = createSlice({
	name: 'modal-dialog',
	initialState,
	reducers: {
		changeFastAddingMode(state, action: PayloadAction<boolean>) {
			return action.payload;
		},
		resetFastAddingMode() {
			return false;
		}
	}
});

export const {actions: modalDialogActions, reducer: modalDialogReducer} = modalDialogSlice;

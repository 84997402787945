import {useDelegationDialog} from '@src/components/DelegationDialog/hooks/useDelegationDialog';
import {useResponsibleDisplayValue} from '@src/core/hooks/useResponsibleDisplayValue';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import {WorkAcceptanceFrontTypeId} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceFrontType';
import {WorkAcceptanceTypeId} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceType';
import {WorkingGroupTypeId} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroupType';
import {IRuleParams} from '@tehzor/tools/utils/responsibilityRules/IRuleParams';
import {EditableFieldLabel, TextField} from '@tehzor/ui-components';
import {ReactNode, memo} from 'react';

interface IResponsiblesProps {
	className?: string;
	objectId: string;
	stage?: ObjectStageIds;
	scope?: string;
	workAcceptanceType?: WorkAcceptanceTypeId;
	workAcceptanceFrontType?: WorkAcceptanceFrontTypeId;

	workingGroupType?: WorkingGroupTypeId;
	respUsers?: string[];
	activeGroup?: string;
	author?: IBriefUser;
	ruleParams?: IRuleParams;

	label?: string;

	//TODO: после перевода всех форм на react-hook-form удалить этот required
	required?: boolean;
	errorLabel?: string;
	dialogTitle?: string;
	icon?: ReactNode;

	disabled?: boolean;

	onResponsiblesChange?: (users: string[], group?: string) => void;
	onUpdateException?: (isException?: boolean) => void;

	checkDoSuggestions?: () => boolean;
}

const userIcon = <i className="tz-user-20" />;

export const Responsibles = memo(
	({
		className,
		objectId,
		stage,
		scope,
		workAcceptanceType,
		workAcceptanceFrontType,
		workingGroupType,
		respUsers,
		activeGroup,
		author,
		ruleParams,
		label,
		errorLabel,
		dialogTitle,
		icon,
		disabled,
		required,
		onResponsiblesChange,
		onUpdateException,
		checkDoSuggestions
	}: IResponsiblesProps) => {
		const displayValue = useResponsibleDisplayValue(respUsers, activeGroup);

		const [dialog, handleOpen] = useDelegationDialog({
			objectId,
			workingGroupType,
			stage,
			scope,
			workAcceptanceType,
			workAcceptanceFrontType,
			ruleParams,
			selectedUsers: respUsers,
			selectedGroup: activeGroup,
			author,
			required,
			autoApplySuggestions: true,
			title: dialogTitle,
			onChange: onResponsiblesChange,
			onUpdateException,
			checkDoSuggestions
		});

		return (
			<div className={className}>
				<EditableFieldLabel>{label}</EditableFieldLabel>

				<TextField
					value={displayValue}
					elementType="div"
					error={errorLabel || undefined}
					disabled={disabled}
					icon={icon ?? userIcon}
					onClick={handleOpen}
				/>

				{dialog}
			</div>
		);
	}
);

import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {delegationHistoryQueryKeys} from '@src/api/cache/delegationHistory/keys';
import {
	IGetDelegationHistoriesParams,
	IGetDelegationHistoriesResponse
} from '@src/api/backend/delegationHistories';
import {IDelegationHistory} from '@tehzor/tools/interfaces/delegationHistories/IDelegationHistory';
import {extractDelegationHistoriesAsArray} from './selectors';
import {useCallback} from 'react';
import {WorkingGroupTypeId} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroupType';

export const useDelegationHistory = <T = IGetDelegationHistoriesResponse>(
	delegationHistoriesParams: IGetDelegationHistoriesParams,
	errorDetail: string,
	select?: (data: IGetDelegationHistoriesResponse) => T,
	options?: Partial<UseQueryOptions<IGetDelegationHistoriesResponse, IError, T>>
) =>
	useQuery<IGetDelegationHistoriesResponse, IError, T>({
		queryKey: delegationHistoryQueryKeys.histories(delegationHistoriesParams),
		meta: {
			error: `при загрузке истории делегирования ${errorDetail}`
		},
		select,
		...options
	});

export const useDelegationHistoryAsArray = (
	delegationHistoriesParams: IGetDelegationHistoriesParams,
	errorDetail: string,
	options?: Partial<
		UseQueryOptions<IGetDelegationHistoriesResponse, IError, IDelegationHistory[]>
	>
) => {
	const selector = useCallback(
		(data: IGetDelegationHistoriesResponse) => extractDelegationHistoriesAsArray(data),
		[]
	);

	return useDelegationHistory(delegationHistoriesParams, errorDetail, selector, options);
};

export const useDelegationHistoryAsArrayByInspection = (objectId: string, inspectionId: string) =>
	useDelegationHistoryAsArray(
		{
			objectId,
			inspectionId,
			workingGroupType: WorkingGroupTypeId.PERFORMERS
		},
		'исполнителей'
	);

export const useDelegationHistoryAsArrayByWorkAcceptance = (
	objectId: string,
	workAcceptanceId: string
) =>
	useDelegationHistoryAsArray(
		{
			objectId,
			workAcceptanceId,
			workingGroupType: WorkingGroupTypeId.ACCEPTORS
		},
		'принимающих'
	);

export const useDelegationHistoryAsArrayByProblemPerformers = (
	objectId: string,
	problemId: string
) =>
	useDelegationHistoryAsArray(
		{
			objectId,
			problemId,
			workingGroupType: WorkingGroupTypeId.PERFORMERS
		},
		'исполнителей'
	);

export const useDelegationHistoryAsArrayByProblemInspectors = (
	objectId: string,
	problemId: string
) =>
	useDelegationHistoryAsArray(
		{
			objectId,
			problemId,
			workingGroupType: WorkingGroupTypeId.INSPECTORS
		},
		'проверяющих'
	);

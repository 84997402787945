import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';

export const useEntitySelectContainerSubTitle = () => {
	const {t} = useTranslation();

	const getSubTitle = useCallback(
		(objectStageName: string, processName: string): React.ReactNode => (
			<div>
				<div>
					{t(
						'globalAddingEntityDialog.entitySelectContainer.getEntitySelectContainerSubTitle',
						{
							name: objectStageName
						}
					)}
				</div>
				<div>
					{t(
						'globalAddingEntityDialog.entitySelectContainer.getEntitySelectContainerProcessSubTitle',
						{
							name: processName
						}
					)}
				</div>
			</div>
		),
		[t]
	);

	return getSubTitle;
};

export const useEntitySelectContainerSubSubTitle = () => {
	const {t} = useTranslation();

	const getSubSubTitle = useCallback(
		(
			objectName?: string,
			spaceTypeId?: string,
			spaceName?: string,
			structureTypeId?: string,
			structureName?: string
		): React.ReactNode => 
			 (
				<div>
					{objectName ? <div>{objectName}</div> : null}
					{spaceTypeId && spaceName ? (
						<div>{`${t(`spaceTypes.singular.${spaceTypeId}`)} №${spaceName}`}</div>
					) : null}
					{structureTypeId && structureName ? (
						<div>{`${t(`structureTypes.${structureTypeId}`)}: ${structureName}`}</div>
					) : null}
				</div>
			)
		,
		[t]
	);

	return getSubSubTitle;
};

import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';

export type SpaceEntitiesTab =
	| 'all'
	| 'checks'
	| 'internal-acceptances'
	| 'owner-acceptances'
	| 'warranty-claims'
	| 'work-acceptances';

export interface ISpaceState {
	entitiesTab?: SpaceEntitiesTab;
}

export const getInitialState = (): ISpaceState => ({
	entitiesTab: 'all'
});

export default createReducer<ISpaceState>(getInitialState(), {
	[types.CHANGE_ENTITIES_TAB]: (state, {payload}: {payload: SpaceEntitiesTab}) => {
		state.entitiesTab = payload;
	},
	[CLEAR_STORE]: getInitialState
});

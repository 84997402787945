import {CSSProperties, memo, useCallback, useMemo} from 'react';
import {EditableFieldLabel, ProblemTagsSelect} from '@tehzor/ui-components';
import {makeTreeData} from '@src/utils/makeTreeData';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {useExtractProblemTagsAsArray} from '@src/core/hooks/queries/problemTags/hooks';
import {useTranslation} from 'react-i18next';
import {useExtractProblemTagsSetsAsFilteredArray} from '@src/core/hooks/queries/problemTagsSets/hooks';
import {useController, useFormContext} from 'react-hook-form';
import {SetValueOptions} from './ProblemForm';

interface IProblemTagsProps {
	objectId: string;
	stage: ObjectStageIds;
	className?: string;
	name: string;
	style?: CSSProperties;
	label?: string;
	disabled?: boolean;
	onSetValue?: (name: string, value: unknown, options?: SetValueOptions) => void;
	errorMessage?: string;
	required?: boolean;
}

const ProblemTagsMemo = ({
	objectId,
	stage,
	className,
	style,
	label = 'Метки',
	disabled,
	name,
	onSetValue,
	errorMessage,
	required
}: IProblemTagsProps) => {
	const {t} = useTranslation();
	const {control} = useFormContext();
	const {data: sets} = useExtractProblemTagsSetsAsFilteredArray(objectId, stage);
	const {data: tagsArray} = useExtractProblemTagsAsArray();
	const treeData = useMemo(() => makeTreeData(sets, tagsArray, true), [sets, tagsArray]);

	// обязательное требование заполнения снимается если нет меток на выбор
	const isException = treeData?.length === 0 || !treeData;
	const {
		field: {value: tags},
		fieldState: {invalid}
	} = useController({
		name,
		control,
		rules: {required: isException ? !isException : required}
	});
	const handleChange = useCallback(
		(item: string[]) => {
			if (onSetValue) {
				onSetValue(name, item);
			}
		},
		[onSetValue, name]
	);

	return (
		<div
			className={className}
			style={style}
		>
			<EditableFieldLabel>{label}</EditableFieldLabel>

			<ProblemTagsSelect
				dialogTitle={t('components.editableFields.problemTags.dialog.title')}
				treeData={treeData}
				value={tags}
				searchPlaceholder={t('components.editableFields.problemTags.searchPlaceholder')}
				dialogAcceptBtnLabel={t(
					'components.editableFields.problemTags.dialog.accept.label'
				)}
				dialogRejectBtnLabel={t(
					'components.editableFields.problemTags.dialog.reject.label'
				)}
				error={invalid ? errorMessage : undefined}
				disabled={disabled}
				onChange={handleChange}
				multiple
			/>
		</div>
	);
};
export const ProblemTags = memo(ProblemTagsMemo) as typeof ProblemTagsMemo;

import React, {useCallback, useState} from 'react';
import {AddingWorkAcceptanceDialog} from '../AddingWorkAcceptanceDialog';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {useTranslation} from 'react-i18next';
import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';
import IInspection from '@tehzor/tools/interfaces/inspections/IInspection';
import {IWorkAcceptanceAddingEntityType} from '@src/store/modules/settings/workAcceptanceAdding/interfaces';

interface IAddingWorkAcceptanceDialogProps {
	objectId: string;
	workAcceptanceId?: string;
	workAcceptanceNumber?: number;
	links?: IProblem['links'] | IInspection['links'];
	stage?: ObjectStageIds;
	types?: IWorkAcceptanceAddingEntityType[];
	onSuccess?: () => void | Promise<void>;
	title?: string;
}

export function useAddingWorkAcceptanceDialog({
	objectId,
	workAcceptanceId,
	workAcceptanceNumber,
	links,
	stage,
	types,
	onSuccess,
	title
}: IAddingWorkAcceptanceDialogProps): [React.ReactNode, () => void] {
	const {t} = useTranslation();
	const [isOpen, setOpen] = useState<boolean>(false);
	const entityButtonTitle = t('addingWorkAcceptanceDialog.dialog.entityButton.title', {
		number: workAcceptanceNumber
	});
	const open = useCallback(() => {
		setOpen(true);
	}, []);

	const close = useCallback(() => {
		setOpen(false);
	}, []);

	const dialog =
		stage && isOpen ? (
			<AddingWorkAcceptanceDialog
				title={title ?? t('useAddingWorkAcceptanceDialog.title')}
				objectId={objectId}
				stage={stage}
				workAcceptanceId={workAcceptanceId}
				links={links}
				types={types}
				isOpen={isOpen}
				onClose={close}
				onSuccess={onSuccess}
				entityButtonTitle={entityButtonTitle}
			/>
		) : null;

	return [dialog, open];
}

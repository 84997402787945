import {Responsibles} from '@src/components/viewFields/Responsibles/Responsibles';
import {useProblemResponsiblePermissions} from '@src/core/hooks/permissions/useProblemResponsiblePermissions';
import {IEntitySettings} from '@src/core/hooks/settings/useEntitySettings';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IPreparedProblem} from '@src/pages/ProblemPage/interfaces/IPreparedProblem';
import {extractUserProfile} from '@src/store/modules/auth/profile/selectors';
import {editProblemInspectors} from '@src/store/modules/pages/problem/actions/editInspectors';
import {WorkingGroupTypeId} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroupType';
import {IRuleParams} from '@tehzor/tools/utils/responsibilityRules/IRuleParams';
import {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useDelegationHistoryAsArrayByProblemInspectors} from '@src/core/hooks/queries/delegationHistory/hooks';

interface IInspectorsProps {
	problem: IPreparedProblem;
	settings?: IEntitySettings;
}

const inspectorIcon = <i className="tz-inspector-20" />;

export const ProblemInspectors = ({problem, settings}: IInspectorsProps) => {
	const {t} = useTranslation();
	const dispatch = useAppDispatch();

	const user = useAppSelector(extractUserProfile);

	const {id, objectId, stage, inspectorsActiveGroup, inspectorsInitialGroup, inspectors} =
		problem;
	const {data: histories} = useDelegationHistoryAsArrayByProblemInspectors(objectId, id);

	const {canEditInspectors, onlyInspectorsActiveGroup} = useProblemResponsiblePermissions(
		objectId,
		problem
	);

	const ruleParams: IRuleParams = useMemo(
		() => ({
			categoryId: problem.categoryId,
			planId: problem.plan?.id
		}),
		[problem.categoryId, problem.plan?.id]
	);

	const handleChange = useCallback(
		async (users: string[], group?: string) => {
			await dispatch(editProblemInspectors(objectId, id, users, group));
		},
		[objectId, id]
	);

	return (
		<Responsibles
			objectId={objectId}
			stage={stage}
			workingGroupType={WorkingGroupTypeId.INSPECTORS}
			respUsers={inspectors}
			activeGroup={inspectorsActiveGroup}
			initialGroup={inspectorsInitialGroup}
			author={problem.createdBy ?? user}
			canEditResponsibles={canEditInspectors}
			onlyActiveGroup={onlyInspectorsActiveGroup}
			ruleParams={ruleParams}
			histories={histories}
			required={settings?.builtin.inspectors.required}
			icon={inspectorIcon}
			label={t('problemPage.info.inspectors.label')}
			delegationDialogLabel={t('components.delegationDialog.inspectors.title')}
			historyAutomaticActionLabelIn={t(
				'problemPage.info.inspectors.historyAutomaticActionLabelIn'
			)}
			historyAutomaticActionLabelTitle={t(
				'problemPage.info.inspectors.historyAutomaticActionLabelTitle'
			)}
			historyUserActionLabelIn={t('problemPage.info.inspectors.historyUserActionLabelIn')}
			historyUserActionLabelTitle={t(
				'problemPage.info.inspectors.historyUserActionLabelTitle'
			)}
			onResponsiblesChange={handleChange}
		/>
	);
};

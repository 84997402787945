import {useCallback, useMemo, useState} from 'react';
import './EditableInspectionDialog.less';
import {makeTitle} from './utils/makeTitle';
import {ActionButtons, Button, ModalDialog} from '@tehzor/ui-components';
import {useEditableInspection} from '@src/components/EditableInspection/hooks/useEditableInspection';
import {makeDefaultData} from '@src/core/hooks/states/useEditableInspectionState';
import IInspection from '@tehzor/tools/interfaces/inspections/IInspection';
import {useEditInspection} from '@src/core/hooks/mutations/inspection/useEditInspection';
import {useObject} from '@src/core/hooks/queries/objects/hooks';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';
import {useTranslation} from 'react-i18next';

interface IEditableInspectionDialogProps {
	objectId: string;
	inspection: IInspection;
	isOpen: boolean;

	onClose: () => void;
}

/**
 * Окно редактирования осмотра
 */
const EditableInspectionDialog = ({
	objectId,
	inspection,
	isOpen,
	onClose
}: IEditableInspectionDialogProps) => {
	const {t} = useTranslation();
	const [saving, setSaving] = useState(false);
	const {data: object} = useObject(objectId);
	const defaultData = useMemo(() => makeDefaultData(inspection), [inspection]);
	const [fields, getSavingData, , isBlocking] = useEditableInspection(
		objectId,
		inspection.stage ?? ObjectStageIds.BUILDING,
		inspection.processId,
		defaultData,
		saving,
		undefined,
		undefined,
		inspection.links
	);
	const [editInspection] = useEditInspection(object);
	const save = useCallback(async () => {
		setSaving(true);
		if (inspection) {
			const savingData = await getSavingData(true);
			if (savingData) {
				editInspection({inspectionId: inspection.id, objectId, fields: savingData});
				onClose();
			}
		}
		setSaving(false);
	}, [objectId, inspection, getSavingData, editInspection, onClose]);

	const [closingDialog, getClosingConfirmation] = useTranslatedConfirmDialog();

	const handleClose = useCallback(async () => {
		if (onClose && (!isBlocking || (await getClosingConfirmation()))) {
			onClose();
		}
	}, [isBlocking, onClose]);

	return (
		<ModalDialog
			className={{root: 'editable-inspection-dialog'}}
			open={isOpen}
			title={makeTitle(inspection)}
			footer={
				<ActionButtons>
					<Button
						type="accent-blue"
						label={t('editableInspectionDialog.button.save')}
						disabled={saving}
						onClick={save}
					/>
					<Button
						type="cancel"
						label={t('editableInspectionDialog.button.cancel')}
						disabled={saving}
						onClick={handleClose}
					/>
				</ActionButtons>
			}
			fullScreenOnTablet
			onRequestClose={handleClose}
		>
			{fields}
			{closingDialog}
		</ModalDialog>
	);
};

export default EditableInspectionDialog;

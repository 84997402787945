import {useStructuresList} from './useStructuresList';
import {useCallback} from 'react';
import {IGetListStructuresResponse} from '@/shared/api/structures/getStructuresList';

export const useStructureById = (objectId: string, structureId?: string) => {
	const selector = useCallback(
		(data: IGetListStructuresResponse) =>  structureId ? data.byId[structureId] : undefined,
		[structureId]
	);
	return useStructuresList(objectId, selector);
};

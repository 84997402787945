import {ReactNode, useCallback, useState} from 'react';
import {DelegationHistoryDialog} from '../DelegationHistoryDialog';
import {IDelegationHistory} from '@tehzor/tools/interfaces/delegationHistories/IDelegationHistory';
import {useEnrichedHistories} from './useEnrichedHistories';

interface IUseDelegationHistoryDialogProps {
	histories: IDelegationHistory[];
	title?: string;
	automaticActionLabelIn?: string;
	automaticActionLabelTitle?: string;
	userActionLabelIn?: string;
	userActionLabelTitle?: string;
}

export const useDelegationHistoryDialog = ({
	histories,
	automaticActionLabelIn,
	automaticActionLabelTitle,
	userActionLabelIn,
	userActionLabelTitle,
}: IUseDelegationHistoryDialogProps): [ReactNode, () => void] => {
	const [isOpen, setOpen] = useState(false);

	const enrichedHistories = useEnrichedHistories(histories);

	const handleClose = useCallback(() => {
		setOpen(false);
	}, []);

	const handleOpen = () => {
		setOpen(true);
	};

	const dialog = isOpen ? (
		<DelegationHistoryDialog
			histories={enrichedHistories}
			isOpen={isOpen}
			automaticActionLabelIn={automaticActionLabelIn}
			automaticActionLabelTitle={automaticActionLabelTitle}
			userActionLabelIn={userActionLabelIn}
			userActionLabelTitle={userActionLabelTitle}
			onClose={handleClose}
		/>
	) : null;

	return [dialog, handleOpen];
};

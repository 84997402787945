import AddingProblem, {IAddingProblemRefProps} from '@src/components/AddingProblem';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {ISavingProblem} from '@src/interfaces/saving/ISavingProblem';
import {IWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaim';
import {IEntitySelectProps} from '@tehzor/ui-components/src/components/containers/EntitySelectContainer/components/EntitySelect/EntitySelect';
import {Dispatch, ReactNode, Ref, SetStateAction, useEffect} from 'react';
import {AddingWarrantyClaimView} from '../AddingWarrantyClaimDialog';
import AddingWarrantyClaim, {IAddingWarrantyClaimRefProps} from '../components/AddingWarrantyClaim';
import DialogTitle from '../components/DialogTitle';
import {useWarrantyClaim} from '@src/core/hooks/queries/warrantyClaim/hooks';
import {
	useExtractLatestWarrantyClaimId,
	useExtractLatestWarrantyClaimsAsArray
} from '@src/core/hooks/queries/warrantyClaims/latest/hooks';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';

interface IUseClaimMapsProps {
	objectId: string;
	claimId?: string;
	spaceId?: string;
	stage: ObjectStageIds;
	processId: ProcessIds;
	links?: IWarrantyClaim['links'];
	view: AddingWarrantyClaimView;
	addingEntityRef: Ref<IAddingProblemRefProps | IAddingWarrantyClaimRefProps>;

	saving: boolean;
	setSaving: Dispatch<SetStateAction<boolean>>;
	selectedWarrantyClaimId?: string;
	setSelectedWarrantyClaimId: Dispatch<SetStateAction<string | undefined>>;

	defaultProblemData?: ISavingProblem;

	onClose: () => void;
}

export const useClaimMaps = (
	props: IUseClaimMapsProps
): [
	Record<ObjectStageIds, Record<ProcessIds, ReactNode>>,
	Record<ObjectStageIds, Record<ProcessIds, IEntitySelectProps>>
] => {
	const {
		objectId,
		claimId,
		spaceId,
		stage,
		processId,
		links,
		view,
		addingEntityRef,
		saving,
		setSaving,
		selectedWarrantyClaimId,
		setSelectedWarrantyClaimId,
		defaultProblemData,
		onClose
	} = props;

	const contentMap = {} as Record<ObjectStageIds, Record<ProcessIds, ReactNode>>;
	const entitiesSelectMapProps = {} as Record<
		ObjectStageIds,
		Record<ProcessIds, IEntitySelectProps>
	>;

	const {data: latestWarrantyClaimId} = useExtractLatestWarrantyClaimId(objectId, links?.spaceId);

	const {data: latestWarrantyClaims} = useExtractLatestWarrantyClaimsAsArray(
		objectId,
		links?.spaceId
	);

	useEffect(() => {
		if (latestWarrantyClaimId) {
			setSelectedWarrantyClaimId(latestWarrantyClaimId);
		}
	}, [latestWarrantyClaimId]);

	contentMap[stage] = {} as Record<ProcessIds, ReactNode>
	contentMap[stage][processId] = (
		<>
			<div className="adding-warranty-claim-dialog__title">
				<DialogTitle view={view} />
			</div>
			{view === 'problem' ? (
				<AddingProblem
					objectId={objectId}
					links={{...links, warrantyClaimId: selectedWarrantyClaimId}}
					stage={stage}
					processId={processId}
					defaultData={defaultProblemData}
					saving={saving}
					onClose={onClose}
					setSaving={setSaving}
					ref={addingEntityRef}
				/>
			) : view === 'claim' ? (
				<AddingWarrantyClaim
					objectId={objectId}
					spaceId={spaceId}
					links={links}
					saving={saving}
					onClose={onClose}
					setSaving={setSaving}
					ref={addingEntityRef}
				/>
			) : null}
		</>
	);

	const {data: warrantyClaim} = useWarrantyClaim(claimId as string, objectId, undefined, {
		enabled: !!claimId && !!objectId
	});

	entitiesSelectMapProps[stage] = {} as Record<ProcessIds, IEntitySelectProps>;
	entitiesSelectMapProps[stage][processId] = {
		name: 'Гарантийное обращение',
		newName: 'Новое гарантийное обращение',
		selected: selectedWarrantyClaimId,
		onEntityChange: setSelectedWarrantyClaimId,
		withoutNew: !!warrantyClaim,
		entities: warrantyClaim ? [warrantyClaim] : latestWarrantyClaims,
		localEntities: []
	};

	return [contentMap, entitiesSelectMapProps];
};

import {ISavingInspection} from '@src/interfaces/saving/ISavingInspection';
import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {addErrorToast} from '@src/utils/toasts';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import IInspection from '@tehzor/tools/interfaces/inspections/IInspection';
import {IAddInspectionResponse, makeInspectionAddRequest} from '@src/api/backend/inspection/add';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';

export type IAddInspectionPayload = IAddInspectionResponse;

const request = () => ({type: types.ADD_REQUEST});

const success = (response: IAddInspectionResponse) => ({
	type: types.ADD_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при добавлении осмотра');
	return {
		type: types.ADD_FAILURE,
		payload: error
	};
};

export const addInspectionActions = {request, success, failure};

/**
 * Добавляет осмотр
 *
 * @param objectId id объекта
 * @param links ссылки на другие сущности
 * @param stage стадия объекта
 * @param processId id процесса
 * @param fields данные осмотра
 */
export const addInspection = (
	objectId: string,
	links: IInspection['links'] | undefined,
	stage: ObjectStageIds | undefined,
	processId: ProcessIds,
	fields: ISavingInspection
) =>
	createApiAction<IAddInspectionResponse>(request, success, failure, () =>
		makeInspectionAddRequest(objectId, links, fields, processId, stage));

import {useDelegationHistoryDialog} from '@src/components/DelegationHistoryDialog/hooks/useDelegationHistoryDialog';
import {IDelegationHistory} from '@tehzor/tools/interfaces/delegationHistories/IDelegationHistory';
import {InlineButton} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

interface IDelegationHistoryButtonProps {
	histories: IDelegationHistory[];
	historyAutomaticActionLabelIn?: string;
	historyAutomaticActionLabelTitle?: string;
	historyUserActionLabelIn?: string;
	historyUserActionLabelTitle?: string;
}

export const DelegationHistoryButton = ({
	histories,
	historyAutomaticActionLabelIn,
	historyAutomaticActionLabelTitle,
	historyUserActionLabelIn,
	historyUserActionLabelTitle,
}: IDelegationHistoryButtonProps) => {
	const {t} = useTranslation();

	const [dialog, handleOpen] = useDelegationHistoryDialog({
		histories,
		automaticActionLabelIn: historyAutomaticActionLabelIn,
		automaticActionLabelTitle: historyAutomaticActionLabelTitle,
		userActionLabelIn: historyUserActionLabelIn,
		userActionLabelTitle: historyUserActionLabelTitle,
	});

	return (
		<>
			<InlineButton
				className="responsibles-view-field__btn"
				type="accent"
				label={t('responsibles.delegationHistoryBtn.label')}
				onClick={handleOpen}
			/>
			{dialog}
		</>
	);
};

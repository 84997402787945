import {forwardRef, Ref, useEffect, Dispatch, SetStateAction} from 'react';
import './AddingOwnerAcptDialog.less';
import {IOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptance';
import AddingAcceptance, {IAddingAcceptanceRefProps} from './components/AddingAcceptance';
import DialogTitle from './components/DialogTitle';
import AddingProblem, {IAddingProblemRefProps} from '../AddingProblem';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {ISavingProblem} from '@src/interfaces/saving/ISavingProblem';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {modalDialogActions} from '@src/store/modules/settings/modalDialog/slice';

export type AddingOwnerAcceptanceView = 'acceptance' | 'problem' | undefined;

interface IAddingOwnerAcceptanceDialogProps {
	objectId: string;
	spaceId?: string;
	links?: IOwnerAcceptance['links'];
	defaultProblemData?: ISavingProblem;
	selectedOwnerAcceptanceId?: string;

	view: AddingOwnerAcceptanceView;
	setView: Dispatch<SetStateAction<AddingOwnerAcceptanceView>>;
	saving: boolean;
	setSaving: Dispatch<SetStateAction<boolean>>;

	onClose: () => void;
}

const AddingOwnerAcptGlobal = (
	props: IAddingOwnerAcceptanceDialogProps,
	ref?: Ref<IAddingAcceptanceRefProps | IAddingProblemRefProps>
) => {
	const {
		objectId,
		spaceId,
		links,
		defaultProblemData,
		selectedOwnerAcceptanceId,
		view,
		setView,
		saving,
		setSaving,
		onClose
	} = props;

	const dispatch = useAppDispatch();
	const {resetFastAddingMode} = modalDialogActions;

	useEffect(() => {
		setView(!selectedOwnerAcceptanceId ? 'acceptance' : 'problem');
		dispatch(resetFastAddingMode());
	}, [selectedOwnerAcceptanceId, dispatch, resetFastAddingMode]);

	return (
		<div>
			<div className="adding-owner-acpt-dialog__header adding-owner-acpt-dialog__header_global">
				<DialogTitle view={view} />
			</div>
			{view === 'problem' ? (
				<AddingProblem
					objectId={objectId}
					links={{...links, ownerAcceptanceId: selectedOwnerAcceptanceId}}
					stage={ObjectStageIds.TRANSFER}
					processId={ProcessIds.UNITS_HANDOVER}
					defaultData={defaultProblemData}
					saving={saving}
					onClose={onClose}
					setSaving={setSaving}
					ref={ref}
				/>
			) : view === 'acceptance' ? (
				<AddingAcceptance
					objectId={objectId}
					spaceId={spaceId}
					links={links}
					saving={saving}
					onClose={onClose}
					setSaving={setSaving}
					ref={ref}
				/>
			) : null}
		</div>
	);
};

export default forwardRef<
	IAddingProblemRefProps | IAddingAcceptanceRefProps,
	IAddingOwnerAcceptanceDialogProps
>(AddingOwnerAcptGlobal);

import {IWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroup';

interface WorkAcceptanceAcceptorsProps {
	acceptorsGroup: IWorkingGroup;
	currentAcceptors: string[];
	company: string;
}

export const WorkAcceptanceAcceptors = ({
	acceptorsGroup,
	currentAcceptors,
	company
}: WorkAcceptanceAcceptorsProps) => (
	<>
		<span>{acceptorsGroup.name}</span>{' '}
		{currentAcceptors?.[0] && (
			<span>
				{currentAcceptors?.[0]}{' '}
				{currentAcceptors?.length > 1 && <span>+{currentAcceptors.length - 1}</span>}{' '}
				{company && <span>{company}</span>}
			</span>
		)}
	</>
);

import {CellProps} from 'react-table';
import {useExtractCategoryById} from '@src/core/hooks/queries/categories/hook';
import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';
import './WorkAcceptanceDesktopStageCell.less';

const getStageProcess = (
	percent: number | undefined,
	physicalValue: string | undefined,
	physicalUnit: string | undefined
) =>
	percent && physicalValue && physicalUnit ? (
		<div className="stage-cell__process">
			{percent}%, {physicalValue} {physicalUnit}
		</div>
	) : null;

export const WorkAcceptanceDesktopStageCell = ({row}: CellProps<{data: IWorkAcceptance}>) => {
	const {data: workAcceptance} = row.original;
	const {data: category} = useExtractCategoryById(row.original.data.categoryId);

	return (
		<div className="stage-cell">
			<div>{category ? category.name : ''}</div>
			{getStageProcess(
				workAcceptance.percent,
				workAcceptance.physicalWorkScope?.value,
				workAcceptance.physicalWorkScope?.unitName
			)}
		</div>
	);
};

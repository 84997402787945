import {useCallback} from 'react';
import {FloatingActionButton, IconButton} from '@tehzor/ui-components';
import {useAddingProblemDialog} from '@src/components/AddingProblemDialog/hooks/useAddingProblemDialog';
import {BeforeDesktop, Desktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useSpaceLocationForEntities} from '@src/core/hooks/spaces/useSpaceLocationForEntities';
import {IOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptance';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {queryClient} from '@src/api/QueryClient';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import {useTranslation} from "react-i18next";
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';

interface IEntityAddingProps {
	objectId: string;
	ownerAcceptance: IOwnerAcceptance;
}

const mobileAddIcon = <i className="tz-plus-16" />;
const desktopAddIcon = <i className="tz-plus-20" />;

const EntityAdding = ({objectId, ownerAcceptance}: IEntityAddingProps) => {
	const {t} = useTranslation();
	const links = {...ownerAcceptance.links, ownerAcceptanceId: ownerAcceptance.id};
	const defaultData = useSpaceLocationForEntities(objectId, ownerAcceptance.links?.spaceId);
	const [dialog, open] = useAddingProblemDialog(
		objectId,
		links,
		ObjectStageIds.TRANSFER,
		ProcessIds.UNITS_HANDOVER,
		undefined,
		defaultData
	);

	const handleClick = useCallback(() => {
		if (ownerAcceptance.links?.spaceId) {
			void queryClient.invalidateQueries({
				queryKey: spacesQueryKeys.detail(ownerAcceptance.links.spaceId)
			});
		}
		open();
	}, [ownerAcceptance, open]);

	return (
		<>
			<BeforeDesktop>
				<FloatingActionButton
					icon={mobileAddIcon}
					label={t('ownerAcceptancePage.actions.entityAdding.floatingActionButton.label')}
					minifyOnScroll
					scrollContainer="scroll-container"
					onClick={handleClick}
				/>
			</BeforeDesktop>
			<Desktop>
				<IconButton
					type="accent-blue"
					onClick={handleClick}
				>
					{desktopAddIcon}
				</IconButton>
			</Desktop>
			{dialog}
		</>
	);
};

export default EntityAdding;

import {ICustomField, IFieldSetting} from '@tehzor/tools/interfaces/fields';
import {
	ActionButtons,
	Button,
	Dialog,
	EditableFieldLabel,
	Select2 as Select,
	SelectOption,
	SelectPopup,
	TextFieldWithForwardedRef
} from '@tehzor/ui-components';
import SelectSearch, {
	flatFilter
} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {memo, useCallback, useMemo, useState} from 'react';
import {useUpdateEffect} from 'react-use';
import {useController, useFormContext} from 'react-hook-form';
import {SetValueOptions} from '../../ProblemForm';

interface ICustomSelectFieldProps {
	field: ICustomField;
	setting: IFieldSetting;
	isMobile?: boolean;
	disabled?: boolean;
	onSetValue?: (name: string, value: unknown, options?: SetValueOptions) => void;
	name: string;
	errorMessage?: string;
	required?: boolean;
}

const getIcon = (icon?: string) =>
	icon ? <i className={icon} /> : <i className="tz-simple-arrow-20" />;

const CusomSelectFieldComponent = ({
	field,
	setting,
	isMobile,
	disabled,
	onSetValue,
	name,
	errorMessage,
	required
}: ICustomSelectFieldProps) => {
	const {control} = useFormContext();

	// обязательное требование заполнения снимается если нет вариантов на выбор
	const isException = !field.values || field.values.length === 0;

	const {
		field: {value},
		fieldState: {invalid}
	} = useController({
		name,
		control,
		rules: {required: isException ? !isException : required}
	});

	const [checked, setChecked] = useState<string | undefined | null>(value);
	const [isOpen, setOpen] = useState(false);

	const [search, setSearch] = useState('');

	const clearSearch = useCallback(() => setSearch(''), []);

	useUpdateEffect(() => setChecked(value), [value]);

	const open = useCallback(() => setOpen(true), []);
	const close = useCallback(() => setOpen(false), []);

	const items = useMemo(() => field.values?.map(val => ({id: val})), [field]);
	const isSearchShown = (items?.length || 0) >= 8;

	const filteredItems = useMemo(() => flatFilter(items || [], 'id', search), [items, search]);

	const onConfirm = useCallback(
		(val?: string | null) => {
			if (onSetValue) {
				onSetValue(name, val);
			}
		},
		[name, onSetValue]
	);

	const handleCancel = useCallback(() => {
		setChecked(value);
		if (isMobile) {
			close();
		}
		clearSearch();
	}, [value, isMobile, close, clearSearch]);

	const handleConfirm = useCallback(() => {
		onConfirm(checked);
		if (isMobile) {
			close();
		}

		clearSearch();
	}, [checked, onConfirm, isMobile, clearSearch, close]);

	const handleClearButton = useCallback(() => {
		onConfirm(null);
		clearSearch();
	}, [onConfirm, clearSearch]);

	const trigger = (
		<TextFieldWithForwardedRef
			elementType="div"
			value={value}
			icon={getIcon(field.icon)}
			disabled={!items?.length || disabled}
			onClearClick={handleClearButton}
			onClick={isMobile ? open : undefined}
			cleanable={!!value}
			error={invalid ? errorMessage : undefined}
		/>
	);

	const content = (
		<Select
			value={checked || undefined}
			onChange={setChecked}
		>
			{filteredItems?.map(item => (
				<SelectOption
					key={item.id}
					itemKey={item.id}
					content={item.id}
					inputType="radio"
				/>
			))}
		</Select>
	);

	const selectSearch = isSearchShown ? (
		<SelectSearch
			value={search}
			onChange={setSearch}
			onClear={clearSearch}
		/>
	) : null;

	const footer = (
		<div>
			<ActionButtons>
				<Button
					type="cancel"
					label="Отменить"
					onClick={handleCancel}
				/>
				<Button
					type="accent-blue"
					label="Сохранить"
					onClick={handleConfirm}
				/>
			</ActionButtons>
		</div>
	);

	return (
		<div>
			<EditableFieldLabel>
				{setting.required ? `${field.name} *` : field.name}
			</EditableFieldLabel>

			{!isMobile ? (
				<SelectPopup
					dataTestId={`SelectField${field.id}SelectPopup`}
					noHeader={!isSearchShown}
					search={selectSearch}
					footer
					onCancel={handleCancel}
					onApply={handleConfirm}
					trigger={trigger}
				>
					{content}
				</SelectPopup>
			) : (
				trigger
			)}
			{isMobile && (
				<Dialog
					dataTestId={`SelectField${field.id}Dialog`}
					isOpen={isOpen}
					fullScreenOnMobile
					title={setting.required ? `${field.name} *` : field.name}
					onRequestClose={close}
					footer={footer}
				>
					<div>{selectSearch}</div>
					{content}
				</Dialog>
			)}
		</div>
	);
};

export const CustomSelectField = memo(
	CusomSelectFieldComponent
) as typeof CusomSelectFieldComponent;

import {
	IEditableEntityAction,
	IEditableEntityState,
	isEntityEdited
} from '@tehzor/tools/core/states/editableEntityState';
import IAttachment from '@tehzor/tools/interfaces/IAttachment';
import {ISavingWarrantyClaim} from '@src/interfaces/saving/ISavingWarrantyClaim';
import {IWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaim';
import IObjectFieldSetting from '@tehzor/tools/interfaces/objects/IObjectFieldSetting';
import {isEqual} from 'lodash';

export type IEditableWarrantyClaimState = IEditableEntityState<{
	spaceOwnerId?: string | null;
	replyEmail: string;
	replyPhone: string;
	claimerName: string;
	registrationDate?: number;
	attachments: IAttachment[];
}>;

export type IEditableWarrantyClaimAction = IEditableEntityAction<
	IEditableWarrantyClaimState,
	IWarrantyClaim
>;

const makeEmptyState = (): IEditableWarrantyClaimState => ({
	spaceOwnerId: undefined,
	replyEmail: '',
	replyPhone: '',
	claimerName: '',
	attachments: [],
	customFields: {},
	errors: {
		spaceOwnerId: false,
		replyEmail: false,
		replyPhone: false,
		claimerName: false,
		attachments: false,
		customFields: {}
	}
});

export const init = (acceptance?: IWarrantyClaim): IEditableWarrantyClaimState => {
	const empty = makeEmptyState();
	return acceptance
		? {
				spaceOwnerId: acceptance.spaceOwner?.id || undefined,
				replyEmail: acceptance.replyEmail || empty.replyEmail,
				replyPhone: acceptance.replyPhone || empty.replyPhone,
				claimerName: acceptance.claimerName || empty.claimerName,
				registrationDate: acceptance.registrationDate || empty.registrationDate,
				attachments: acceptance.attachments || empty.attachments,
				customFields: acceptance.customFields || empty.customFields,
				errors: empty.errors
		  }
		: empty;
};

const isSpaceOwnerEdited = (state: IEditableWarrantyClaimState, original?: IWarrantyClaim) =>
	original?.spaceOwner ? original.spaceOwner.id !== state.spaceOwnerId : !!state.spaceOwnerId;

const isReplyEmailEdited = (state: IEditableWarrantyClaimState, original?: IWarrantyClaim) =>
	original?.replyEmail ? original.replyEmail !== state.replyEmail : !!state.replyEmail;

const isReplyPhoneEdited = (state: IEditableWarrantyClaimState, original?: IWarrantyClaim) =>
	original?.replyPhone ? original.replyPhone !== state.replyPhone : !!state.replyPhone;

const isClaimerNameEdited = (state: IEditableWarrantyClaimState, original?: IWarrantyClaim) =>
	original?.claimerName ? original.claimerName !== state.claimerName : !!state.claimerName;

const areAttachmentsEdited = (state: IEditableWarrantyClaimState, original?: IWarrantyClaim) =>
	original?.attachments ? original.attachments.length !== state.attachments.length : false;

const isRegistrationDateEdited = (state: IEditableWarrantyClaimState, original?: IWarrantyClaim) =>
	original?.registrationDate
		? original.registrationDate !== state.registrationDate
		: !!state.registrationDate;

const areCustomFieldsEdited = (state: IEditableWarrantyClaimState, original?: IWarrantyClaim) =>
	!isEqual(state.customFields, original?.customFields);

/**
 * Возвращает значение, показывающее были ли отредактированы поля
 *
 * @param state состояние
 * @param original изначальные данные
 */
export const isEdited = (state: IEditableWarrantyClaimState, original?: IWarrantyClaim): boolean =>
	isEntityEdited(
		state,
		original,
		isSpaceOwnerEdited,
		isReplyEmailEdited,
		isReplyPhoneEdited,
		isClaimerNameEdited,
		areAttachmentsEdited,
		isRegistrationDateEdited,
		areCustomFieldsEdited
	);

/**
 * Функции проверки полей на ошибки
 */

const regEmail = /^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/;
const regPhone = /^(\+)?((\d{2,3}) ?\d|\d)(([ -]?\d)|( ?(\d{2,3}) ?)){5,12}\d$/;

export const errorsFns = {
	spaceOwnerId: (state: IEditableWarrantyClaimState) => !state.spaceOwnerId,
	replyEmail: (state: IEditableWarrantyClaimState) =>
		!!state.replyEmail && !(state.replyEmail && regEmail.test(state.replyEmail)),
	replyPhone: (state: IEditableWarrantyClaimState) =>
		!!state.replyPhone && !(state.replyPhone && regPhone.test(state.replyPhone)),
	claimerName: (state: IEditableWarrantyClaimState) => !state.claimerName,
	attachments: (state: IEditableWarrantyClaimState) => !state.attachments.length,
	customFields: (state: IEditableWarrantyClaimState, key?: string) => {
		if (!key) {
			return false;
		}
		const value = state.customFields?.[key];
		if (Array.isArray(value)) {
			return !value.length;
		}
		return !value;
	}
};

/**
 * Проверяет, есть ли ошибка в сохраненных вложениях
 *
 * @param state состояние
 * @param settings настройки полей
 */
export const hasAttachmentsError = (
	state: IEditableWarrantyClaimState,
	settings: {[k: string]: IObjectFieldSetting}
) => settings.attachments?.isRequired && errorsFns.attachments(state);

/**
 * Конвертирует данные в формат, пригодный для отправки на сервер
 *
 * @param state состояние
 * @param original изначальные данные
 * @param onlyEdited возвращать только изменённые поля
 */
export const convertToSave = (
	state: IEditableWarrantyClaimState,
	original?: IWarrantyClaim,
	onlyEdited?: boolean
): ISavingWarrantyClaim => {
	if (!onlyEdited) {
		return {
			spaceOwnerId: state.spaceOwnerId,
			replyEmail: state.replyEmail,
			replyPhone: state.replyPhone,
			claimerName: state.claimerName,
			attachments: state.attachments,
			registrationDate: state.registrationDate,
			customFields: state.customFields
		};
	}
	const acceptance: ISavingWarrantyClaim = {};
	if (isSpaceOwnerEdited(state, original)) {
		acceptance.spaceOwnerId = state.spaceOwnerId;
	}
	if (isReplyEmailEdited(state, original)) {
		acceptance.replyEmail = state.replyEmail;
	}
	if (isReplyPhoneEdited(state, original)) {
		acceptance.replyPhone = state.replyPhone;
	}
	if (isClaimerNameEdited(state, original)) {
		acceptance.claimerName = state.claimerName;
	}
	if (areAttachmentsEdited(state, original)) {
		acceptance.attachments = state.attachments.map(item => ({id: item.id}));
	}
	if (isRegistrationDateEdited(state, original)) {
		acceptance.registrationDate = state.registrationDate;
	}
	if (areCustomFieldsEdited(state, original)) {
        acceptance.customFields = state.customFields;
    }
	return acceptance;
};

import {createReducer} from '@reduxjs/toolkit';
import config from '@src/core/config';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import {
	IAddProblemCommentPayload,
	IDeleteProblemCommentPayload
} from '@src/store/modules/entities/problemComment/actions';
import * as commentTypes from '@src/store/modules/entities/problemComment/constants';
import * as replyTypes from '@src/store/modules/entities/problemReply/constants';
import {
	ILoadableEntitiesState,
	handleLoadableEntitiesGetting
} from '@tehzor/tools/core/storeHelpers/reducers/loadable';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IMarkerStory} from '@tehzor/tools/interfaces/markers/IMarkerStory';
import {IProblemStory} from '@tehzor/tools/interfaces/problemStories/IProblemStory';
import {ILinkedProblem} from '@tehzor/tools/interfaces/problems/ILinkedProblem';
import {
	getLoadedEntitiesInitialState,
} from '@tehzor/tools/utils/reducersHandlers';
import {
	IDeleteProblemPayload,
	IEditProblemPayload,
	IGetProblemPayload,
	IGetProblemRequestPayload
} from './actions';
import {IGetProblemCommentsPayload} from './actions/comments/get';
import {IGetProblemCommentsStoriesPayload} from './actions/commentsStories';
import {IEditProblemInspectorsPayload} from './actions/editInspectors';
import {IEditProblemPerformersPayload} from './actions/editPerformers';
import {IGetProblemMarkersStoriesPayload} from './actions/markersStories';
import {IGetProblemStoriesPayload} from './actions/stories';
import * as types from './constants';
import IComment from '@tehzor/tools/interfaces/comments/IComment';
import {ICommentStory} from '@tehzor/tools/interfaces/comments/ICommentStory';

const {entitiesCacheTime} = config;

export interface IProblemState {
	data?: ILinkedProblem;
	expires?: number;
	comments: INormalizedData<IComment> & {
		offset: number;
		limit: number;
		total: number;
		hasMore: boolean;
	};
	stories: ILoadableEntitiesState<IProblemStory>;
	commentsStories: ILoadableEntitiesState<ICommentStory>;
	markersStories: ILoadableEntitiesState<IMarkerStory>;
}

const getInitialState = () =>
	({
		expires: undefined,
		comments: {
			byId: {},
			allIds: [],
			offset: 0,
			limit: 20,
			total: 0,
			hasMore: false
		},
		stories: {
			byId: {},
			allIds: [],
			loaded: false
		},
		commentsStories: {
			byId: {},
			allIds: [],
			loaded: false
		},
		markersStories: {
			byId: {},
			allIds: [],
			loaded: false
		},
	}) as IProblemState;

const isAnotherProblem = (state: IProblemState, payload: IEditProblemPayload) =>
	!(state.data?.id === payload.id);

const updateProblem = (state: IProblemState, {payload}: {payload: IEditProblemPayload}) =>
	isAnotherProblem(state, payload)
		? state
		: {
				...state,
				data: {
					check: state.data?.check,
					space: state.data?.space,
					ownerAcceptance: state.data?.ownerAcceptance,
					warrantyClaim: state.data?.warrantyClaim,
					template: state.data?.template,
					location: state.data?.location,
					...payload
				},
				stories: {
					...state.stories,
					loaded: false
				},
				commentsStories: {
					...state.commentsStories,
					loaded: false
				},
				markersStories: {
					...state.markersStories,
					loaded: false
				},
				expires: Date.now() + entitiesCacheTime * 1000,
		  };

/**
 * Находит позицию для вставки нового комментария,
 * с учётом того, что они должны быть отсортированы по дате добавления
 */
const findCommentPlace = (state: IProblemState, comment: IComment) => {
	for (let i = state.comments.allIds.length - 1; i >= 0; i--) {
		const item = state.comments.byId[state.comments.allIds[i]];
		if (item.createdAt && comment.createdAt && item.createdAt <= comment.createdAt) {
			return i;
		}
	}
	return 0;
};

const handleCommentAdd = (
	state: IProblemState,
	{payload}: {payload: IAddProblemCommentPayload}
) => {
	state.comments.byId[payload.id] = payload;
	const index = findCommentPlace(state, payload);
	if (index === state.comments.allIds.length - 1 || index === state.comments.allIds.length) {
		state.comments.allIds.push(payload.id);
	} else {
		state.comments.allIds.splice(index, 0, payload.id);
	}
	state.comments.offset += 1;
	state.comments.total += 1;
	// TODO Удалить после реализации фонового обновления
	if (payload.official) {
		state.expires = undefined;
	}
};

const handleCommentChange = (state: IProblemState, {payload}: {payload: IComment}) => {
	if (state.comments.allIds.includes(payload.id)) {
		state.comments.byId[payload.id] = payload;
	}
};

const handleCommentDelete = (
	state: IProblemState,
	{payload}: {payload: IDeleteProblemCommentPayload}
) => {
	if (state.comments.allIds.includes(payload.commentId)) {
		state.comments.allIds = state.comments.allIds.filter(id => id !== payload.commentId);
		delete state.comments.byId[payload.commentId];
	}
};

export default createReducer<IProblemState>(getInitialState(), {
	[types.GET_REQUEST]: (state, {payload}: {payload: IGetProblemRequestPayload}) => {
		if (state.data?.id === payload.problemId) {
			return {...state, data: undefined};
		}
		return getInitialState();
	},
	[types.GET_SUCCESS]: (state, {payload}: {payload: IGetProblemPayload}) => {
		state.data = payload;
		state.expires = Date.now() + entitiesCacheTime * 1000;
	},
	[types.GET_FAILURE]: getInitialState,
	[types.EDIT_SUCCESS]: updateProblem,
	[types.EDIT_STATUS_SUCCESS]: updateProblem,
	[types.EDIT_PERFORMERS_SUCCESS]: (
		state,
		{payload}: {payload: IEditProblemPerformersPayload}
	) => {
		const newState = updateProblem(state, {payload});
		return newState;
	},
	[types.EDIT_INSPECTORS_SUCCESS]: (
		state,
		{payload}: {payload: IEditProblemInspectorsPayload}
	) => {
		const newState = updateProblem(state, {payload});
		return newState;
	},
	[types.DELETE_SUCCESS]: (state, {payload}: {payload: IDeleteProblemPayload}) => {
		if (!(state.data?.id === payload.problemId)) {
			return state;
		}
		return getInitialState();
	},
	[types.GET_COMMENTS_REQUEST]: state => {
		state.comments.hasMore = false;
	},
	[types.GET_COMMENTS_SUCCESS]: (state, {payload}: {payload: IGetProblemCommentsPayload}) => {
		state.comments.byId = {...state.comments.byId, ...payload.byId};
		state.comments.allIds = Array.from(
			new Set([...payload.allIds.reverse(), ...state.comments.allIds])
		);
		state.comments.offset = payload.offset + payload.limit;
		state.comments.limit = payload.limit;
		state.comments.total = payload.total;
		state.comments.hasMore = payload.total > payload.offset + payload.limit;
	},
	[types.RESET_COMMENTS]: state => {
		state.comments = getInitialState().comments;
	},
	[types.GET_STORIES_REQUEST]: state => {
		state.stories = getLoadedEntitiesInitialState<IProblemStory>();
	},
	[types.GET_STORIES_SUCCESS]: (state, action: {payload: IGetProblemStoriesPayload}) => {
		state.stories = handleLoadableEntitiesGetting<IProblemStory>()(state.stories, action);
	},
	[types.GET_COMMENTS_STORIES_REQUEST]: state => {
		state.commentsStories = getLoadedEntitiesInitialState<ICommentStory>();
	},
	[types.GET_COMMENTS_STORIES_SUCCESS]: (
		state,
		action: {payload: IGetProblemCommentsStoriesPayload}
	) => {
		state.commentsStories = handleLoadableEntitiesGetting<ICommentStory>()(
			state.commentsStories,
			action
		);
	},
	[types.GET_MARKERS_STORIES_REQUEST]: state => {
		state.markersStories = getLoadedEntitiesInitialState<IMarkerStory>();
	},
	[types.GET_MARKERS_STORIES_SUCCESS]: (
		state,
		action: {payload: IGetProblemMarkersStoriesPayload}
	) => {
		state.markersStories = handleLoadableEntitiesGetting<IMarkerStory>()(
			state.markersStories,
			action
		);
	},
	[replyTypes.ADD_SUCCESS]: handleCommentAdd,
	[replyTypes.EDIT_SUCCESS]: handleCommentChange,
	[replyTypes.DELETE_SUCCESS]: handleCommentDelete,
	[commentTypes.ADD_SUCCESS]: handleCommentAdd,
	[commentTypes.EDIT_SUCCESS]: handleCommentChange,
	[commentTypes.DELETE_SUCCESS]: handleCommentDelete,
	[CLEAR_STORE]: getInitialState
});

import {ChangeEvent, useCallback} from 'react';
import './CriticalCheckbox.less';
import {Checkbox} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';
import {useController, useFormContext} from 'react-hook-form';
import {SetValueOptions} from '../ProblemForm';

interface ICriticalCheckboxProps {
	plannedFixDateValue?: number | null;
	disabled?: boolean;
	name?: string;
	plannedFixDateName?: string;
	onSetValue?: (name: string | undefined, value: unknown, options?: SetValueOptions) => void;
	required?: boolean;
}
export const CriticalCheckbox = (props: ICriticalCheckboxProps) => {
	const {plannedFixDateValue, disabled, name, onSetValue, plannedFixDateName, required} = props;
	const {t} = useTranslation();
	const {control} = useFormContext();
	const {
		field: {value}
	} = useController({
		name: name || 'critical',
		control,
		rules: {required}
	});
	const handleChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			const checked = e.target.checked;
			if (onSetValue) {
				onSetValue(name, checked);
				if (checked) {
					onSetValue(plannedFixDateName, Date.now() + 86400000); // +24 часа
				} else {
					onSetValue(plannedFixDateName, plannedFixDateValue);
				}
			}
		},
		[onSetValue, name, plannedFixDateName, plannedFixDateValue]
	);
	return (
		<Checkbox
			className="editable-critical-checkbox"
			checked={value}
			onChange={handleChange}
			disabled={disabled}
		>
			<span className="editable-critical-checkbox__label">
				{t('components.editableFields.critical.label')}
			</span>
			<span className="editable-critical-checkbox__explanation">
				{t('components.editableFields.critical.explanation')}
			</span>
		</Checkbox>
	);
};

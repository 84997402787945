import {useCallback} from 'react';
import {CellProps} from 'react-table';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import ClickPreventWrap from '@src/components/tableCells/ClickPreventWrap';
import {IListWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IListWorkAcceptance';
import {StatusSelect} from '@tehzor/ui-components';
import {useExtractWorkAcceptanceStatuses} from '@src/core/hooks/queries/workAcceptanceStatuses/hooks';
import {editWorkAcceptanceStatus} from '@src/store/modules/entities/workAcceptance/actions';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import {useTranslatedDictionary} from '@src/core/hooks/translations/useTranslatedDictionary';
import {WorkAcceptanceStatusIds} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceStatus';

interface WorkAcceptanceStatusProps {
	workAcceptance: IListWorkAcceptance;
}

export const WorkAcceptanceStatus = ({workAcceptance}: WorkAcceptanceStatusProps) => {
	const {objectId, id} = workAcceptance;

	const dispatch = useAppDispatch();

	const {data: statuses} = useExtractWorkAcceptanceStatuses();
	const translatedStatuses = useTranslatedDictionary(
		dictionaryKeys.workAcceptanceStatuses,
		statuses
	);

	const onChange = useCallback(
		async (value: WorkAcceptanceStatusIds) => {
			await dispatch(editWorkAcceptanceStatus(objectId, id, value));
		},
		[objectId, id, dispatch]
	);

	return (
		<ClickPreventWrap>
			<StatusSelect
				className="work-acceptance-page__d-entities-status"
				statuses={translatedStatuses}
				disabled
				hideArrow
				value={workAcceptance?.status}
				onChange={onChange}
			/>
		</ClickPreventWrap>
	);
};

export const WorkAcceptanceStatusCell = ({row}: CellProps<IListWorkAcceptance>) => (
	<WorkAcceptanceStatus workAcceptance={row.original} />
);

export const WorkAcceptanceStatusCellWrap = ({row}: CellProps<{data: IListWorkAcceptance}>) =>
	row.original.data ? <WorkAcceptanceStatus workAcceptance={row.original.data} /> : null;

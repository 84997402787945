import {memo, useCallback, useEffect, useRef, useState} from 'react';
import './AddingOwnerAcptDialog.less';
import {Dialog} from '@tehzor/ui-components';
import {IOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptance';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IAddingAcceptanceRefProps} from './components/AddingAcceptance';
import {IAddingProblemRefProps} from '../AddingProblem';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {ISavingProblem} from '@src/interfaces/saving/ISavingProblem';
import EntitySelectContainer from '@tehzor/ui-components/src/components/containers/EntitySelectContainer/EntitySelectContainer';
import {useOwnerAcceptanceMaps} from './hooks/useOwnerAcceptanceMaps';
import {saveOwnerAcceptance} from './utils/saveOwnerAcceptance';
import {useLocalOwnerAcceptances} from '@src/core/hooks/queries/ownerAcceptances/useGetOwnerAcceptances';
import {queryClient} from '@src/api/QueryClient';
import {ownerAcceptancesQueryKeys} from '@src/api/cache/ownerAcceptances/keys';
import {useTranslation} from 'react-i18next';
import {useTranslatedObjectStagesArray} from '@src/core/hooks/translations/useTranslatedObjectStagesArray';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';
import {
	useEntitySelectContainerSubTitle,
	useEntitySelectContainerSubSubTitle
} from '@src/core/hooks/getEntitySelectContainerSubTitle';
import {useObject} from '@src/core/hooks/queries/objects/hooks';
import {useSpace} from '@src/core/hooks/queries/space';
import {extractFastAdding} from '@src/store/modules/settings/modalDialog/selectors';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {ModalDialogButtons} from '../ModalDialogButtons';
import {modalDialogActions} from '@src/store/modules/settings/modalDialog/slice';

export type AddingOwnerAcceptanceView = 'acceptance' | 'problem' | undefined;

interface IAddingOwnerAcceptanceDialogProps {
	objectId: string;
	spaceId?: string;
	acceptanceId?: string;
	links?: IOwnerAcceptance['links'];
	defaultProblemData?: ISavingProblem;
	isOpen: boolean;
	title: string;

	onClose: () => void;
	onSuccess?: () => void | Promise<void>;
}

const addingOwnerAcptDialogClassNames = {
	root: 'adding-owner-acpt-dialog',
	header: 'adding-owner-acpt-dialog__header',
	body: 'adding-owner-acpt-dialog__body'
};

const AddingOwnerAcptDialog = (props: IAddingOwnerAcceptanceDialogProps) => {
	const {
		objectId,
		spaceId,
		acceptanceId,
		links,
		defaultProblemData,
		isOpen,
		onClose,
		onSuccess,
		title
	} = props;
	const {t} = useTranslation();
	const [selectedOwnerAcceptanceId, setSelectedOwnerAcceptanceId] = useState<string | undefined>(
		acceptanceId
	);
	const dispatch = useAppDispatch();
	const {resetFastAddingMode} = modalDialogActions;

	const isFastAddingActive = useAppSelector(extractFastAdding);
	const addingEntityRef = useRef<IAddingProblemRefProps | IAddingAcceptanceRefProps>(null);
	const {data: object} = useObject(objectId);
	const {data: space} = useSpace(links?.spaceId, objectId);
	const [view, setView] = useState<AddingOwnerAcceptanceView>();
	const [saving, setSaving] = useState(false);
	const success = useRef<boolean>(false);

	const online = useAppSelector(s => s.offlineMode.networkStatus);

	const localOwnerAcceptances = useLocalOwnerAcceptances();
	const localOwnerAcceptancesIds = localOwnerAcceptances.map(acp => acp.id);

	useEffect(() => {
		setView(!selectedOwnerAcceptanceId ? 'acceptance' : 'problem');
	}, [selectedOwnerAcceptanceId]);

	const stage = ObjectStageIds.TRANSFER;
	const processId = ProcessIds.UNITS_HANDOVER;
	const stages = useTranslatedObjectStagesArray();
	const getSubTitle = useEntitySelectContainerSubTitle();
	const getSubSubTitle = useEntitySelectContainerSubSubTitle();
	const [contentMap, entitiesSelectMapProps] = useOwnerAcceptanceMaps({
		objectId,
		acceptanceId,
		spaceId,
		stage,
		processId,
		links,
		view,
		addingEntityRef,
		saving,
		setSaving,
		selectedOwnerAcceptanceId,
		setSelectedOwnerAcceptanceId,
		defaultProblemData,
		onClose
	});

	const handleSave = useCallback(async () => {
		const updateLatest = async () => {
			if (
				selectedOwnerAcceptanceId &&
				!localOwnerAcceptancesIds.includes(selectedOwnerAcceptanceId)
			) {
				await queryClient.invalidateQueries({
					queryKey: ownerAcceptancesQueryKeys.detail(selectedOwnerAcceptanceId)
				});
			}
		};

		await saveOwnerAcceptance({
			addingEntityRef,
			localOwnerAcceptancesIds,
			view,
			online,
			updateLatest,
			selectedOwnerAcceptanceId,
			success,
			onClose,
			setSaving,
			isFastAddingActive
		});
		if (success.current && onSuccess) {
			success.current = false;
			void onSuccess();
		}
	}, [
		localOwnerAcceptancesIds,
		onClose,
		onSuccess,
		online,
		selectedOwnerAcceptanceId,
		view,
		isFastAddingActive
	]);

	const handleCancel = useCallback(() => {
		if (addingEntityRef.current) {
			addingEntityRef.current.cancel();
		} else {
			onClose();
		}
		dispatch(resetFastAddingMode());
	}, [onClose, dispatch, resetFastAddingMode]);

	return (
		<Dialog
			className={addingOwnerAcptDialogClassNames}
			isOpen={isOpen}
			title={title}
			footer={
				<ModalDialogButtons
					saving={saving}
					handleCancel={handleCancel}
					handleSave={handleSave}
					isProblemType={view === 'problem'}
				/>
			}
			fullScreenOnTablet
			onRequestClose={handleCancel}
		>
			{stages && (
				<EntitySelectContainer
					contentMap={contentMap}
					entitiesSelectMapProps={entitiesSelectMapProps}
					selectedStage={stage}
					selectedProcess={processId}
					stagesSelectDialogTitle={t(
						'globalAddingEntityDialog.entitySelectContainer.processSelectDialogTitle'
					)}
					stageTitle={t(
						'globalAddingEntityDialog.entitySelectContainer.processSelectDialogStageTitle'
					)}
					stagesSelectDialogSaveLabel={t('actionButtons.button.save')}
					stagesSelectDialogCancelLabel={t('actionButtons.button.cancel')}
					stages={stages}
					getEntitySelectContainerSubTitle={getSubTitle}
					getEntitySelectContainerSubSubTitle={getSubSubTitle}
					objectName={object?.name}
					spaceTypeId={space?.type}
					spaceName={space?.name}
				/>
			)}
		</Dialog>
	);
};

export default memo(AddingOwnerAcptDialog);

import IInspection from '@tehzor/tools/interfaces/inspections/IInspection';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {ISavingInspection} from '@src/interfaces/saving/ISavingInspection';
import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';

export type IAddInspectionResponse = IInspection;

/**
 * Добавляет осмотр
 *
 * @param objectId id объекта
 * @param links ссылки на другие сущности
 * @param fields данные осмотра
 * @param processId id процесса
 * @param localId локальный id
 * @param stage стадия объекта

 */
export const makeInspectionAddRequest = async (
	objectId: string,
	links: IInspection['links'] | undefined,
	fields: ISavingInspection,
	processId: ProcessIds,
	localId?: string,
	stage: ObjectStageIds = ObjectStageIds.BUILDING
) => {
	const response = await httpRequests.withToken.post<IAddInspectionResponse>('inspections', {
		objectId,
		links,
		stage,
		processId,
		localId,
		...fields
	});

	return response.data;
};

import {Dispatch, ReactNode, RefObject, SetStateAction} from 'react';
import AddingInspection, {IAddingInspectionRefProps} from '@src/components/AddingInspection';
import AddingProblem, {IAddingProblemRefProps} from '@src/components/AddingProblem';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IEntitySelectProps} from '@tehzor/ui-components/src/components/containers/EntitySelectContainer/components/EntitySelect/EntitySelect';
import {EntityTypeSelect} from '../components/EntityTypeSelect';
import {useTranslation} from 'react-i18next';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';
import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';
import IInspection from '@tehzor/tools/interfaces/inspections/IInspection';
import {IWorkAcceptanceAddingEntityType} from '@src/store/modules/settings/workAcceptanceAdding/interfaces';

interface IUseWorkAcceptanceMapsProps {
	objectId: string;
	workAcceptanceId?: string;
	stage: ObjectStageIds;
	processId: ProcessIds;
	links?: IProblem['links'] | IInspection['links'];
	availableTypes: IWorkAcceptanceAddingEntityType[];
	type?: IWorkAcceptanceAddingEntityType;
	addingEntityRef: RefObject<IAddingProblemRefProps | IAddingInspectionRefProps>;
	saving: boolean;
	setSaving: Dispatch<SetStateAction<boolean>>;
	selectedCheckId?: string;
	onClose: () => void;
}

export const useWorkAcceptanceMaps = (
	props: IUseWorkAcceptanceMapsProps
): [
	Record<ObjectStageIds, Record<ProcessIds, ReactNode>>,
	Record<ObjectStageIds, Record<ProcessIds, IEntitySelectProps>>
] => {
	const {
		objectId,
		workAcceptanceId,
		stage,
		processId,
		links,
		availableTypes,
		type,
		addingEntityRef,
		saving,
		setSaving,
		onClose
	} = props;
	const {t} = useTranslation();
	const contentMap = {} as Record<ObjectStageIds, Record<ProcessIds, ReactNode>>;
	const entitiesSelectMapProps = {} as Record<
		ObjectStageIds,
		Record<ProcessIds, IEntitySelectProps>
	>;

	contentMap[stage] = {} as Record<ProcessIds, ReactNode>;
	contentMap[stage][processId] = (
		<>
			<div className="adding-work-acceptance-dialog__title">
				{type !== undefined && (
					<EntityTypeSelect
						type={type}
						types={availableTypes}
						disabled={saving}
					/>
				)}
			</div>
			{type === 'problem' ? (
				<AddingProblem
					objectId={objectId}
					links={links}
					stage={stage}
					processId={processId}
					saving={saving}
					onClose={onClose}
					setSaving={setSaving}
					ref={addingEntityRef}
				/>
			) : type === 'inspection' ? (
				<AddingInspection
					objectId={objectId}
					links={links}
					stage={stage}
					processId={processId}
					saving={saving}
					onClose={onClose}
					setSaving={setSaving}
					ref={addingEntityRef}
				/>
			) : null}
		</>
	);

	entitiesSelectMapProps[stage] = {} as Record<ProcessIds, IEntitySelectProps>;
	entitiesSelectMapProps[stage][processId] = {
		name: t('addingWorkAcceptanceDialog.useWorkAcceptanceMaps.building.name'),
		newName: t('addingWorkAcceptanceDialog.useWorkAcceptanceMaps.building.newName'),
		selected: workAcceptanceId,
		onEntityChange: () => {},
		withoutNew: true
	};

	return [contentMap, entitiesSelectMapProps];
};

import {ISavingProblem} from '@src/interfaces/saving/ISavingProblem';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';
import {useCallback, useState} from 'react';
import * as React from 'react';
import AddingProblemDialog from '../AddingProblemDialog';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';

export function useAddingProblemDialog(
	objectId: string,
	links: IProblem['links'] | undefined,
	stage: ObjectStageIds,
	processId: ProcessIds,
	scope?: string,
	defaultData?: ISavingProblem,
	onSuccess?: () => void | Promise<void>,
	header?: React.ReactElement
): [React.ReactNode, () => void] {
	const [isOpen, setOpen] = useState<boolean>(false);

	const open = useCallback(() => {
		setOpen(true);
	}, []);

	const close = useCallback(() => {
		setOpen(false);
	}, []);

	const dialog = (
		<AddingProblemDialog
			objectId={objectId}
			links={links}
			stage={stage}
			processId={processId}
			scope={scope}
			defaultData={defaultData}
			isOpen={isOpen}
			onClose={close}
			onSuccess={onSuccess}
			header={header}
		/>
	);

	return [dialog, open];
}

import {combineReducers} from 'redux';
import checkAdding from './checkAdding/reducers';
import locationSelect from './locationSelect/reducers';
import {offlineMode} from './offlineMode/reducers';
import pages from './pages';
import photoViewer from './photoViewer/reducers';
import sidebar from './sidebar/reducers';
import exportReducer from '../../../reducers/settings/export';
import notifications from '../../../reducers/settings/notifications';
import {PersistedState, persistReducer} from 'redux-persist';
import {appVersion, formPersistConfig} from '@src/store/persistentStorage/formPersistConfig';
import {internalAcceptanceAdding} from './internalAcceptanceAdding/reducers';
import {workAcceptanceAddingReducer} from './workAcceptanceAdding/slice';
import createMigrate from 'redux-persist/es/createMigrate';
import {modalDialogReducer} from './modalDialog/slice';

const settingsReducer = combineReducers({
	checkAdding,
	locationSelect,
	offlineMode,
	pages,
	photoViewer,
	sidebar,
	export: exportReducer,
	notifications,
	internalAcceptanceAdding,
	workAcceptanceAdding: workAcceptanceAddingReducer,
	modalDialog: modalDialogReducer
});

const migrations = {
	[appVersion]: (state: ReturnType<typeof settingsReducer> & PersistedState) =>
		({
			offlineMode: state.offlineMode,
			notifications: state.notifications
		}) as unknown as PersistedState
};

const migrate = createMigrate(migrations, {debug: process.env.NODE_ENV === 'development'});

export const settings = persistReducer(
	formPersistConfig<ReturnType<typeof settingsReducer>>('settings', {migrate}),
	settingsReducer
);

import {useCallback, forwardRef, Ref, CSSProperties, ReactNode} from 'react';
import {EditableFieldLabel, TextField} from '@tehzor/ui-components';
import IReason from '@tehzor/tools/interfaces/IReason';
import {ElementPropsType} from '@tehzor/ui-components/src/components/inputs/TextField/TextField';

export interface IReasonProps {
	className?: string;
	style?: CSSProperties;
	label?: string;
	select?: ReactNode;
	search?: ReactNode;
	value?: IReason | null;
	required?: boolean;
	disabled?: boolean;
	textAreaProps?: ElementPropsType;
	onChange?: (value: string) => void;
	errorMessage?: string;
}

export const Reason = forwardRef(
	(
		{
			className,
			style,
			label = 'Основание',
			value,
			select,
			search,
			disabled,
			textAreaProps,
			onChange,
			errorMessage
		}: IReasonProps,
		ref: Ref<HTMLTextAreaElement>
	) => {
		const handleChange = useCallback(
			(v: string) => {
				if (onChange) {
					onChange(String(v));
				}
			},
			[onChange]
		);

		return (
			<div
				className={className}
				style={style}
			>
				<EditableFieldLabel addon={select}>{label}</EditableFieldLabel>

				<TextField
					value={value?.value || ''}
					elementRef={ref}
					elementType="textarea"
					elementProps={textAreaProps}
					error={errorMessage}
					disabled={disabled}
					onChange={handleChange}
				/>

				{search}
			</div>
		);
	}
);

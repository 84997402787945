/**
 * Логика редактирования и сохранения нарушения (нового или существующего)
 */

import {
	convertToLocalSave as convertFilesToLocalSave,
	convertToSave as convertFilesToSave,
	someFilesHaveError,
	someFilesHaveSizeError,
	useUploadingFilesState,
	isEdited as isFilesExist
} from '@src/core/hooks/states/useUploadingFilesState';
import {useWorkAcceptance} from '@src/core/hooks/queries/workAcceptance';
import {useProblemSettings} from '@src/core/hooks/settings/useProblemSettings';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useNavigationPrevent} from '@src/core/hooks/useNavigationPrevent';
import {ISavingProblem} from '@src/interfaces/saving/ISavingProblem';
import {testProblemFieldsRestrictions} from '@src/utils/testRestrictions';
import ILocation from '@tehzor/tools/interfaces/ILocation';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';
import {useCallback, useEffect, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {ProblemForm} from '../components/ProblemForm';
import {IRuleParams} from '@tehzor/tools/utils/responsibilityRules/IRuleParams';
import {extractUserProfile} from '@src/store/modules/auth/profile/selectors';
import {useDefaultProblemData} from '../hooks/useDefaultProblemData';
import {IPreparedProblem} from '@src/pages/ProblemPage/interfaces/IPreparedProblem';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';

interface IEditableProblemFormProps {
	objectId: string;
	stage: ObjectStageIds;
	defaultData: ISavingProblem | undefined;
	saving: boolean;
	scope?: string | undefined;
	creating?: boolean;
	isCopying?: boolean;
	links?: IProblem['links'];
	isLoading?: boolean;
	problem?: IPreparedProblem;
	processId?: ProcessIds;
}

export interface IAddProblemForm extends ISavingProblem {
	templateId?: string;
	initialRuleParams?: IRuleParams;
}

export const useEditableProblemForm = ({
	objectId,
	stage,
	scope,
	saving,
	creating,
	isCopying,
	links,
	isLoading,
	defaultData,
	problem
}: IEditableProblemFormProps): {
	form: JSX.Element;
	isBlocking: boolean;
	getSavingData: (useLocalFiles?: boolean) => Promise<IAddProblemForm | undefined>;
	resetForm: () => void;
} => {
	const fieldsSettings = useProblemSettings(objectId, stage);
	const {
		createdBy,
		performers,
		performersActiveGroupLeader,
		inspectors,
		inspectorsActiveGroupLeader,
		watchers
	} = problem || {};
	const {builtin} = fieldsSettings;
	const user = useAppSelector(extractUserProfile);
	const [uploadingFilesState, uploadingFilesDispatch, waitUploading] = useUploadingFilesState();

	const {data: workAcceptance} = useWorkAcceptance(links?.workAcceptanceId, objectId);
	const [isBlocking, setIsBlocking] = useState(false);

	let entityLocation: ILocation | undefined;
	let entityPlanId: string | undefined;
	if (links?.structureId && links?.workAcceptanceId) {
		const selectedStructure = workAcceptance?.structures?.find(
			str => str.id === links?.structureId
		);
		entityLocation = selectedStructure?.location;
		entityPlanId = selectedStructure?.planId;
	}

	if (links?.spaceId && links?.workAcceptanceId) {
		const selectedSpaces = workAcceptance?.spaces?.find(space => space.id === links?.spaceId);
		entityLocation = selectedSpaces?.location;
		entityPlanId = selectedSpaces?.planId;
	}

	const defaultValues = useDefaultProblemData(defaultData, problem);

	const formMethods = useForm<IAddProblemForm>({
		defaultValues,
		mode: 'onChange'
	});

	const {formState, reset, trigger, getValues} = formMethods;
	// Получение информации были ли отредактированы поля.
	// Используется такой подход вместо свойства isDirty в formState из-за кастомных полей
	// Т.к. при редактировании формы и повторном ее открытии из-за вложенных объектов в customFields форма всегда помечается как dirty в formState.IsDirty
	const {dirtyFields} = formState;
	const isDirtyForm = Object.keys(dirtyFields).length > 0;

	useEffect(() => {
		setIsBlocking(isDirtyForm || isFilesExist(uploadingFilesState.value));
	}, [isDirtyForm, uploadingFilesState.value]);

	useNavigationPrevent(isBlocking);

	const resetForm = useCallback(() => {
		reset(defaultValues);
		uploadingFilesDispatch({type: 'reset'});
	}, [reset, defaultValues, uploadingFilesDispatch]);

	const getSavingData = useCallback(
		async (useLocalFiles?: boolean) => {
			const files = await waitUploading();

			// Проверка наличия ошибок в состояниях react-hook-form и вложений.
			// Для вложений отдельная проверка для uploadsFiles и attachments из react-hook-form (для случаев, если удаляем из attachments)
			const isValid = await trigger();

			const formData = getValues();
			const {initialRuleParams, ...restFormData} = formData;

			const isAttachmentsInvalid =
				builtin.attachments.required &&
				!uploadingFilesState.value.length &&
				!formData.attachments?.length;

			if (!isValid || isAttachmentsInvalid) {
				uploadingFilesDispatch({type: 'update-error'});
				return undefined;
			}

			// Проверка, есть ли файлы с превышением размера.
			// не даст сохранить нарушение в автономном режиме
			if (someFilesHaveSizeError(files)) {
				return undefined;
			}

			// Проверка, были ли отредактированы поля
			if (!isDirtyForm && !isCopying && (!isFilesExist(files) || someFilesHaveError(files))) {
				return undefined;
			}

			return {
				...restFormData,
				newAttachments: useLocalFiles
					? convertFilesToLocalSave(files)
					: convertFilesToSave(files)
			};
		},
		[
			waitUploading,
			uploadingFilesState,
			builtin,
			isCopying,
			uploadingFilesDispatch,
			isDirtyForm,
			getValues,
			trigger
		]
	);

	const fieldsAvailability =
		user.roles &&
		testProblemFieldsRestrictions(
			user.id,
			user.roles,
			'problemsEdit',
			objectId,
			createdBy?.id,
			performers,
			performersActiveGroupLeader,
			inspectors,
			inspectorsActiveGroupLeader,
			watchers,
			saving,
			creating
		);

	const form = (
		<FormProvider {...formMethods}>
			<ProblemForm
				objectId={objectId}
				stage={stage}
				scope={scope}
				createdBy={createdBy?.id}
				uploadingFilesState={uploadingFilesState}
				uploadingFilesDispatch={uploadingFilesDispatch}
				fieldsSettings={fieldsSettings}
				saving={saving}
				creating={creating}
				fieldsAvailability={fieldsAvailability}
				entityLocation={entityLocation}
				entityPlanId={entityPlanId}
				isLoading={isLoading}
				defaultValues={defaultValues}
			/>
		</FormProvider>
	);
	return {form, isBlocking, getSavingData, resetForm};
};

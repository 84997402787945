import {FixDateDisplayValue} from '@src/components/FixDateDisplayValue';
import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';
import {CellProps} from 'react-table';
import './ProblemDeadlineCell.less';

export const ProblemDeadlineCell = ({row}: CellProps<{data: IProblem}>) => {
	const {status, plannedFixDate, critical, actualFixDate} = row.original.data;

	return plannedFixDate ? (
		<FixDateDisplayValue
			className="problem-deadline-cell-date"
			plannedFixDate={plannedFixDate}
			status={status}
			critical={critical}
			actualFixDate={actualFixDate}
		/>
	) : null;
};

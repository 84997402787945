import {CellProps} from 'react-table';
import {IPreparedWorkAcceptance} from '@src/interfaces/IPreparedWorkAcceptance';
import {WorkAcceptanceFrontItem} from '@src/components/WorkAcceptanceFrontItem/WorkAcceptanceFrontItem';
import ClickPreventWrap from '@src/components/tableCells/ClickPreventWrap';
import {WorkAcceptanceFrontTypeId} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceFrontType';

interface IWorkAcceptanceScopeCellProps extends CellProps<IPreparedWorkAcceptance> {
	withoutObject?: boolean;
}

export const WorkAcceptanceScopeCell = ({row, withoutObject}: IWorkAcceptanceScopeCellProps) => {
	const {structures, object, frontType, spaces} = row.original;
	const objectId = object?.id;
	const workAcceptanceFront =
		frontType?.id === WorkAcceptanceFrontTypeId.STRUCTURES
			? structures
			: frontType?.id === WorkAcceptanceFrontTypeId.SPACES
			? spaces
			: undefined;

	return (
		<div className="work-acceptances-page__d-table-scope">
			{!withoutObject && (
				<div className="work-acceptances-page__d-table-scope__object-name work-acceptances-page__d-table__cell-header">
					{object?.name}
				</div>
			)}
			<div className="work-acceptances-page__d-table-scope__structures">
				{workAcceptanceFront &&
					workAcceptanceFront.slice(0, 2).map(
						item =>
							item &&
							objectId && (
								<ClickPreventWrap key={item.id}>
									<WorkAcceptanceFrontItem
										item={item}
										objectId={objectId}
										frontType={frontType?.id}
									/>
								</ClickPreventWrap>
							)
					)}
			</div>
			{!!workAcceptanceFront?.length && workAcceptanceFront?.length > 2 && (
				<div className="work-acceptances-page__d-table__cell-rest">
					+{workAcceptanceFront.length - 2}
				</div>
			)}
		</div>
	);
};

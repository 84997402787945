import {CSSProperties, memo, useCallback, useMemo} from 'react';
import {useExtractAllCategoriesAsArray} from '@src/core/hooks/queries/categories/hook';
import {useExtractCategoriesSetsAsArray} from '@src/core/hooks/queries/categorySets/hook';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {CategorySelect, EditableFieldLabel} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';
import {makeTreeData} from '@src/core/hooks/queries/categorySets/utils/makeTreeData';
import {useController, useFormContext} from 'react-hook-form';

interface ICategoriesSetProps {
	className?: string;
	style?: CSSProperties;
	label?: string;
	objectId: string;
	stage: ObjectStageIds;
	disabled?: boolean;
	treeCheckOnlyChildren?: boolean;
	errorMessage?: string;
	name: string;
	required?: boolean;
	onSetValue?: (name: string, value: unknown, options?: unknown) => void;
}

const CategoryMemo = ({
	className,
	style,
	label = 'Вид работ',
	objectId,
	stage,
	disabled,
	treeCheckOnlyChildren = false,
	errorMessage,
	name,
	onSetValue,
	required
}: ICategoriesSetProps) => {
	const {data: sets} = useExtractCategoriesSetsAsArray(objectId, stage);
	const {data: categories} = useExtractAllCategoriesAsArray();
	const {t} = useTranslation();
	const {control} = useFormContext();
	const treeData = useMemo(() => makeTreeData(sets, categories, true), [sets, categories]);

	// обязательное требование заполнения снимается если нет категорий на выбор
	const isException = treeData?.length === 0 || !treeData;
	const {
		field: {value},
		fieldState: {invalid}
	} = useController({
		name,
		control,
		rules: {required: isException ? !isException : required}
	});

	const category = useMemo(() => (value ? [value] : undefined), [value]);

	const handleChange = useCallback(
		(v: string[]) => {
			if (onSetValue) {
				onSetValue(name, v[0]);
				if (v.length === 0) {
					onSetValue(name, null);
				}
			}
		},
		[onSetValue, name]
	);
	return (
		<div
			className={className}
			style={style}
		>
			<EditableFieldLabel>{label}</EditableFieldLabel>

			<CategorySelect
				dialogTitle={t('components.editableFields.category.dialog.title')}
				treeData={treeData}
				value={category}
				error={invalid ? errorMessage : undefined}
				searchPlaceholder={t('components.editableFields.category.searchPlaceholder')}
				dialogAcceptBtnLabel={t('components.editableFields.category.dialog.accept.label')}
				dialogRejectBtnLabel={t('components.editableFields.category.dialog.reject.label')}
				disabled={disabled}
				onChange={handleChange}
				treeCheckOnlyChildren={treeCheckOnlyChildren}
			/>
		</div>
	);
};
export const Category = memo(CategoryMemo) as typeof CategoryMemo;

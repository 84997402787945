import {
	IAddProblemParams,
	IEditProblemParams
} from '@src/api/cache/problems/defaults/useProblemsMutationDefaults';
import {problemsQueryKeys} from '@src/api/cache/problems/keys';
import {useEditProblemMutation} from '@src/api/cache/problems/mutations';
import {editProblemActions} from '@src/store/modules/pages/problem/actions';
import {addWarningToast} from '@src/utils/toasts';
import {onlineManager, useQueryClient} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {ILinkedProblem} from '@tehzor/tools/interfaces/problems/ILinkedProblem';
import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';
import {useCallback} from 'react';
import useAppDispatch from '../../useAppDispatch';
import {ISavingProblemQuery} from './useAddProblem';
import {toListProblem} from './utils/convertToLocalSave';
import useAppSelector from '../../useAppSelector';
import {extractUserProfile} from '@src/store/modules/auth/profile/selectors';
import {usePlans} from '../../queries/plans/hooks';

export const useEditProblem = (object?: IObject) => {
	const queryClient = useQueryClient();
	const dispatch = useAppDispatch();
	const user = useAppSelector(extractUserProfile);
	const mutation = useEditProblemMutation();
	const online = onlineManager.isOnline();
	const {data: plans} = usePlans(object?.id);
	// Проверяем локальное нарушение или нет,
	// если да, то изменяем кэш
	const editProblem = useCallback((params: IEditProblemParams) => {
		const {problemId, fields, objectId} = params;
		const payloadToSave = queryClient.getQueryData(problemsQueryKeys.savingData(problemId));
		if (payloadToSave) {
			const newPayload = queryClient.setQueryData<ISavingProblemQuery>(
				problemsQueryKeys.savingData(problemId),
				data => {
					if (data) {
						if (fields.attachments) {
							data.fields.newAttachments = data.fields.newAttachments?.filter(
								att => fields.attachments?.some(item => item.id === att.key)
							);
						}
						if (fields.newAttachments) {
							if (!data.fields.newAttachments || !data.fields.newAttachments.length) {
								data.fields.newAttachments = [];
							}
							data.fields.newAttachments = [
								...data.fields.newAttachments,
								...fields.newAttachments
							];
						}

						return {
							...data,
							fields: {
								...data.fields,
								...fields,
								newAttachments: data.fields.newAttachments
							}
						};
					}
					return data;
				}
			);
			const cachedProblem = queryClient.setQueryData<IProblem>(
				[...problemsQueryKeys.detail(problemId), objectId],
				(data: IProblem) => {
					if (data && object) {
						return {
							...data,
							...toListProblem(
								object,
								data.links,
								data.stage,
								data.processId,
								{...data, ...fields},
								problemId,
								Number(data.localNumber?.split('-')[1]),
								user,
								plans
							)
						};
					}
					return data;
				}
			);
			void queryClient.refetchQueries({
				queryKey: problemsQueryKeys.localList()
			});
			const mutationCache = queryClient.getMutationCache();
			const currentMutation = mutationCache.find<
				IProblem,
				IError,
				IAddProblemParams,
				unknown
			>({
				predicate: mut => {
					const key = (mut.state.variables as IAddProblemParams).key;
					return problemId === key;
				}
			});

			if (currentMutation && newPayload && currentMutation.state.variables) {
				currentMutation.state.isPaused = true;
				currentMutation.state.status = 'pending';
				currentMutation.state.failureCount = 0;
				currentMutation.state.error = null;
				currentMutation.state.failureReason = null;
				currentMutation.state.variables = {
					...currentMutation.state.variables,
					fields: newPayload.fields
				};
				if (online) {
					void currentMutation.execute(currentMutation.state.variables);
				}
			}
			dispatch(editProblemActions.success(cachedProblem as ILinkedProblem));
			addWarningToast('Успех', 'локальное нарушение изменено');
		}
		// Секция изменения нормального нарушения
		if (!payloadToSave) {
			mutation.mutate({
				...params
			});
		}
	}, []);
	return [editProblem];
};

import {EntityAddingBtn} from './EntityAddingBtn';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {useGlobalAddingEntityDialog} from '@src/components/GlobalAddingEntityDialog/hooks/useGlobalAddingEntityDialog';
import {useChecksPermissions} from '@src/core/hooks/permissions/useChecksPermissions';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';

interface IStructureAddingProps {
	objectId: string;
	structureId?: string;
}

export const GlobalEntityAdding = ({objectId, structureId}: IStructureAddingProps) => {
	const checksPermissions = useChecksPermissions(objectId);

	const permissions = {
		checks: checksPermissions.canAdd
	};
	const [dialog, openDialog] = useGlobalAddingEntityDialog(
		objectId,
		undefined,
		{structureId},
		ObjectStageIds.BUILDING,
		ProcessIds.OPERATIONAL_CONTROL,
		permissions,
		undefined
	);
	return (
		<>
			{checksPermissions.canAdd ? <EntityAddingBtn onClick={openDialog} /> : null}

			{dialog}
		</>
	);
};

import {wsConnector} from '../wsConnector';
import {IAddCommentResponse} from '../comment';
import IComment from '@tehzor/tools/interfaces/comments/IComment';
import {ICommentTypeId} from '@tehzor/tools/interfaces/comments/ICommentType';
import ISavingComment from '@src/interfaces/ISavingComment';

/**
 * Добавляет официальный ответ
 *
 * @param objectId id объекта
 * @param commentTypeId тип комментрия
 * @param links ссылки на другие сущности
 * @param fields данные для сохранения
 */
export const makeReplyAddRequest = (
	objectId: string,
	commentTypeId: ICommentTypeId,
	links: IComment['links'] | undefined,
	fields: ISavingComment
) =>
	wsConnector.sendAuthorizedRequest<IAddCommentResponse>('addReply', {
		objectId,
		commentTypeId,
		links,
		...fields
	});

import {useContractsPermissions} from '@src/core/hooks/permissions/useContractsPermissions';
import {IEntitySettings} from '@src/core/hooks/settings/useEntitySettings';
import {
	IUploadingFilesAction,
	IUploadingFilesState
} from '@src/core/hooks/states/useUploadingFilesState';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {AttachmentFileDestination} from '@tehzor/tools/enums/AttachmentFileDestination';
import ILocation from '@tehzor/tools/interfaces/ILocation';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {useIsDesktopHeight, useIsLargeTablet} from '@tehzor/ui-components/src/utils/mediaQueries';
import {Dispatch, memo, useCallback, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import './ProblemForm.less';
import {useFormContext} from 'react-hook-form';
import SearchableProblemDescription from './SearchableProblemDescription';
import {SearchableProblemReason} from './SearchableProblemReason';
import {Category} from './Category';
import {EditableDate} from './EditableDate';
import {ProblemTags} from './ProblemTags';
import {Location} from './Location';
import {EditableProblemPerformers} from './EditableProblemPerformers';
import {EditableProblemInspectors} from './EditableProblemInspectors';
import Attachments from './Attachments/Attachments';
import Floors from './Floors';
import Prescription from './Prescription/Prescription';
import {Contracts} from './Contracts';
import {CustomField} from './CustomField/CustomFields';
import {editableProblemFields} from '../useEditableProblemForm/editableProblemFields';
import Description from './Description/Description';
import {IAddProblemForm} from '../useEditableProblemForm';
import {createProblemErrorMessages} from '../utils/createProblemErrorMessages';

const desktopDescProps = {
	minRows: 3,
	maxRows: 7
};
const mobileDescProps = {
	minRows: 3,
	maxRows: 5
};

interface IAddingProblemFormContentProps {
	objectId: string;
	stage: ObjectStageIds;
	scope?: string;
	createdBy?: string;
	uploadingFilesState: IUploadingFilesState;
	uploadingFilesDispatch: Dispatch<IUploadingFilesAction>;
	fieldsSettings: IEntitySettings;
	saving: boolean;
	isLoading?: boolean;
	creating?: boolean;
	fieldsAvailability?: Record<string, boolean>;
	entityLocation?: ILocation;
	entityPlanId?: string;
	defaultValues: IAddProblemForm | undefined;
}

export type SetValueOptions = Partial<{
	shouldValidate: boolean;
	shouldDirty: boolean;
	shouldTouch: boolean;
}>;

/**
 * Интерфейс с визуальными компонентами для редактирования полей
 */
export const ProblemForm = memo((props: IAddingProblemFormContentProps) => {
	const {
		objectId,
		stage,
		scope,
		createdBy,
		uploadingFilesState,
		uploadingFilesDispatch,
		fieldsSettings,
		saving,
		fieldsAvailability,
		entityLocation,
		entityPlanId,
		isLoading,
		creating,
		defaultValues
	} = props;
	const {t} = useTranslation();

	const builtinNumber = Object.keys(fieldsSettings.builtin).length;
	const hasCustomFields = !!Object.keys(fieldsSettings.custom).length;
	const isDesktop = useIsLargeTablet();
	const isDesktopHeight = useIsDesktopHeight();
	const problem = useAppSelector(s => s.pages.problem.data);
	const {canView} = useContractsPermissions();
	const attachmentsClassNames = {
		root: 'adding-problem-form__attachments',
		scrollArea: 'adding-problem-form__attachments-scroll-area',
		files: 'adding-problem-form__attachments-files',
		file: 'adding-problem-form__attachments-file'
	};

	const {reset, setValue} = useFormContext();
	const errors = createProblemErrorMessages(t);

	useEffect(() => {
		if (!creating) {
			reset(defaultValues);
			uploadingFilesDispatch({type: 'reset'});
		}
	}, [defaultValues, creating, reset, uploadingFilesDispatch]);

	const changeHandler = useCallback(
		(name: string, value: string, options: SetValueOptions) => {
			const defaultOptions = {shouldDirty: true, shouldValidate: true};
			setValue(name, value, options || defaultOptions);
		},
		[setValue]
	);
	return (
		<form className="adding-problem-form">
			<div>
				{fieldsSettings.builtin.description !== undefined &&
					(creating ? (
						<SearchableProblemDescription
							data-testid="EditableProblemSearchDescription"
							className="adding-problem-form__description"
							disabled={saving || isLoading}
							onSetValue={changeHandler}
							textAreaProps={isDesktopHeight ? desktopDescProps : mobileDescProps}
							objectId={objectId}
							stage={stage}
							label={t('components.editableProblem.description.label')}
							name={editableProblemFields.DESCRIPTION}
							errorMessage={errors[editableProblemFields.DESCRIPTION]}
							required={fieldsSettings.builtin.description.required}
						/>
					) : (
						<Description
							data-testid="EditableProblemDescription"
							className="editable-problem__description"
							name={editableProblemFields.DESCRIPTION}
							onSetValue={changeHandler}
							disabled={
								(fieldsAvailability && !fieldsAvailability.description) ||
								saving ||
								isLoading
							}
							errorMessage={errors[editableProblemFields.DESCRIPTION]}
							textAreaProps={isDesktopHeight ? desktopDescProps : mobileDescProps}
							label={t('components.editableProblem.description.label')}
							required={fieldsSettings.builtin.description.required}
						/>
					))}

				<div className="adding-problem-form__info-grid">
					{fieldsSettings.builtin.categoryId !== undefined && (
						<Category
							data-testid="EditableProblemCategory"
							name={editableProblemFields.CATEGORY_ID}
							onSetValue={changeHandler}
							objectId={objectId}
							disabled={
								(fieldsAvailability && !fieldsAvailability.categoryId) || isLoading
							}
							required={fieldsSettings.builtin.categoryId.required}
							errorMessage={errors[editableProblemFields.CATEGORY_ID]}
							stage={stage}
							label={t('components.editableProblem.category.label')}
						/>
					)}

					{fieldsSettings.builtin.plannedFixDate !== undefined && (
						<EditableDate
							data-testid="EditableProblemDate"
							name={editableProblemFields.PLANNED_FIX_DATE}
							criticalName={editableProblemFields.CRITICAL}
							onSetValue={changeHandler}
							disabled={
								(fieldsAvailability && !fieldsAvailability.plannedFixDate) ||
								isLoading
							}
							errorMessage={errors[editableProblemFields.PLANNED_FIX_DATE]}
							criticalFieldsSettings={fieldsSettings.builtin.critical}
							criticalDisabled={fieldsAvailability && !fieldsAvailability.critical}
							showTimeSelect
							label={t('components.editableProblem.plannedFixDate.label')}
							required={fieldsSettings.builtin.plannedFixDate.required}
						/>
					)}

					{fieldsSettings.builtin.reason !== undefined && (
						<SearchableProblemReason
							data-testid="EditableProblemSearchReason"
							name={editableProblemFields.REASON}
							onSetValue={changeHandler}
							disabled={
								(fieldsAvailability && !fieldsAvailability.reason) || isLoading
							}
							required={fieldsSettings.builtin.reason.required}
							errorMessage={errors[editableProblemFields.REASON]}
							label={t('components.editableProblem.reason.label')}
						/>
					)}

					{fieldsSettings.builtin.problemTags !== undefined && (
						<ProblemTags
							data-testid="EditableProblemTags"
							name={editableProblemFields.PROBLEM_TAGS}
							onSetValue={changeHandler}
							objectId={objectId}
							stage={stage}
							disabled={
								(fieldsAvailability && !fieldsAvailability.problemTags) || isLoading
							}
							required={fieldsSettings.builtin.problemTags.required}
							errorMessage={errors[editableProblemFields.PROBLEM_TAGS]}
							label={t('components.editableProblem.tages.label')}
						/>
					)}

					{fieldsSettings.builtin.location !== undefined && (
						<Location
							data-testid="EditableProblemLocation"
							entityPlanId={entityPlanId}
							name={editableProblemFields.LOCATION}
							planIdFieldName={editableProblemFields.PLAN_ID}
							floorFieldName={editableProblemFields.FLOOR}
							onSetValue={changeHandler}
							objectId={objectId}
							disabled={
								(fieldsAvailability && !fieldsAvailability.location) || isLoading
							}
							errorMessage={errors[editableProblemFields.LOCATION]}
							entityLocation={entityLocation}
							label={t('components.editableProblem.location.label')}
							required={fieldsSettings.builtin.location.required}
							planRequired={fieldsSettings.builtin.planId.required}
						/>
					)}

					{fieldsSettings.builtin.floor !== undefined && (
						<Floors
							data-testid="EditableProblemFloors"
							name={editableProblemFields.FLOOR}
							onSetValue={changeHandler}
							disabled={
								(fieldsAvailability && !fieldsAvailability.floor) || isLoading
							}
							errorMessage={errors[editableProblemFields.FLOOR]}
							required={fieldsSettings.builtin.floor.required}
							label={t('components.editableProblem.floor.label')}
						/>
					)}

					{fieldsSettings.builtin.performers !== undefined && (
						<EditableProblemPerformers
							data-testid="EditableProblemPerformers"
							name={editableProblemFields.PERFORMERS}
							onSetValue={changeHandler}
							objectId={objectId}
							stage={stage}
							scope={scope}
							disabled={
								(fieldsAvailability && !fieldsAvailability.performers) || isLoading
							}
							required={fieldsSettings.builtin.performers.required}
							errorMessage={errors[editableProblemFields.PERFORMERS]}
						/>
					)}

					{fieldsSettings.builtin.inspectors !== undefined && (
						<EditableProblemInspectors
							data-testid="EditableProblemInspectors"
							name={editableProblemFields.INSPECTORS}
							onSetValue={changeHandler}
							objectId={objectId}
							stage={stage}
							scope={scope}
							createdBy={createdBy}
							disabled={
								(fieldsAvailability && !fieldsAvailability.inspectors) || isLoading
							}
							required={fieldsSettings.builtin.inspectors.required}
							errorMessage={errors[editableProblemFields.INSPECTORS]}
						/>
					)}

					{fieldsSettings.builtin.contractId !== undefined && canView && (
						<Contracts
							data-testid="EditableProblemContracts"
							name={editableProblemFields.CONTRACT_ID}
							objectId={objectId}
							stage={stage}
							onSetValue={changeHandler}
							disabled={
								(fieldsAvailability && !fieldsAvailability.contractId) || isLoading
							}
							required={fieldsSettings.builtin.contractId.required}
							errorMessage={errors[editableProblemFields.CONTRACT_ID]}
							label={t('components.editableProblem.contract.label')}
						/>
					)}

					{hasCustomFields &&
						Object.values(fieldsSettings.custom).map(customSetting => (
							<CustomField
								key={customSetting.fieldId}
								data-testid={`EditableProblemCustomField${customSetting.id}`}
								name={`${editableProblemFields.CUSTOM_FIELDS}.${customSetting.fieldKey}`}
								custom={customSetting}
								isMobile={!isDesktop}
								onSetValue={changeHandler}
								errorMessage={errors[`${editableProblemFields.CUSTOM_FIELDS}`]}
								required={customSetting.required}
							/>
						))}
				</div>
				{fieldsSettings.builtin.prescription !== undefined && (
					<Prescription
						data-testid="EditableProblemPrescription"
						name={editableProblemFields.PRESCRIPTION}
						className="adding-problem-form__prescription"
						onSetValue={changeHandler}
						disabled={
							(fieldsAvailability && !fieldsAvailability.prescription) || isLoading
						}
						errorMessage={errors[editableProblemFields.PRESCRIPTION]}
						textAreaProps={isDesktopHeight ? desktopDescProps : mobileDescProps}
						label={t('components.editableProblem.prescription.label')}
						required={fieldsSettings.builtin.prescription.required}
					/>
				)}
			</div>

			<div>
				{fieldsSettings.builtin.attachments !== undefined && (
					<Attachments
						data-testid="EditableProblemAttachments"
						name={editableProblemFields.ATTACHMENTS}
						className={attachmentsClassNames}
						imagesTitle={t('components.editableProblem.attachments.label')}
						attachmentsDestination={AttachmentFileDestination.PROBLEM}
						entityId={problem?.id}
						uploadingFilesState={uploadingFilesState}
						uploadingFilesDispatch={uploadingFilesDispatch}
						disabled={
							(fieldsAvailability && !fieldsAvailability.attachments) || isLoading
						}
						required={fieldsSettings.builtin.attachments.required}
						canDraw={fieldsAvailability && fieldsAvailability.attachments}
						waitForUploading={false}
						onSetValue={changeHandler}
						showAttachBtn
						label={t('components.editableProblem.attachments.imagesTitle')}
					/>
				)}
			</div>

			{!builtinNumber && !hasCustomFields && (
				<div className="adding-problem-form__empty-fields-msg">
					{t('components.editableProblem.emptyFieldsMsg')}
				</div>
			)}
		</form>
	);
});

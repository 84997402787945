import {forwardRef, memo, Ref, useCallback} from 'react';
import {EditableFieldLabel, TextField} from '@tehzor/ui-components';
import {IDescriptionProps} from './IDescriptionProps';
import {useController, useFormContext} from 'react-hook-form';

const defaultTextAreaProps = {
	minRows: 2,
	maxRows: 6
};

const Description = (
	{
		className,
		style,
		label = 'Описание',
		addon,
		disabled,
		textAreaProps = defaultTextAreaProps,
		name,
		onSetValue,
		errorMessage,
		required
	}: IDescriptionProps,
	ref?: Ref<HTMLTextAreaElement>
) => {
	const {control} = useFormContext();
	const {
		field: {value},
		fieldState: {invalid}
	} = useController({
		name,
		control,
		rules: {required}
	});

	const handleChange = useCallback(
		(v: string) => {
			if (onSetValue) {
				onSetValue(name, v);
			}
		},
		[onSetValue, name]
	);

	return (
		<div
			className={className}
			style={style}
		>
			<EditableFieldLabel addon={addon}>{label}</EditableFieldLabel>

			<TextField
				value={value}
				elementRef={ref}
				elementType="textarea"
				elementProps={textAreaProps}
				error={invalid ? errorMessage : undefined}
				disabled={disabled}
				onChange={handleChange}
				name={name}
			/>
		</div>
	);
};

export default memo(forwardRef<HTMLTextAreaElement, IDescriptionProps>(Description));

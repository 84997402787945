import {useCallback, useState, useMemo, memo} from 'react';
import {IProblemTemplate} from '@tehzor/tools/interfaces/problemTemplatesSets/IProblemTemplate';
import {
	ActionButtons,
	Button,
	Dialog,
	InlineButton,
	SearchSelect,
	useLocalSearchProvider
} from '@tehzor/ui-components';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import './TemplateSelectDialog.less';
import {useToggle} from 'react-use';
import {useObject} from '@src/core/hooks/queries/objects/hooks';
import {useProblemTemplatesAsArray} from '@src/core/hooks/queries/problemTemplatesSets/hooks';
import {useTranslation} from 'react-i18next';
import {useTemplateSelectHandler} from '../hooks/useTemplateSelectHandler';
import {templateRenderItem} from '../utils/templateRenderItem';

interface ITemplateSelectDialogProps {
	objectId: string;
	stage: ObjectStageIds;
}

export const TemplateSelectDialog = memo(({objectId, stage}: ITemplateSelectDialogProps) => {
	const {data: object} = useObject(objectId);
	const {data: templates} = useProblemTemplatesAsArray(object);
	const filteredTemplates = useMemo(
		() => templates?.filter(el => el.stageId === stage) || [],
		[templates, stage]
	);
	const {t} = useTranslation();
	const selectHandler = useTemplateSelectHandler({objectId, stage});
	const [isOpen, toggle] = useToggle(false);
	const [selected, setSelected] = useState<IProblemTemplate>();
	const provider = useLocalSearchProvider({
		initialList: filteredTemplates,
		keys: ['description']
	});

	const handleSave = useCallback(() => {
		if (!selected) {
			toggle(false);
			return;
		}

		selectHandler(selected);

		toggle(false);
	}, [selected, selectHandler, toggle]);

	return (
		<>
			<InlineButton
				onClick={toggle}
				label={t('templateSelectDialog.templateSelectDialogBtn.label')}
				type="accent"
			/>
			<Dialog
				dataTestId="TemplateSelectDialog"
				className={{
					content: 'template-select-dialog',
					body: 'template-select-dialog__body'
				}}
				title={t('templateSelectDialog.label')}
				isOpen={isOpen}
				footer={
					<ActionButtons>
						<Button
							type="cancel"
							label={t('cancelBtn.label')}
							onClick={toggle}
						/>
						<Button
							type="accent-blue"
							label={t('applyBtn.label')}
							onClick={handleSave}
						/>
					</ActionButtons>
				}
				fullScreenOnTablet
				onRequestClose={toggle}
			>
				<SearchSelect
					provider={provider}
					renderItem={templateRenderItem}
					onSelect={setSelected as (value: IProblemTemplate | undefined) => void}
				/>
			</Dialog>
		</>
	);
});

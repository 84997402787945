import {ReactElement, useCallback, useRef, useState} from 'react';
import './AddingProblemDialog.less';
import {Dialog} from '@tehzor/ui-components';
import AddingProblem, {IAddingProblemRefProps} from '../AddingProblem';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';
import {ISavingProblem} from '@src/interfaces/saving/ISavingProblem';
import {saveProblem} from './utils/saveProblem';
import {useFetchLatestUserChecks} from '@src/core/hooks/queries/checks/latest/hooks';
import {useTranslation} from 'react-i18next';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractFastAdding} from '@src/store/modules/settings/modalDialog/selectors';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {ModalDialogButtons} from '../ModalDialogButtons';
import {modalDialogActions} from '@src/store/modules/settings/modalDialog/slice';

interface IAddingProblemDialogProps {
	objectId: string;
	links?: IProblem['links'];
	stage: ObjectStageIds;
	processId: ProcessIds;
	scope?: string;
	defaultData?: ISavingProblem;
	isOpen: boolean;
	header?: ReactElement;
	onClose: () => void;
	onSuccess?: () => void | Promise<void>;
}

const AddingProblemDialog = (props: IAddingProblemDialogProps) => {
	const {t} = useTranslation();
	const dispatch = useAppDispatch();
	const {resetFastAddingMode} = modalDialogActions;

	const {
		objectId,
		links,
		stage,
		processId,
		scope,
		defaultData,
		isOpen,
		header,
		onSuccess,
		onClose
	} = props;
	const addingEntityRef = useRef<IAddingProblemRefProps>(null);
	useFetchLatestUserChecks();
	const isFastAddingActive = useAppSelector(extractFastAdding);

	const [saving, setSaving] = useState(false);
	const success = useRef<boolean>(false);

	const handleSave = useCallback(async () => {
		await saveProblem({addingEntityRef, success, onClose, isFastAddingActive});
		setSaving(false);
	}, [onClose, isFastAddingActive]);

	const handleCancel = useCallback(() => {
		if (addingEntityRef.current) {
			addingEntityRef.current.cancel();
		} else {
			onClose();
		}
		dispatch(resetFastAddingMode());
	}, [onClose, dispatch, resetFastAddingMode]);

	const handleAfterClose = useCallback(() => {
		if (success.current && onSuccess) {
			success.current = false;
			void onSuccess();
		}
	}, [onSuccess]);

	return (
		<Dialog
			className="adding-check-dialog"
			isOpen={isOpen}
			title={t('components.addingProblemDialog.dialog.title')}
			footer={
				<ModalDialogButtons
					saving={saving}
					handleCancel={handleCancel}
					handleSave={handleSave}
					isProblemType={true}
				/>
			}
			fullScreenOnTablet
			onRequestClose={handleCancel}
			onAfterClose={handleAfterClose}
		>
			{header}
			<AddingProblem
				objectId={objectId}
				links={links}
				stage={stage}
				processId={processId}
				scope={scope}
				defaultData={defaultData}
				saving={saving}
				onClose={onClose}
				setSaving={setSaving}
				ref={addingEntityRef}
			/>
		</Dialog>
	);
};

export default AddingProblemDialog;

import {memo} from 'react';
import {useStructuresPermissions} from '@src/core/hooks/permissions/useStructuresPermissions';
import {useEditableStructureDialog} from '@src/components/EditableStructureDialog/hooks/useEditableStructureDialog';
import {IconMenu, MenuItem} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';
import {useIsDesktop} from '@/shared/hooks/dimensions/useIsDesktop/useIsDesktop';
import {useAppUpdateMenuItem} from '@src/components/AppUpdateData';

interface IActionsMenuProps {
	objectId: string;
}

const iconPlus = <i className="tz-plus-24" />;

export const ActionsMenu = memo(({objectId}: IActionsMenuProps) => {
	const isDesktop = useIsDesktop();
	const {t} = useTranslation();
	const permissions = useStructuresPermissions(objectId);

	const [addingDialog, openAddingDialog] = useEditableStructureDialog(objectId);
	const updateData = useAppUpdateMenuItem();

	const items = [
		<MenuItem
			key="add"
			icon={iconPlus}
			onClick={openAddingDialog}
		>
			{t('structuresPage.actionMenu.items.add.title')}
		</MenuItem>
	];

	if (!isDesktop) {
		items.unshift(updateData);
	}

	if (permissions.canAdd) {
		return (
			<>
				<IconMenu type={isDesktop ? 'accent-blue' : 'common'}>{items}</IconMenu>
				{addingDialog}
			</>
		);
	}

	return null;
});

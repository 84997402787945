export enum editableProblemFields {
	CATEGORY_ID = 'categoryId',
	TEMPLATE_ID = 'templateId',
	PLANNED_FIX_DATE = 'plannedFixDate',
	REASON = 'reason',
	PLAN_ID = 'planId',
	LOCATION = 'location',
	FLOOR = 'floor',
	INSPECTORS = 'inspectors',
	INSPECTORS_ACTIVE_GROUP = 'inspectorsActiveGroup',
	PERFORMERS = 'performers',
	WATCHERS = 'watchers',
	PERFORMERS_ACTIVE_GROUP = 'performersActiveGroup',
	DESCRIPTION = 'description',
	PRESCRIPTION = 'prescription',
	ATTACHMENTS = 'attachments',
	PROBLEM_TAGS = 'problemTags',
	CRITICAL = 'critical',
	CONTRACT_ID = 'contractId',
	CUSTOM_FIELDS = 'customFields',
	INITIAL_RULE_PARAMS = 'initialRuleParams'
}

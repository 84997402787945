import {IGetDelegationHistoriesParams} from '@src/api/backend/delegationHistories';

export type IGetDelegationHistoryQueryKey = [] & {
	0: string; // delegation-history
	1: IGetDelegationHistoriesParams;
};

export const delegationHistoryQueryKeys = {
	all: () => ['delegation-history'],
	histories: (delegationHistoriesParams: IGetDelegationHistoriesParams) => [
		...delegationHistoryQueryKeys.all(),
		delegationHistoriesParams
	]
};

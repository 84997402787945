import {memo} from 'react';
import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';
import {DesktopInfo} from './Info.desktop';
import {DesktopDocuments} from './Documents.desktop';
import {SpaceTasksLinksDesktop} from './SpaceTasksLinks.desktop';
import {AdditionalInfo} from './AdditionalInfo';
import {MetersBlock} from './MetersBlock';

interface ISpacePageDesktopInfoBlockProps {
	space: ISpace;
	objectId: string;
	hasUnfixedProblems: boolean;
	hasHandlingOrWaitingProblems: boolean;
	hasUnacceptedCheckItem: boolean;
}

export const DesktopInfoBlock = memo((props: ISpacePageDesktopInfoBlockProps) => {
	const {
		objectId,
		space,
		hasUnfixedProblems,
		hasHandlingOrWaitingProblems,
		hasUnacceptedCheckItem
	} = props;

	const showAdditionalInfo =
		space.areaBTI ||
		space.floorBTI ||
		space.numberBTI ||
		space.contractForm ||
		space.markupForRegistration ||
		space.decorationWarrantyExpiredDate ||
		space.technicalEquipmentWarrantyExpiredDate ||
		space.constructiveWarrantyExpiredDate;

	return (
		<div className="space-page__d-column_info">
			<DesktopInfo
				objectId={objectId}
				space={space}
				hasUnfixedProblems={hasUnfixedProblems}
				hasHandlingOrWaitingProblems={hasHandlingOrWaitingProblems}
				hasUnacceptedCheckItem={hasUnacceptedCheckItem}
			/>

			<MetersBlock
				objectId={objectId}
				spaceId={space.id}
			/>

			<DesktopDocuments
				objectId={objectId}
				space={space}
			/>

			<SpaceTasksLinksDesktop spaceId={space.id} />

			{showAdditionalInfo && <AdditionalInfo space={space} />}
		</div>
	);
});

import {useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IWorkAcceptanceAddingEntityType} from '@src/store/modules/settings/workAcceptanceAdding/interfaces';
import {extractWorkAcceptanceAddingSettings} from '@src/store/modules/settings/workAcceptanceAdding/selectors';

export function useEntityType(objectId: string, types: IWorkAcceptanceAddingEntityType[]) {
	const {entityType} = useAppSelector(extractWorkAcceptanceAddingSettings);
	return useMemo<IWorkAcceptanceAddingEntityType | undefined>(() => {
		if (types.includes(entityType)) {
			return entityType;
		}
		return types.length ? types[0] : undefined;
	}, [entityType, types]);
}

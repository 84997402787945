import {ICustomField, IFieldSetting} from '@tehzor/tools/interfaces/fields';
import {EditableFieldLabel, TextField} from '@tehzor/ui-components';
import {memo, useCallback} from 'react';
import {useController, useFormContext} from 'react-hook-form';
import {SetValueOptions} from '../../ProblemForm';

interface ICustomLinkFieldProps {
	field: ICustomField;
	setting: IFieldSetting;
	disabled?: boolean;
	onSetValue?: (name: string, value: unknown, options?: SetValueOptions) => void;
	name: string;
	errorMessage?: string;
	required?: boolean;
}

const textAreaProps = {
	minRows: 2,
	maxRows: 6
};

const CustomLinkFieldComponent = ({
	field,
	setting,
	disabled,
	onSetValue,
	name,
	errorMessage,
	required
}: ICustomLinkFieldProps) => {
	const {control} = useFormContext();

	const {
		field: {value},
		fieldState: {invalid}
	} = useController({
		name,
		control,
		rules: {required}
	});

	const handleChange = useCallback(
		(val?: string | null) => {
			if (onSetValue) {
				onSetValue(name, val);
			}
		},
		[onSetValue, name]
	);

	return (
		<div className="custom-fields__text-custom-field">
			<EditableFieldLabel>
				{setting.required ? `${field.name} *` : field.name}
			</EditableFieldLabel>

			<TextField
				value={value}
				disabled={disabled}
				elementType="textarea"
				elementProps={textAreaProps}
				error={invalid ? errorMessage : undefined}
				onChange={handleChange}
			/>
		</div>
	);
};

export const CustomLinkField = memo(CustomLinkFieldComponent) as typeof CustomLinkFieldComponent;
